import React, { useState, useEffect, useRef, ReactInstance } from 'react'
import {
  Box,
  Card,
  Chip,
  Grid,
  Typography,
  makeStyles,
  useTheme,
  useMediaQuery,
  CircularProgress,
  Link,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  IconButton,
  Menu,
  MenuItem
} from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { FiClock, FiInfo } from 'react-icons/fi'
import {
  Alert,
  Timeline,
  TimelineContent,
  TimelineItem,
  TimelineDot,
  TimelineSeparator,
  TimelineConnector
} from '@material-ui/lab'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import formatarDinheiro from 'src/core/formatacoes/formatarDinheiro'
import {
  obterDadosPropostaPorIdMedico,
  obterHistoricoPropostas,
  recusarProposta,
  aprovarProposta,
  imprimirProposta
} from 'src/api/proposta/index'
import { useQueryParams } from 'src/hooks/useQuery'
import {
  IPaciente,
  IPacoteOrcado,
  IMedico,
  DadosPropostaHistorico,
  ResumoPropostaResponse,
  ObjetoDescricaoGenerico
} from 'src/types'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import { formatarDataHoraLocal } from 'src/core/formatacoes/formatarData'
import { useHistory } from 'react-router-dom'
import StatusProposta from 'src/core/consts/statusProposta'
import Button from 'src/components/ui/Button'
import ImpressaoProposta, {
  DadosImpressao,
  IOPMESelecionado
} from '../../operacional/solicitacao/impressaoProposta'
import { useReactToPrint } from 'react-to-print'
import DetalhesPropostaMobile from 'src/components/propostas/DetalhesPropostaMobile'
import { TextField } from 'src/components/ui/TextField'
import ModalGenerico from 'src/components/modals/ModalGenerico'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import yup from 'src/components/yupCustom'
import Swal from 'sweetalert2'
import HeaderSolicitacao from 'src/components/solicitacao/HeaderSolicitacao'
import { RiArrowLeftLine } from 'react-icons/ri'
import { primeiraLetraMaiusculo } from 'src/core/helpers/common'
import useAuth from 'src/hooks/useAuth'
import { SynPerfis } from 'src/core/consts/synPerfis'
import UBox from '../../../../../src/components/ui/Ubox'
import formatarCNPJ from 'src/core/formatacoes/formatarCNPJ'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import { SynRoutes } from 'src/core/consts/synRoutes'
import informarcaoAlert from 'src/core/informacoes/informacaoAlert'
import { MdLocalOffer } from 'react-icons/md'
import { BiPlusMedical } from 'react-icons/bi'
import { saveAs } from 'file-saver'
import TextoExclusao from 'src/components/propostas/textoExclusao'

const btnRed = {
  border: '1px solid red',
  color: 'red'
}

const contestacaoSchema = yup.object().shape({
  contestacao: yup.string().required().min(4).label('Motivação')
})

const useStyles = makeStyles(theme => ({
  '.aprovadoDot': {
    backgroundColor: StatusProposta.APROVADA_PELO_MEDICO.Color
  },
  '.contestadoDot': {
    backgroundColor: StatusProposta.RECUSADA_PELO_MEDICO.Color
  },
  '.enviadoDot': {
    backgroundColor: StatusProposta.AGUARDANDO_APROVACAO_MEDICO.Color
  },
  '.aguardandoPacienteDot': {
    backgroundColor: StatusProposta.AGUARDANDO_APROVACAO_PACIENTE.Color
  },
  '.aprovadaPacienteDot': {
    backgroundColor: StatusProposta.APROVADA_PELO_PACIENTE.Color
  },
  headerDivider: {
    margin: '10px 0 30px 0'
  },
  moreIcon: {
    position: 'absolute',
    right: 20,
    transform: 'rotateZ(-90deg) translateY(50%)'
  },
  moreIconCard: {
    position: 'absolute',
    right: 12,
    zIndex: 2
  },
  containerCard: {
    display: 'flex',
    flexDirection: 'row',
    '@media (max-width: 1300px)': {
      flexDirection: 'column'
    }
  },
  containerCardDetail: {
    minWidth: '540px',
    '@media (max-width: 1300px)': {
      minWidth: '700px'
    },
    '@media (max-width: 1005px)': {
      minWidth: '640px'
    }
  },
  historyCard: {
    cursor: 'pointer',
    background: theme.palette.grey[50],
    boxShadow: 'none',
    border: '1px solid #efefef',
    minWidth: '287px',
    position: 'relative',
    '@media (max-width: 1300px)': {
      minWidth: '665px'
    },
    '@media (max-width: 1005px)': {
      minWidth: '600px'
    },
    '@media (max-width: 955px)': {
      minWidth: '700px'
    },
    '@media (max-width: 760px)': {
      minWidth: '320px'
    }
  },
  boxPropostaDetalhe: {
    minHeight: 400,
    background: theme.palette.grey[50],
    boxShadow: 'none',
    border: '1px solid #efefef'
  },
  hospitalCard: {
    color: '#465a81',
    padding: '20px',
    textAlign: 'center',
    border: '1px solid #465b82',
    margin: '20px 0'
  },
  hositalCardText: {
    margin: '1px 0'
  },
  cardDivider: {
    margin: '10px 0'
  },
  OPMECard: {
    padding: '20px',
    marginBottom: '20px',
    border: '1px solid #efefef',
    boxShadow: 'none',
    background: theme.palette.background.paper
  },
  timeline: {
    '&& .MuiTimelineItem-missingOppositeContent:before': {
      flex: 'none'
    },
    '&& .MuiTimelineDot-root': {
      marginTop: 0,
      marginBottom: 0
    },
    '&& .MuiTimelineConnector-root': {
      backgroundColor: '#e4e4e4'
    }
  },
  aprovadoPacienteAlert: {
    backgroundColor: '#8d44ad39',
    color: '#8e44ad',
    '& .MuiAlert-icon': {
      color: '#8e44ad'
    }
  },
  aguardandoPacienteAlert: {
    backgroundColor: '#E67F2439',
    color: '#c26b20',
    '& .MuiAlert-icon': {
      color: '#c26b20'
    }
  },
  propostaVencedoraAlert: {
    backgroundColor: '#009431',
    color: '#fff',
    '& .MuiAlert-icon': {
      color: '#fff'
    }
  },
  boxAlert: {
    '& .MuiAlert-message': {
      width: '100%'
    }
  }
}))

export default function DetalhesPropostas() {
  const { signOut, user, perfilAtual } = useAuth()
  const styles = useStyles()
  const theme = useTheme()
  const query = useQueryParams()
  const history = useHistory()

  const ehXS = useMediaQuery(theme.breakpoints.down('sm'))
  const [showDetalhesMobile, setShowDetalhesMobile] = useState(false)

  const [showContestacao, setShowContestacao] = useState(false)
  const [contestacaoLoading, setContestacaoLoading] = useState(false)
  const [aprovacaoLoading, setAprovacaoLoading] = useState(false)

  const [loading, setLoading] = useState(true)
  const [loadingPdf, setLoadingPdf] = useState(false)
  const [card, setCard] = useState<number | undefined>(0)

  const [
    propostaSelecionada,
    setPropostaSelecionada
  ] = useState<ResumoPropostaResponse>(null)
  const [loadingProposta, setLoadingProposta] = useState(true)

  const [medico, setMedico] = useState<IMedico>()
  const [paciente, setPaciente] = useState<IPaciente>()
  const [listaPropostas, setListaProposta] = useState<
    DadosPropostaHistorico[]
  >()
  const [pacoteProcedimento, setPacoteProcedimento] = useState<IPacoteOrcado>()
  const [pacoteInteligente, setPacoteInteligente] = useState(false)

  const [valorExames, setValorExames] = useState(0)

  useEffect(() => {
    const id = query.get('id')

    obterHistoricoPropostas(id)
      .then(response => {
        setMedico(response.data.medico)
        setPaciente(response.data.paciente)
        setListaProposta(response.data.listaPropostas)
        setPacoteProcedimento(response.data.pacoteProcedimento)
        setPacoteInteligente(response.data.pacoteInteligente)
        handleSelecionarProposta(0, response.data.listaPropostas[0])
        setLoading(false)
      })
      .catch(err => {
        showNotificationErrorAPI(err)
        history.goBack()
      })
  }, [])

  const [dadosImpressao, setDadosImpressao] = useState<
    DadosImpressao | undefined
  >()

  const handlePrint = async () => {
    try {
      setLoadingPdf(true)

      const response = await imprimirProposta(dadosImpressao.propostaId)
      if (response?.data) {
        const blob = new Blob([response.data], { type: 'application/pdf' })
        saveAs(blob, `Proposta-${dadosImpressao.codigoProposta}.pdf`)
        const url = window.URL.createObjectURL(blob)
        const printWindow = window.open(url, '_blank')
        if (printWindow) {
          printWindow.focus()
          //printWindow.print();
        }
      } else {
        console.error('Erro ao gerar a proposta: nenhum dado retornado')
      }
    } catch (error) {
      console.error('Erro ao processar a impressão:', error)
    } finally {
      setLoadingPdf(false) // Desativa o loading ao finalizar
    }
  }

  const { handleSubmit, errors, register } = useForm<{
    contestacao: string
  }>({
    defaultValues: {
      contestacao: ''
    },
    resolver: yupResolver(contestacaoSchema)
  })

  function setStatusColor(status: number) {
    if (status === 1) return StatusProposta.AGUARDANDO_APROVACAO_MEDICO.Color
    if (status === 2) return StatusProposta.APROVADA_PELO_MEDICO.Color
    if (status === 3) return StatusProposta.RECUSADA_PELO_MEDICO.Color
    if (status === 4) return StatusProposta.AGUARDANDO_APROVACAO_PACIENTE.Color
    if (status === 5) return StatusProposta.APROVADA_PELO_PACIENTE.Color
    if (status === 6) return StatusProposta.VENCEDORA.Color
    if (status === 7) return StatusProposta.RECUSADA_PELO_PACIENTE.Color
    // if (status === 8) return StatusProposta.ENCERRADA.Color
    // if (status === 9) return StatusProposta.DESATIVADA.Color
    // if (status === 10) return StatusProposta.AGENDADO.Color
  }

  function renderPalavraStatus(status: number) {
    for (const key in StatusProposta) {
      let statusProposta = StatusProposta[key]
      if (statusProposta.Id === status) return statusProposta.Descricao
    }
  }

  function handleSelecionarProposta(index, proposta) {
    setLoadingProposta(true)
    setCard(index)

    obterDadosPropostaPorIdMedico(proposta.id)
      .then(response => {
        const dados = response.data
        setPropostaSelecionada(dados)
        setLoadingProposta(false)

        const impressao: DadosImpressao = {
          medico: dados.medicoNome,
          paciente: dados.paciente,
          listaOPME: dados.listaOPME as IOPMESelecionado[],
          localAtendimento: dados.localAtendimento,
          pacoteProcedimento: dados.pacoteProcedimento,
          valorTotal: dados.valorTotal,
          examesLaboratorio: dados.examesLaboratorio,
          temAnestesia: dados.temAnestesia,
          temPreAnestesica: dados.temPreAnestesica,
          temRiscoCirurgico: dados.temRiscoCirurgico,
          temExames: dados.temExames,
          temReservaSangue: dados.temReservaSangue,
          propostaSemLocalAtendimento: dados.propostaSemLocalAtendimento,
          propostaSemHonorarioMedico: dados.propostaSemHonorarioMedico,
          propostaSemOpme: dados.propostaSemOpme,
          codigoSolicitacao: dados.codigoSolicitacao,
          codigoProposta: dados.codigoProposta,
          informacaoExame: dados.informacaoExame,
          criado: dados.criado,
          temBolsaSangue: dados.temBolsaSangue,
          temTaxaSyn: dados.temTaxaSyn,
          valorAnestesia: dados.valorAnestesia,
          valorTotalOPME: dados.valorTotalOPME,
          valorBancoSangue: dados.valorBancoSangue,
          valorReservaSangue: dados.valorReservaSangue,
          valorComissaoSyn: dados.valorComissaoSyn,
          valorSala: dados.valorSala,
          statusSolicitacao: dados.statusSolicitacao,
          honorarioMedico: dados.honorarioMedico,
          status: dados.status,
          descricaoMaterial: dados.descricaoMaterial,
          descricaoAnestesia: dados.descricaoAnestesia,
          descricaoEquipamento: dados.descricaoEquipamento,
          descricaoDiarias: dados.descricaoDiarias,
          tempoCirurgico: dados.tempoCirurgico,
          observacaoExame: dados.observacaoExame,
          observacaoPacotePaciente: dados.observacaoPacotePaciente,
          observacaoSangue: dados.observacaoSangue,
          urlLogomarca: dados.urlLogomarca,
          propostaCentroCirurgicoAmbulatorial:
            dados.propostaCentroCirurgicoAmbulatorial,
          propostaFinalDeSemana: dados.propostaFinalDeSemana,
          propostaId: dados.id
          /* contestacao: dados.contestacao */
        }
        var totalExames = 0
        dados.examesLaboratorio.exames.forEach(item => {
          totalExames += item.valor
        })

        setValorExames(totalExames)

        setDadosImpressao(impressao)
      })
      .catch(err => {
        showNotificationErrorAPI(err)
        setLoadingProposta(false)
      })
  }

  async function submitContestacao(data: { contestacao: string }) {
    setContestacaoLoading(true)

    const body = {
      propostaId: propostaSelecionada.id,
      motivo: data.contestacao,
      perfilAtual: perfilAtual
    }

    try {
      await recusarProposta(body)

      Swal.fire({
        title: 'Obrigado!',
        text: 'Proposta recusada com sucesso.',
        icon: 'success',
        confirmButtonText: 'Continuar',
        willClose: () => {
          setContestacaoLoading(false)
          setShowContestacao(false)
        }
      })

      const response = await obterHistoricoPropostas(query.get('id'))
      setMedico(response.data.medico)
      setPaciente(response.data.paciente)
      setListaProposta(response.data.listaPropostas)
      setPacoteProcedimento(response.data.pacoteProcedimento)

      handleSelecionarProposta(0, response.data.listaPropostas[0])
      setLoading(false)
    } catch (err) {
      history.goBack()
      showNotificationErrorAPI(err)
      setContestacaoLoading(false)
      setShowContestacao(false)
    }
  }

  async function submitAprovacao() {
    setAprovacaoLoading(true)
    const body = {
      propostaId: propostaSelecionada.id,
      perfilAtual: perfilAtual
    }

    try {
      await aprovarProposta(body)

      await Swal.fire({
        title: 'Sucesso!',
        text: 'A proposta foi aprovada',
        icon: 'success',
        confirmButtonText: 'Ok'
      })

      const response = await obterHistoricoPropostas(query.get('id'))
      setMedico(response.data.medico)
      setPaciente(response.data.paciente)
      setListaProposta(response.data.listaPropostas)
      setPacoteProcedimento(response.data.pacoteProcedimento)

      handleSelecionarProposta(0, response.data.listaPropostas[0])
      setAprovacaoLoading(false)
    } catch (err) {
      showNotificationErrorAPI(err)
      setAprovacaoLoading(false)
    }
  }

  function verificarSeExiste(item: boolean, texto: string) {
    if (!item) return ''
    return texto
  }

  function financiamento(propostaId) {
    window.open(`
      ${SynRoutes.financiamentoStep}?p=${propostaId}`)
  }

  function obterItensContemplados() {
    var itens = []

    var dados = propostaSelecionada

    itens.push(
      verificarSeExiste(
        !dados?.propostaSemLocalAtendimento,
        'valor do hospital'
      )
    )
    itens.push(verificarSeExiste(dados?.temAnestesia, 'anestesia'))

    itens.push(
      verificarSeExiste(dados?.temPreAnestesica, 'avaliação pré-anestésica')
    )
    itens.push(
      verificarSeExiste(
        dados?.temRiscoCirurgico,
        'avaliação de risco cirúrgico'
      )
    )

    itens.push(verificarSeExiste(dados?.temExames, 'exames pré-operatórios'))
    itens.push(
      verificarSeExiste(dados?.listaOPME?.length !== 0, "OPME's, taxa de OPME")
    )
    itens.push(verificarSeExiste(dados?.temTaxaSyn, 'taxa de serviço'))
    itens.push(
      verificarSeExiste(
        dados?.temReservaSangue,
        'reserva de sangue, bolsa(s) de sangue'
      )
    )

    itens = itens.filter(i => !!i)

    itens.push(
      verificarSeExiste(
        !dados?.propostaSemHonorarioMedico,
        ' honorários equipe médica'
      )
    )

    itens = itens.filter(i => !!i)
    if (itens.length > 0) {
      var texto = itens.slice(0, -1).join(', ')
      texto += (itens.length > 1 ? ' e ' : '') + itens.slice(-1)
      return primeiraLetraMaiusculo(`${texto}`)
    }
  }

  if (loading) {
    return (
      <Box textAlign="center">
        <CircularProgress />
        <div>Carregando...</div>
      </Box>
    )
  }

  function gerarDescricao(item: DadosPropostaHistorico) {
    let partes = []

    if (!!item.diasUteis) {
      partes.push('em qualquer dia da semana')
    }
    if (!!item.finalDeSemana) {
      partes.push('nos finais de semana')
    }
    if (!!item.centroCirurgicoAmbulatorial) {
      partes.push('no centro cirúrgico ambulatorial')
    }
    if (
      !item.diasUteis &&
      !item.finalDeSemana &&
      !item.centroCirurgicoAmbulatorial
    ) {
      partes.push('em qualquer dia e horário')
    }

    let descricao = 'Para realização  '
    if (partes.length === 1) {
      descricao += partes[0] + '.'
    } else if (partes.length === 2) {
      descricao += partes.join(' no ') + '.'
    }

    if (!!item.cartaoDesconto) {
      descricao = 'Mediante a cartão de desconto. ' + descricao
    }
    return descricao
  }

  return (
    <Box>
      {showDetalhesMobile && ehXS ? (
        <DetalhesPropostaMobile
          financiamento={financiamento}
          handlePrint={handlePrint}
          handleClose={() => setShowDetalhesMobile(false)}
          propostaSelecionada={propostaSelecionada}
          esconderDadosSensiveis
          contestacaoLoading={contestacaoLoading}
          aprovacaoLoading={aprovacaoLoading}
          submitContestacao={submitContestacao}
          submitAprovacao={submitAprovacao}
          valorExames={valorExames}
          verificarSeExiste={verificarSeExiste}
        />
      ) : (
        <>
          <Link
            component="button"
            color="primary"
            onClick={() => history.goBack()}
            style={{ marginBottom: '20px' }}
          >
            <RiArrowLeftLine />
            <Typography
              style={{ marginLeft: 10 }}
              variant="subtitle1"
              component="span"
            >
              Voltar
            </Typography>
          </Link>
          <HeaderSolicitacao
            proposta
            pacoteProcedimento={pacoteProcedimento}
            paciente={paciente}
            medicoNome={medico.nome}
            comorbidades={paciente.comorbidades as ObjetoDescricaoGenerico[]}
            obsComorbidades={paciente.observacaoComorbidades}
          />

          <Grid container className={styles.containerCard}>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6">Propostas</Typography>
              <Timeline
                className={styles.timeline}
                align="left"
                style={{ transform: 'translateX(-40px)' }}
              >
                <Grid item>
                  {listaPropostas?.map((p, i) => {
                    return (
                      <>
                        <TimelineItem key={p.codigo}>
                          <TimelineSeparator>
                            <TimelineDot
                              style={{
                                backgroundColor: setStatusColor(p.status)
                              }}
                            />
                            {i !== listaPropostas.length - 1 && (
                              <TimelineConnector />
                            )}
                          </TimelineSeparator>
                          <TimelineContent style={{ position: 'relative' }}>
                            {(p.status ===
                              StatusProposta.RECUSADA_PELO_PACIENTE.Id ||
                              p.status ===
                                StatusProposta.APROVADA_PELO_PACIENTE.Id ||
                              p.status === StatusProposta.VENCEDORA.Id ||
                              p.status ===
                                StatusProposta.AGUARDANDO_APROVACAO_MEDICO.Id ||
                              p.status ===
                                StatusProposta.AGUARDANDO_APROVACAO_PACIENTE
                                  .Id ||
                              p.status === StatusProposta.ENCERRADA.Id) && (
                              <Box>
                                <Typography className={styles.moreIconCard}>
                                  <PopupState
                                    variant="popover"
                                    popupId="demo-popup-menu"
                                  >
                                    {popupState => (
                                      <React.Fragment>
                                        <IconButton
                                          aria-controls="simple-menu"
                                          aria-haspopup="true"
                                          aria-label="mais"
                                          {...bindTrigger(popupState)}
                                        >
                                          <MoreVertIcon />
                                        </IconButton>

                                        <Menu
                                          anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right'
                                          }}
                                          transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right'
                                          }}
                                          {...bindMenu(popupState)}
                                        >
                                          <MenuItem
                                            onClick={() => {
                                              financiamento(p.id)
                                              popupState.close()
                                            }}
                                          >
                                            Solicitar financiamento
                                          </MenuItem>
                                        </Menu>
                                      </React.Fragment>
                                    )}
                                  </PopupState>
                                </Typography>
                              </Box>
                            )}
                            <Box
                              mb={2}
                              component={Card}
                              className={styles.historyCard}
                              style={
                                card === i
                                  ? {
                                      border: '2px solid #000'
                                    }
                                  : null
                              }
                              onClick={() => {
                                if (p.status !== StatusProposta.ENCERRADA.Id) {
                                  handleSelecionarProposta(i, p)
                                  setShowDetalhesMobile(true)
                                }
                              }}
                            >
                              <Box p={2}>
                                <Grid
                                  container
                                  direction="row"
                                  alignItems="center"
                                >
                                  <Grid
                                    item
                                    xs={12}
                                    style={{
                                      width: '100%',
                                      overflowX: 'hidden'
                                    }}
                                  >
                                    <UBox maxWidth="90%" mb={2}>
                                      <Typography
                                        variant="subtitle2"
                                        style={{
                                          whiteSpace: 'nowrap',
                                          textTransform: 'uppercase'
                                        }}
                                        color="primary"
                                      >
                                        <b>{p.internacao}</b>
                                      </Typography>

                                      <Box style={{ paddingRight: '10px' }}>
                                        <Typography
                                          variant="body2"
                                          color="secondary"
                                          style={{
                                            whiteSpace: 'nowrap',
                                            fontSize: '16px',
                                            textTransform: 'capitalize'
                                          }}
                                        >
                                          <b>{p.localAtendimento}</b>
                                        </Typography>
                                      </Box>
                                      <UBox
                                        style={{
                                          background: '#21ebc7',
                                          width: '60px',
                                          height: '4px',
                                          borderRadius: '4px'
                                        }}
                                        display="block"
                                      ></UBox>
                                    </UBox>

                                    {p?.status === 6 ? (
                                      <Chip
                                        variant="default"
                                        size="small"
                                        label={renderPalavraStatus(p.status)}
                                        style={{
                                          color: '#fff',
                                          backgroundColor: setStatusColor(
                                            p.status
                                          )
                                        }}
                                      />
                                    ) : (
                                      <Chip
                                        variant="outlined"
                                        size="small"
                                        label={renderPalavraStatus(p.status)}
                                        style={{
                                          color: setStatusColor(p.status),
                                          borderColor: setStatusColor(p.status)
                                        }}
                                      />
                                    )}

                                    <Typography
                                      variant="subtitle2"
                                      style={{ whiteSpace: 'nowrap' }}
                                    >
                                      <b>
                                        <small>Valor total:</small>
                                      </b>{' '}
                                      {formatarDinheiro(p.valorTotal)}
                                    </Typography>
                                    <Typography
                                      variant="subtitle2"
                                      style={{ whiteSpace: 'nowrap' }}
                                    >
                                      <b>
                                        <small>Código:</small>
                                      </b>{' '}
                                      {p.codigo}
                                    </Typography>
                                    {!!p?.centroCirurgicoAmbulatorial && (
                                      <Typography
                                        variant="subtitle2"
                                        style={{
                                          whiteSpace: 'nowrap',
                                          display: 'flex',
                                          alignItems: 'center',
                                          gap: 4
                                        }}
                                      >
                                        <BiPlusMedical />
                                        <b>
                                          <small>
                                            Centro cirúrgico ambulatorial
                                          </small>
                                        </b>
                                      </Typography>
                                    )}
                                    {p?.cartaoDesconto && (
                                      <Typography
                                        variant="subtitle2"
                                        style={{
                                          whiteSpace: 'nowrap',
                                          display: 'flex',
                                          alignItems: 'center',
                                          gap: 4
                                        }}
                                      >
                                        <MdLocalOffer />
                                        <b>
                                          <small>
                                            Paciente com cartão de desconto
                                          </small>
                                        </b>
                                      </Typography>
                                    )}

                                    <UBox
                                      render={
                                        !!p.centroCirurgicoAmbulatorial ||
                                        !!p.finalDeSemana ||
                                        !!pacoteInteligente
                                      }
                                    >
                                      <Alert
                                        severity="warning"
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'flex-start',
                                          padding: '8px'
                                        }}
                                        iconMapping={{
                                          warning: (
                                            <FiInfo
                                              style={{ color: 'orange' }}
                                            />
                                          )
                                        }}
                                      >
                                        {gerarDescricao(p)}
                                        <br />

                                        {p.finalDeSemana === true &&
                                          'Sábado a partir de 12h até domingo 23h59 e feriados.'}
                                      </Alert>
                                    </UBox>

                                    <Typography variant="body2">
                                      <UBox
                                        mt={2}
                                        component="small"
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="end"
                                        mr={1}
                                      >
                                        <Box
                                          component={FiClock}
                                          style={{ marginRight: '4px' }}
                                        />
                                        <b>
                                          <i>
                                            {formatarDataHoraLocal(p.dataEnvio)}
                                          </i>
                                        </b>
                                      </UBox>
                                    </Typography>
                                  </Grid>
                                  <ExpandMoreIcon className={styles.moreIcon} />
                                </Grid>
                              </Box>
                            </Box>
                          </TimelineContent>
                        </TimelineItem>
                      </>
                    )
                  })}
                </Grid>
              </Timeline>
            </Grid>
            {!ehXS && (
              <Grid item xs={12} sm={8} className={styles.containerCardDetail}>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="h6">Detalhes da proposta</Typography>
                </Box>
                <UBox
                  display="flex"
                  justifyContent="end"
                  render={
                    perfilAtual !== SynPerfis.PARCEIRO_EXTERNO &&
                    !loadingProposta &&
                    ![
                      StatusProposta.ENCERRADA.Id,
                      StatusProposta.RECUSADA_PELO_PACIENTE.Id,
                      StatusProposta.RECUSADA_PELO_MEDICO.Id
                    ].includes(propostaSelecionada?.status)
                  }
                >
                  <UBox>
                    <Button
                      variant="outlined"
                      onClick={() => handlePrint()}
                      disabled={loadingPdf}
                    >
                      {loadingPdf ? (
                        <CircularProgress size={24} />
                      ) : (
                        'Visualizar'
                      )}
                    </Button>
                  </UBox>

                  <UBox ml={1}>
                    <Button
                      variant="outlined"
                      onClick={() => financiamento(propostaSelecionada.id)}
                    >
                      Solicitar financiamento
                    </Button>
                  </UBox>
                </UBox>
                {/* </Box> */}
                <Box
                  mt={2}
                  py={2}
                  px={2}
                  minHeight="400"
                  component={Card}
                  className={styles.boxPropostaDetalhe}
                >
                  <Box mt={4} hidden={!loadingProposta} textAlign="center">
                    <CircularProgress />
                    <div>Carregando...</div>
                  </Box>
                  {propostaSelecionada && (
                    <Box hidden={loadingProposta}>
                      <>
                        {propostaSelecionada.motivo && (
                          <Alert
                            style={{ marginBottom: '10px' }}
                            severity="error"
                          >
                            {propostaSelecionada.motivo}
                          </Alert>
                        )}

                        {propostaSelecionada?.status ===
                          StatusProposta.APROVADA_PELO_MEDICO.Id && (
                          <Alert
                            style={{ marginBottom: '10px' }}
                            severity="success"
                          >
                            O médico aprovou a proposta.
                          </Alert>
                        )}

                        {propostaSelecionada?.status ===
                          StatusProposta.APROVADA_PELO_PACIENTE.Id && (
                          <Alert
                            style={{ marginBottom: '10px' }}
                            severity="success"
                            className={styles.aprovadoPacienteAlert}
                          >
                            {StatusProposta.APROVADA_PELO_PACIENTE.Descricao}
                          </Alert>
                        )}

                        {propostaSelecionada?.status ===
                          StatusProposta.AGUARDANDO_APROVACAO_PACIENTE.Id && (
                          <Alert
                            style={{ marginBottom: '10px' }}
                            severity="success"
                            className={styles.aguardandoPacienteAlert}
                          >
                            {
                              StatusProposta.AGUARDANDO_APROVACAO_PACIENTE
                                .Descricao
                            }
                          </Alert>
                        )}

                        {propostaSelecionada?.status ===
                          StatusProposta.VENCEDORA.Id && (
                          <Alert
                            style={{ marginBottom: '10px' }}
                            severity="success"
                            className={styles.propostaVencedoraAlert}
                          >
                            {StatusProposta.VENCEDORA.Descricao}
                          </Alert>
                        )}

                        <Box width="100%" textAlign="center" mb={5}>
                          <Card className={styles.hospitalCard}>
                            <Grid container spacing={1}>
                              <Grid item xs={12}>
                                <Typography variant="h6">
                                  <b>Valor total da proposta:</b>
                                </Typography>

                                <Box>
                                  <Grid container>
                                    <Grid item xs={12}>
                                      <Typography
                                        variant="body1"
                                        style={{ fontSize: '18px' }}
                                      >
                                        {formatarDinheiro(
                                          propostaSelecionada?.valorTotal
                                        )}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Box>
                                {!!obterItensContemplados() && (
                                  <Alert
                                    style={{
                                      marginBottom: '10px',
                                      marginTop: '16px',
                                      width: '100%'
                                    }}
                                    severity="info"
                                    className={styles.boxAlert}
                                  >
                                    <Typography variant="body1">
                                      <b>
                                        O valor total da proposta contempla:
                                      </b>
                                      <br />
                                      <span>{obterItensContemplados()}</span>
                                    </Typography>
                                  </Alert>
                                )}
                              </Grid>
                            </Grid>
                          </Card>
                        </Box>

                        <UBox render={!!propostaSelecionada.observacaoHospital}>
                          <Alert severity="info">
                            <Typography
                              variant="subtitle2"
                              className={styles.hositalCardText}
                              align="left"
                              color={
                                propostaSelecionada?.status === 9
                                  ? 'textSecondary'
                                  : 'primary'
                              }
                            >
                              Observação do hospital:
                            </Typography>

                            <Typography
                              align="left"
                              variant="body2"
                              color={
                                propostaSelecionada?.status === 9
                                  ? 'textSecondary'
                                  : 'primary'
                              }
                            >
                              {propostaSelecionada?.observacaoHospital}
                            </Typography>
                          </Alert>
                        </UBox>

                        <UBox
                          render={
                            (propostaSelecionada.propostaCentroCirurgicoAmbulatorial ===
                              true ||
                              propostaSelecionada?.propostaFinalDeSemana ===
                                true ||
                              propostaSelecionada.propostaDiasUteis === true) &&
                            (!!propostaSelecionada.pacoteInteligente ||
                              !!propostaSelecionada?.propostaFinalDeSemana)
                          }
                        >
                          <Alert
                            severity="warning"
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'flex-start',
                              padding: '8px'
                            }}
                            iconMapping={{
                              warning: <FiInfo style={{ color: 'orange' }} />
                            }}
                          >
                            {informarcaoAlert(propostaSelecionada)}
                            <br />

                            {propostaSelecionada?.propostaFinalDeSemana ===
                              true &&
                              'Sábado a partir de 12h até domingo 23h59 e feriados.'}
                          </Alert>
                        </UBox>

                        {propostaSelecionada.propostaSemLocalAtendimento && (
                          <Box width="100%" textAlign="left" my={2}>
                            <Box mt={2}>
                              <Alert severity="info">
                                <Typography variant="subtitle2">
                                  Proposta não contempla local de atendimento.
                                </Typography>
                              </Alert>
                            </Box>
                          </Box>
                        )}

                        {propostaSelecionada?.propostaSemHonorarioMedico && (
                          <Box width="100%" textAlign="left" my={2}>
                            <Box mt={2}>
                              <Alert severity="info">
                                <Typography variant="subtitle2">
                                  Proposta não contempla honorários da equipe
                                  médica.
                                </Typography>
                              </Alert>
                            </Box>
                          </Box>
                        )}

                        {propostaSelecionada?.informacaoExame && (
                          <Box width="100%" textAlign="left" my={2}>
                            <Box mt={2}>
                              <Alert severity="info">
                                <Typography variant="subtitle2">
                                  {propostaSelecionada?.informacaoExame}
                                </Typography>
                              </Alert>
                            </Box>
                          </Box>
                        )}
                        <Box width="100%" textAlign="left" my={2}>
                          <TextoExclusao />
                        </Box>
                        {propostaSelecionada.listaOPME?.length === 0 ? (
                          <Box width="100%" textAlign="left" my={2}>
                            <Alert severity="info">
                              <Typography variant="subtitle2">
                                Proposta não contempla itens OPME.
                              </Typography>
                            </Alert>
                          </Box>
                        ) : (
                          <>
                            <Box width="100%" textAlign="left" my={2}>
                              <Box mt={2}>
                                <Typography variant="h6">Lista OPME</Typography>
                              </Box>
                            </Box>
                            {propostaSelecionada?.listaOPME?.map((o, index) => (
                              <Card className={styles.OPMECard} key={o.id}>
                                <Box mb={1}>
                                  <Grid
                                    container
                                    direction="row"
                                    justify="space-between"
                                    alignItems="center"
                                  >
                                    <Typography variant="body2">
                                      <small>
                                        <b>
                                          {`${o.nomeParceiro} - ${
                                            !!formatarCNPJ(o.cnpjParceiro)
                                              ? formatarCNPJ(o.cnpjParceiro)
                                              : o.cnpjParceiro
                                          }`}
                                        </b>
                                      </small>
                                    </Typography>
                                  </Grid>
                                  <UBox
                                    mt={2}
                                    component={Grid}
                                    container
                                    direction="row"
                                    justify="space-between"
                                    alignItems="flex-start"
                                  >
                                    <Typography
                                      variant="h6"
                                      color="secondary"
                                      style={{ lineHeight: 1.1 }}
                                    >
                                      <b>{o.nomeOPME}</b>
                                    </Typography>
                                    <Typography variant="h6">
                                      <b>Qtd. {o.quantidade}</b>
                                    </Typography>
                                  </UBox>
                                </Box>

                                <UBox
                                  render={!o.pacoteInteligenteId}
                                  component={Grid}
                                  container
                                >
                                  <Grid item xs={12} sm={6}>
                                    <Typography variant="body2">
                                      <strong>Fabricante:</strong>{' '}
                                      {!!o.fabricante
                                        ? o.fabricante
                                        : 'Não informado'}
                                    </Typography>
                                  </Grid>

                                  <Grid item xs={12}>
                                    <Typography variant="body2">
                                      <strong>Observação:</strong>{' '}
                                      {o.observacao
                                        ? o.observacao
                                        : 'Não informado'}
                                    </Typography>
                                  </Grid>
                                </UBox>
                              </Card>
                            ))}
                          </>
                        )}

                        {propostaSelecionada?.examesLaboratorio?.exames
                          ?.length !== 0 && (
                          <Box width="100%" textAlign="left" my={2}>
                            <Box mt={2}>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-between'
                                }}
                              >
                                <Typography variant="h6">
                                  Lista de exames pré-operatórios
                                </Typography>
                              </div>
                            </Box>

                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell>Código</TableCell>
                                  <TableCell>Descrição</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {propostaSelecionada?.examesLaboratorio?.exames?.map(
                                  item => {
                                    return (
                                      <TableRow key={item.id}>
                                        <TableCell>{item.codigo}</TableCell>
                                        <TableCell>{item.descricao}</TableCell>
                                      </TableRow>
                                    )
                                  }
                                )}
                              </TableBody>
                            </Table>
                          </Box>
                        )}

                        {propostaSelecionada?.status ===
                          StatusProposta.AGUARDANDO_APROVACAO_MEDICO.Id &&
                          perfilAtual !== SynPerfis.PARCEIRO_EXTERNO && (
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={6}>
                                <Button
                                  style={{ ...btnRed }}
                                  color="default"
                                  variant="outlined"
                                  fullWidth
                                  onClick={() => setShowContestacao(true)}
                                >
                                  Recusar
                                </Button>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  fullWidth
                                  loading={aprovacaoLoading}
                                  disabled={aprovacaoLoading}
                                  onClick={() => submitAprovacao()}
                                >
                                  Aprovar
                                </Button>
                              </Grid>
                            </Grid>
                          )}
                      </>
                    </Box>
                  )}
                </Box>
              </Grid>
            )}
          </Grid>
          <ModalGenerico
            title="Por favor, conte-nos o motivo por ter recusado"
            open={showContestacao}
            onClose={() => setShowContestacao(false)}
            actions={
              <>
                <Button
                  color="default"
                  onClick={() => {
                    setShowContestacao(false)
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  onClick={handleSubmit(submitContestacao)}
                  loading={contestacaoLoading}
                  disabled={contestacaoLoading}
                >
                  Enviar
                </Button>
              </>
            }
          >
            <Box>
              <TextField
                erro={errors}
                multiline
                rows={6}
                name="contestacao"
                label="Motivação"
                inputRef={register}
              />
            </Box>
          </ModalGenerico>
        </>
      )}

      {/* <ImpressaoProposta ref={impressaoRef} dados={dadosImpressao} /> */}
    </Box>
  )
}
