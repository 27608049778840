import {
  Chip,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core'
import {
  BusinessCenter,
  LockOutlined,
  People,
  SdCardOutlined
} from '@material-ui/icons'
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined'

import { FaBed, FaLaptopMedical, FaStethoscope } from 'react-icons/fa'
import { GoPackage } from 'react-icons/go'
import { RiAlertFill } from 'react-icons/ri'

import { useHistory } from 'react-router-dom'
import Ubox from 'src/components/ui/Ubox'
import { SynPerfis } from 'src/core/consts/synPerfis'
import { SynRoutes } from 'src/core/consts/synRoutes'
import useAtividade from 'src/hooks/useAtividade'
import useAuth from 'src/hooks/useAuth'
import usePendencias from 'src/hooks/usePendencias'

function menuLateral(props) {
  const history = useHistory()
  const { perfilAtual, user } = useAuth()
  const { obterQtdePendente } = useAtividade()
  const { qtdPendencias } = usePendencias()
  return (
    <List>
      <Ubox render={perfilAtual !== SynPerfis.OPERACIONAL_HOSPITAL}>
        <ListItem
          button
          onClick={() => history.push(SynRoutes.listagemUsuarios)}
        >
          <ListItemIcon>
            <People />
          </ListItemIcon>
          <ListItemText primary="Usuários" />
        </ListItem>
      </Ubox>

      <Ubox render={perfilAtual !== SynPerfis.OPERACIONAL_HOSPITAL}>
        <ListItem button onClick={() => history.push(SynRoutes.acomodacoes)}>
          <ListItemIcon>
            <FaBed />
          </ListItemIcon>
          <ListItemText primary="Acomodações" />
        </ListItem>
      </Ubox>

      <ListItem button onClick={() => history.push(SynRoutes.equipamentos)}>
        <ListItemIcon>
          <FaLaptopMedical />
        </ListItemIcon>
        <ListItemText primary="Equipamentos" />
      </ListItem>

      {/* <ListItem button onClick={() => history.push(SynRoutes.dashboard)}>
        <ListItemIcon>
          <Dashboard />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItem> */}

      <ListItem button onClick={() => history.push(SynRoutes.listagemMedicos)}>
        <ListItemIcon>
          <FaStethoscope style={{ fontSize: '1.16rem', marginLeft: '2px' }} />
        </ListItemIcon>
        <ListItemText primary="Médicos" />
      </ListItem>

      <ListItem
        button
        onClick={() => history.push(SynRoutes.listagemPacotesOperacional)}
      >
        <ListItemIcon style={{ fontSize: '1.4rem' }}>
          <GoPackage />
        </ListItemIcon>
        <ListItemText primary="Pacotes" />
      </ListItem>

      <ListItem
        button
        onClick={() => history.push(SynRoutes.listagemOrcamentosOperacional)}
      >
        <ListItemIcon>
          <AssignmentOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary="Solicitações" />
      </ListItem>

      <ListItem
        button
        onClick={() => history.push(SynRoutes.listagemSolicitacoesPendencias)}
      >
        <ListItemIcon>
          <RiAlertFill size={22} />
        </ListItemIcon>
        <ListItemText
          primary={
            <Ubox>
              Pendências{' '}
              {qtdPendencias > 0 && (
                <Chip
                  variant="default"
                  color="secondary"
                  size="small"
                  label={qtdPendencias}
                />
              )}
            </Ubox>
          }
        />
      </ListItem>

      {/* <ListItem button onClick={() => history.push(SynRoutes.tarefas)}>
        <ListItemIcon style={{ fontSize: '1.3rem' }}>
          <FaTasks />
        </ListItemIcon>
        <ListItemText
          primary={
            <Ubox>
              Atividades{' '}
              {obterQtdePendente() > 0 && (
                <Chip
                  variant="default"
                  color="secondary"
                  size="small"
                  label={obterQtdePendente()}
                />
              )}
            </Ubox>
          }
        />
      </ListItem> */}

      {/* <ListItem
        button
        onClick={() => history.push(SynRoutes.listagemFinanciamentos)}
      >
        <ListItemIcon>
          <ChromeReaderMode />
        </ListItemIcon>
        <ListItemText primary="Financiamentos" />
      </ListItem> */}

      <ListItem
        button
        onClick={() => history.push(SynRoutes.listagemParceirosOperacional)}
      >
        <ListItemIcon>
          <BusinessCenter />
        </ListItemIcon>
        <ListItemText primary="Parceiros" />
      </ListItem>

      <ListItem button onClick={() => history.push(SynRoutes.alterarSenha)}>
        <ListItemIcon>
          <LockOutlined />
        </ListItemIcon>
        <ListItemText primary="Alterar senha" />
      </ListItem>

      <ListItem button onClick={() => history.push(SynRoutes.termos)}>
        <ListItemIcon>
          <SdCardOutlined />
        </ListItemIcon>
        <ListItemText primary="Termos" />
      </ListItem>
    </List>
  )
}

export default menuLateral
