export const MEIO_PAGAMENTO = {
  MAQUININHA: { id: 1, value: 'maquininha', name: 'Maquininha Stone SYN' },
  MAQUININHA_HOSPITAL: {
    id: 1,
    value: 'maquininha',
    name: 'Maquininha do Hospital'
  },
  LINK_PAGAMENTO: {
    id: 2,
    value: 'linkPagamento',
    name: 'Link de pagamento Pagarme'
  },
  CONTA_BANCARIA: {
    id: 3,
    value: 'contaBancariaSyn',
    name: 'Conta Bancária SYN'
  }
}

export default [
  { id: 1, value: 'maquininha', name: 'Maquininha Stone SYN' },
  { id: 5, value: 'maquininhaHospital', name: 'Maquininha do Hospital' },
  { id: 2, value: 'linkPagamento', name: 'Link de pagamento Pagarme' },
  { id: 3, value: 'contaBancariaSyn', name: 'Conta Bancária SYN' },
  { id: 4, value: 'contaBancariaHospital', name: 'Conta Bancária do Hospital' }
]
