// import { ResumoPropostaResponse } from 'src/types'
// : ResumoPropostaResponse
function informarcaoAlert(item) {
  const itemCentroAmbulatorial =
    item?.centroCirurgicoAmbulatorial ||
    item?.propostaCentroCirurgicoAmbulatorial
  const itemFinalDeSemana = item?.finalDeSemana || item?.propostaFinalDeSemana
  const itemDiasUteis =
    item?.diasUteis || item?.propostaDiasUteis || !itemFinalDeSemana

  var msgCartao = item?.cartaoDesconto
    ? 'Para pacientes com cartão de desconto. '
    : ''
  if (itemCentroAmbulatorial && itemDiasUteis) {
    return (
      msgCartao +
      'Para realização em qualquer dia e horário da semana no centro cirúrgico ambulatorial.'
    )
  } else if (itemCentroAmbulatorial && itemFinalDeSemana) {
    return (
      msgCartao +
      'Para realização somente em finais de semana no centro cirúrgico ambulatorial.'
    )
  } else if (!itemCentroAmbulatorial && itemDiasUteis) {
    return (
      msgCartao +
      'Para realização em qualquer dia e horário da semana no centro cirúrgico.'
    )
  } else if (!itemCentroAmbulatorial && itemFinalDeSemana) {
    return (
      msgCartao +
      'Para realização somente em finais de semana no centro cirúrgico.'
    )
  } else if (!itemCentroAmbulatorial && !itemDiasUteis && !itemFinalDeSemana) {
    return (
      msgCartao +
      'Para realização em qualquer dia e horário no centro cirúrgico.'
    )
  } else {
    return msgCartao + 'Informação não disponível.'
  }
}

export default informarcaoAlert
