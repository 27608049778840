import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  makeStyles
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'

import Tooltip from '@material-ui/core/Tooltip'
import CloseIcon from '@material-ui/icons/Close'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import Alert from '@material-ui/lab/Alert'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import { useHistory } from 'react-router-dom'
import ModalGenerico from 'src/components/modals/ModalGenerico'
import Button from 'src/components/ui/Button'
import useAuth from 'src/hooks/useAuth'
import {
  IOPMEResponse,
  IOrcamentoLocal,
  IOrcamentoProposta,
  ItemExameLaboratorioResponse,
  ItemTaxaOpmeHospital
} from 'src/types'
import Swal from 'sweetalert2'
import {
  calcularTaxaSyn,
  enviarProposta,
  enviarPropostaHospital
} from '../../api/proposta'
import { SynRoutes } from '../../core/consts/synRoutes'
import formatarDinheiro from '../../core/formatacoes/formatarDinheiro'
import { showNotificationErrorAPI } from '../../core/helpers/notification'
import { ModalEditarTaxa } from '../propostas/modals/ModalEditarTaxa'
import { ModalEditarTaxaOpme } from '../propostas/modals/ModalEditarTaxaOpme'
import { ModalEditarTaxaOpmeHospital } from '../propostas/modals/ModalEditarTaxaOpmeHospital'
import UBox from '../ui/Ubox'
import { IOrcamentoAcomodacao } from 'src/pages/dashboard/operacional/solicitacao/propostaHospital/prepararPropostaHospital'

const useStyles = makeStyles(theme => ({
  modalCard: {
    backgroundColor: theme.palette.grey[50],
    marginBottom: '20px',
    border: '1px solid #efefef'
  },
  hr: {
    margin: '3em 0'
  }
}))

interface Props {
  open: boolean
  onClose: () => void
  listaOpme: IOPMEResponse[] | undefined
  listaOrcamentoAcomodacao?: IOrcamentoAcomodacao[] | undefined
  valorOpme: number
  valorOpmeHospital?: number
  valorEquipeAnestesia?: number
  laboratorio: ItemExameLaboratorioResponse
  hospitais: IOrcamentoLocal[]
  honorarioMedico: number
  solicitacaoId: string
  orcamentoHospital?: IOrcamentoProposta
  ehHospital?: boolean
  contemplaExame?: boolean
  propostaFinaisDeSemana?: boolean
  propostaSemLocalAtendimento?: boolean
  taxaOpmeHospital?: number
}
const defaultPropostaSemLocalAtendimento: IOrcamentoLocal = {
  orcamentoId: '',
  localAtendimentoId: '',
  nome: '',
  taxa: 0,
  valorSala: 0,
  valorAnestesia: 0,
  valorBolsaSangue: 0,
  valorPreAnestesica: 0,
  valorReservaSangue: 0,
  valorRiscoCirurgico: 0,
  possuiAnestesia: false,
  selecionado: true,
  ehAnestesiaEquipeMedica: true
}

export default function ModalSubmitProposta({
  open,
  onClose,
  listaOpme,
  listaOrcamentoAcomodacao,
  valorOpme,
  valorOpmeHospital,
  laboratorio,
  hospitais,
  honorarioMedico,
  solicitacaoId,
  valorEquipeAnestesia,
  orcamentoHospital,
  ehHospital,
  contemplaExame,
  propostaFinaisDeSemana,
  taxaOpmeHospital
}: Props) {
  const history = useHistory()
  const styles = useStyles()

  const [loading, setLoading] = useState(false)

  const [showModalValores, setShowModalValores] = useState(false)
  const [showModalTaxa, setShowModalTaxa] = useState(false)
  const [showModalTaxaOPME, setShowModalTaxaOPME] = useState(false)
  const [
    hospitalSelecionado,
    setHospitalSelecionado
  ] = useState<IOrcamentoLocal>()
  const [listaHospitais, setListaHospitais] = useState<IOrcamentoLocal[]>(
    hospitais
  )
  const [propostaSemHospital, setPropostaSemHospital] = useState<boolean>(
    !(hospitais && hospitais.length > 0)
  )
  const [propostaSemOPME, setPropostaSemOPME] = useState<boolean>(
    !listaOpme?.length
  )
  const [showModalTaxaOpmeHospital, setShowModalTaxaOpmeHospital] = useState(
    false
  )
  const { user, perfilAtual } = useAuth()

  useEffect(() => {
    var hospitais = listaHospitais || []

    if (propostaSemHospital) {
      hospitais = [...hospitais, defaultPropostaSemLocalAtendimento]
    }
    const fnObterTaxa = async () => obterTaxasSyn(hospitais)
    fnObterTaxa()
  }, [])

  async function obterTaxasSyn(hospitais) {
    let hospitaisEditados = hospitais.map(h => {
      h.loading = true
      let valor = getValorSemComissao(h)

      let response = calcularTaxaSyn({ valor }).then(response => {
        let valores = response.data
        h.taxaSyn = valores.valorComissaoSYN
        h.taxaPorcentagem = valores.taxaSYN
        h.loading = false

        setListaHospitais([
          ...hospitais.filter(
            i => i.localAtendimentoId !== h.localAtendimentoId
          ),
          h
        ])
      })
      return h
    })

    setListaHospitais(hospitaisEditados)
  }

  function obterListaOrdenadaPorNome() {
    return listaHospitais.sort((a, b) => a.nome.localeCompare(b.nome))
  }

  function obterListaAcomodacoesSelecionadas() {
    return listaOrcamentoAcomodacao?.filter(a => a.selecionado)
  }

  function getValorSemComissao(hospital: IOrcamentoLocal) {
    let valorAnestesia =
      (!!ehHospital
        ? orcamentoHospital?.valorAnestesia
        : hospital.valorAnestesia) || 0

    let valorSala =
      (!!ehHospital ? orcamentoHospital?.valorSala : hospital.valorSala) || 0

    let valorBolsaSangue =
      (!!ehHospital
        ? orcamentoHospital?.valorBolsaSangue
        : hospital.valorBolsaSangue) || 0

    let valorReservaSangue =
      (!!ehHospital
        ? orcamentoHospital?.valorReservaSangue
        : hospital.valorReservaSangue) || 0

    let valorTotal =
      (hospital.ehAnestesiaEquipeMedica
        ? valorEquipeAnestesia || 0
        : valorAnestesia) +
      valorSala +
      (hospital?.valorRiscoCirurgico || 0) +
      (hospital?.valorPreAnestesica || 0) +
      valorBolsaSangue +
      valorReservaSangue +
      valorOpme * (1 + hospital.taxa / 100) +
      (laboratorio?.valorTotal || 0) +
      honorarioMedico

    return valorTotal
  }

  function obterValorOrcamentoHospital() {
    var lista = obterListaAcomodacoesSelecionadas()
    var valorMinProposta =
      (orcamentoHospital?.valorAnestesia || 0) +
      (orcamentoHospital?.valorSala || 0) +
      (orcamentoHospital?.valorReservaSangue || 0) +
      (orcamentoHospital?.valorBolsaSangue || 0)

    if (lista?.length > 0) {
      let valorMaxSala = lista.reduce(
        (max, num) => (num.valor > max.valor ? num : max),
        lista[0]
      )
      let valorMaxProposta =
        valorMinProposta - orcamentoHospital?.valorSala + valorMaxSala.valor

      return `${formatarDinheiro(valorMinProposta)} - ${formatarDinheiro(
        valorMaxProposta
      )}`
    }

    return formatarDinheiro(valorMinProposta)
  }

  function obterValorTaxaOpme(item: IOrcamentoLocal, ehHospital) {
    if (ehHospital) {
      console.log('taxa', item)
      // Não contabiliza valor de taxa de OPME de um OPME orçado pelo hospital
      var valorTaxa = 0
      if (item.taxa > 0) {
        valorTaxa = valorOpme - valorOpme * (1 - item.taxa / 100)
      } else {
        valorTaxa = valorOpme / taxaOpmeHospital
      }
      return formatarDinheiro(valorTaxa)
    }

    return formatarDinheiro(valorOpme - valorOpme * (1 - item.taxa / 100))
  }

  async function submit() {
    try {
      if (listaHospitais.find(h => !h.taxaSyn || h.taxaSyn === 0)) {
        const swalResult = await Swal.fire({
          title: 'Atenção',
          icon: 'warning',
          text:
            'Algumas propostas vão ser geradas sem a Taxa de serviço. Tem certeza que deseja continuar?',
          confirmButtonText: 'Gerar propostas',
          showCancelButton: true,
          cancelButtonText: 'Cancelar'
        })

        if (swalResult.isDismissed) return
      } else {
        let swalResult = await Swal.fire({
          title: 'Atenção',
          icon: 'warning',
          text: 'Tem certeza que deseja continuar?',
          confirmButtonText: 'Gerar propostas',
          showCancelButton: true,
          cancelButtonText: 'Cancelar'
        })
        if (swalResult.isDismissed) return
      }

      var listaOrcamentoOpmeSelecionado = []
      if (!!listaOpme && !ehHospital) {
        listaOpme?.forEach(o => {
          let selecionado = o.listaOrcamentoOPME?.find(
            orcamento => orcamento.selecionado
          )
          if (selecionado?.id) {
            listaOrcamentoOpmeSelecionado.push(selecionado?.id)
          }
        })
      }
      if (!!listaOpme && ehHospital) {
        listaOpme?.forEach(opme => {
          // Verifica se algum item da listaOrcamentoOPME está selecionado
          let selecionado = opme?.listaOrcamentoOPME?.find(
            item => item?.selecionado === true
          )
          // Verifica se o orcamentoOpmeHospital está selecionado
          const opmeHospitalSelecionado =
            opme?.orcamentoOpmeHospital?.selecionado === true

          if (selecionado) {
            listaOrcamentoOpmeSelecionado.push({
              id: selecionado.id,
              pacoteProcedimentoOpmeId: opme.pacoteProcedimentoOpmeId,
              valorUnitario: selecionado.valorUnitario,
              fabricante: selecionado.fabricante
            })
          }

          // Adiciona a verificação e inclusão do orcamentoOpmeHospital se selecionado
          if (opmeHospitalSelecionado) {
            listaOrcamentoOpmeSelecionado.push({
              id: null,
              pacoteProcedimentoOpmeId: opme?.pacoteProcedimentoOpmeId,
              valorUnitario: opme?.orcamentoOpmeHospital.valorUnitario,
              fabricante: opme?.orcamentoOpmeHospital.fabricante
            })
          }
        })
      }

      var listaOrcamentoLocalAtendimento = []
      if (!propostaSemHospital) {
        listaHospitais?.forEach(l => {
          if (l.selecionado) {
            listaOrcamentoLocalAtendimento.push({
              orcamentoId: l.orcamentoId,
              taxaSynPersonalizadaId: l.taxaPersonalizadaId,
              taxaPersonalizadaOpmeId: l.taxaPersonalizadaOpmeId
            })
          }
        })
      }

      setLoading(true)
      if (ehHospital) {
        orcamentoHospital.ehContemplaExame = contemplaExame
      }

      const payload = {
        solicitacaoOrcamentoId: solicitacaoId,
        propostaSemOPME: !listaOpme,
        listaOrcamentoOpmeSelecionado,
        ListaOrcamentoAcomodacaoSelecionado: listaOrcamentoAcomodacao?.filter(
          a => a.selecionado
        ),
        laboratorioId: laboratorio?.laboratorioId,
        propostaFinalDeSemana: propostaFinaisDeSemana,
        ...(!ehHospital && {
          taxaPersonalizadaOpmeId: !propostaSemHospital
            ? listaHospitais[0].taxaPersonalizadaOpmeId
            : null,
          propostaSemLocalAtendimento: propostaSemHospital,
          taxaSynPersonalizadaId: propostaSemHospital
            ? listaHospitais[0].taxaPersonalizadaId
            : null,
          listaOrcamentoLocalAtendimento,
          perfilAtual: perfilAtual
        }),
        ...(ehHospital && {
          taxaPersonalizadaOpmeId: itemTaxaOpmePersonalizada?.id,
          propostaSemLocalAtendimento: false,
          valorExamesPreOperatorios: laboratorio?.valorTotal
            ? laboratorio?.valorTotal
            : 0,
          orcamentoHospital: orcamentoHospital
        })
      }
      if (ehHospital) {
        await enviarPropostaHospital(payload)
      }

      if (!ehHospital) {
        await enviarProposta(payload)
      }

      Swal.fire({
        title: 'Parabéns!',
        text: 'A proposta foi enviada com sucesso.',
        icon: 'success',
        confirmButtonText: 'Continuar'
      }).then(result => {
        if (result.isConfirmed) {
          return history.push(
            `${SynRoutes.detalhesPropostas}?id=${solicitacaoId}`
          )
        }
      })
    } catch (erro) {
      showNotificationErrorAPI(erro)
    }
    setLoading(false)
  }

  function dadosModalEditarTaxa(hospital: IOrcamentoLocal) {
    return {
      descricao: 0,
      handleClose: () => setShowModalTaxa(false),
      titulo: 'Taxa de serviço personalizada',
      open: showModalTaxa,
      dinheiro: true,
      buttonLabel: 'Salvar',
      submitTaxa: setarTaxaSyn,
      valorTotalSemComissao: getValorSemComissao(hospital)
    }
  }
  function dadosModalEditarTaxaOPME(hospital: IOrcamentoLocal) {
    return {
      descricao: 0,
      handleClose: () => handleCloseModals(),
      titulo: 'Taxa de OPME personalizada',
      open: showModalTaxaOPME,
      dinheiro: true,
      buttonLabel: 'Salvar',
      submitTaxa: setarTaxaOPME,
      valorTotalSemComissao: valorOpme
    }
  }

  function dadosModalVisualizar(
    hospital: IOrcamentoLocal,
    propostaSemHospital: boolean,
    propostaSemOpme: boolean
  ): React.ComponentProps<typeof ModalGenerico> {
    return {
      open: showModalValores,
      onClose: () => setShowModalValores(false),
      title: hospital.nome || 'Detalhes da proposta',
      children: (
        <UBox pt={1}>
          <Grid container spacing={2} style={{ color: '#465a81' }}>
            {!propostaSemHospital && (
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2">
                  <b>Valor hospital:</b>
                  <br />
                  {formatarDinheiro(hospital.valorSala || 0)}
                </Typography>
              </Grid>
            )}

            {!!valorEquipeAnestesia && (
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2">
                  <b>
                    Valor anestesia
                    <br />
                    (Anestesia equipe médica):
                  </b>
                  <br />
                  {formatarDinheiro(valorEquipeAnestesia || 0)}
                </Typography>
              </Grid>
            )}

            {!valorEquipeAnestesia && !propostaSemHospital && (
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2">
                  <b>
                    Valor anestesia
                    <br />
                    (Anestesia hospital):
                  </b>
                  <br />
                  {formatarDinheiro(hospital.valorAnestesia || 0)}
                </Typography>
              </Grid>
            )}

            {!propostaSemHospital && (
              <>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2">
                    <b>Valor reserva de sangue:</b>
                    <br />
                    {formatarDinheiro(hospital.valorReservaSangue || 0)}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2">
                    <b>Valor bolsa de sangue:</b>
                    <br />
                    {formatarDinheiro(hospital.valorBolsaSangue || 0)}
                  </Typography>
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2">
                <b>Valor Taxa de serviço ({hospital.taxaPorcentagem}%):</b>
                <br />
                {formatarDinheiro(hospital.taxaSyn || 0)}
              </Typography>
            </Grid>
            <UBox
              render={hospital.valorPreAnestesica > 0 && !propostaSemHospital}
            >
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2">
                  <b>Valor pré-anestésica:</b>
                  <br />
                  {formatarDinheiro(hospital.valorPreAnestesica || 0)}
                </Typography>
              </Grid>
            </UBox>
            <UBox
              render={hospital.valorRiscoCirurgico > 0 && !propostaSemHospital}
            >
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2">
                  <b>Valor risco cirúrgico:</b>
                  <br />
                  {formatarDinheiro(hospital.valorRiscoCirurgico || 0)}
                </Typography>
              </Grid>
            </UBox>
            {(!propostaSemOpme || !ehHospital) && (
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2">
                  <b>Valor OPME:</b>
                  <br />
                  {formatarDinheiro(valorOpme || 0)}
                </Typography>
              </Grid>
            )}
            {!propostaSemHospital && (
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2">
                  <b>Valor taxa OPME ({hospital.taxa}%):</b>
                  <br />
                  {formatarDinheiro((valorOpme * hospital.taxa) / 100 || 0)}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2">
                <b>Valor honorários equipe médica:</b>
                <br />
                {formatarDinheiro(honorarioMedico || 0)}
              </Typography>
            </Grid>
            <UBox render={laboratorio?.valorTotal > 0}>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2">
                  <b>Valor exames:</b>
                  <br />
                  {formatarDinheiro(laboratorio?.valorTotal || 0)}
                </Typography>
              </Grid>
            </UBox>
          </Grid>
        </UBox>
      ),
      maxWidth: 'sm',
      fullWidth: true,
      actions: (
        <Button variant="outlined" onClick={() => setShowModalValores(false)}>
          Fechar
        </Button>
      )
    }
  }

  function handleOpenModalTaxa(hospital: IOrcamentoLocal) {
    setHospitalSelecionado(hospital)
    setShowModalTaxa(true)
  }

  function handleOpenModalTaxaOPME(hospital: IOrcamentoLocal) {
    setHospitalSelecionado(hospital)
    setShowModalTaxaOPME(true)
  }

  async function handleEditarTaxaOpmeHospital(hospital: IOrcamentoLocal) {
    setHospitalSelecionado(hospital)
    setShowModalTaxaOpmeHospital(true)
  }
  function handleCloseModals() {
    setShowModalTaxaOpmeHospital(false)
    setShowModalTaxaOPME(false)
  }

  function handleOpenModalValores(hospital: IOrcamentoLocal) {
    setHospitalSelecionado(hospital)
    setShowModalValores(true)
  }

  function setarTaxaSyn(
    id: string,
    usuario: string,
    valor: number,
    valorPorcentagem: number
  ) {
    const novoHospital = listaHospitais.find(
      h => h.localAtendimentoId === hospitalSelecionado.localAtendimentoId
    )

    novoHospital.taxaPersonalizadaId = id
    novoHospital.taxaPorcentagem = valorPorcentagem
    novoHospital.usuarioTaxaPersonalizada = usuario
    novoHospital.taxaSyn = valor

    const novaListaHospitais = listaHospitais.filter(
      h => h.localAtendimentoId !== novoHospital.localAtendimentoId
    )
    setListaHospitais([...novaListaHospitais, novoHospital])
  }
  const [
    itemTaxaOpmePersonalizada,
    setItemTaxaOpmePersonalizada
  ] = useState<ItemTaxaOpmeHospital>()
  async function setarTaxaOPME(
    id: string,
    usuario: string,
    valor: number,
    valorPorcentagem: number
  ) {
    let novoItem: ItemTaxaOpmeHospital = {
      id: id,
      usuario: usuario,
      valor: valor,
      valorPorcentagem: valorPorcentagem
    }
    setItemTaxaOpmePersonalizada(novoItem)
    const novoHospital = listaHospitais.find(
      h => h.localAtendimentoId === hospitalSelecionado.localAtendimentoId
    )
    novoHospital.taxaPersonalizadaOpmeId = id
    novoHospital.taxa = valorPorcentagem
    novoHospital.usuarioTaxaPersonalizadaOpme = usuario
    const novaListaHospitais = listaHospitais.filter(
      h => h.localAtendimentoId !== novoHospital.localAtendimentoId
    )
    setListaHospitais([...novaListaHospitais, novoHospital])
    await obterTaxasSyn(listaHospitais)
  }

  return (
    <>
      <Dialog open={open} fullWidth maxWidth="md">
        <DialogTitle>
          <div style={{ position: 'absolute', top: '10px', right: '20px' }}>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>

          <Typography variant="h6" style={{ marginRight: '2em' }}>
            Revise os dados para gerar a proposta
          </Typography>
        </DialogTitle>
        <DialogContent>
          {propostaSemHospital && !ehHospital && (
            <Box mb={1}>
              <Alert severity="info">
                Proposta será gerada sem local de atendimento.
              </Alert>
            </Box>
          )}
          <Box>
            {!listaOpme.length && (
              <Alert severity="info">Proposta será gerada sem OPME.</Alert>
            )}
            {!!listaOpme.length && (
              <>
                <Typography variant="subtitle1">Lista de OPME</Typography>

                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Nome</TableCell>
                      <TableCell>Quantidade</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {listaOpme?.map(i => (
                      <TableRow key={i.nome}>
                        <TableCell>{i.nome}</TableCell>
                        <TableCell>{i.quantidade}</TableCell>
                      </TableRow>
                    ))}
                    {!ehHospital && (
                      <TableRow>
                        <TableCell>
                          <strong>Valor OPME: </strong>
                        </TableCell>
                        <TableCell>
                          <strong>{formatarDinheiro(valorOpme || 0)}</strong>
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow></TableRow>
                  </TableBody>
                </Table>
              </>
            )}
          </Box>

          {!laboratorio && (
            <Box mt={1}>
              <Alert severity="info">
                Proposta será gerada sem exames pré-operatórios e laboratório.
              </Alert>
            </Box>
          )}
          {laboratorio && (
            <>
              <Divider className={styles.hr} />

              <Box>
                <Typography variant="subtitle1">
                  Exames pré-operatórios <br />
                  Laboratório: {laboratorio?.laboratorioNome}
                </Typography>

                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Código</TableCell>
                      <TableCell>Descrição</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {laboratorio?.exames.map(e => (
                      <TableRow key={e.id}>
                        <TableCell>{e.codigo}</TableCell>
                        <TableCell>{e.descricao}</TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell>
                        <strong>Valor exames:</strong>
                      </TableCell>
                      <TableCell>
                        <strong>
                          {formatarDinheiro(laboratorio?.valorTotal || 0)}
                        </strong>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </>
          )}

          <Divider className={styles.hr} />

          <Box>
            <Typography variant="subtitle1">
              {propostaSemHospital ? 'Propostas' : 'Propostas por hospital'}
            </Typography>

            <Table>
              <TableHead>
                <TableRow>
                  {(!propostaSemHospital || ehHospital) && (
                    <TableCell>
                      <Typography variant="body2" align="left">
                        <strong>Hospital</strong>
                      </Typography>
                    </TableCell>
                  )}
                  {!ehHospital && (
                    <TableCell>
                      <Typography variant="body2" align="left">
                        <strong>Valor sem taxa de serviço</strong>
                      </Typography>
                    </TableCell>
                  )}
                  {!ehHospital && (
                    <TableCell>
                      <Typography variant="body2" align="left">
                        <strong>Taxa de serviço</strong>
                      </Typography>
                    </TableCell>
                  )}

                  {(!propostaSemHospital || ehHospital) && !propostaSemOPME && (
                    <TableCell>
                      <Typography variant="body2" align="left">
                        <strong>Taxa de OPME</strong>
                      </Typography>
                    </TableCell>
                  )}
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {obterListaOrdenadaPorNome().map(i => (
                  <>
                    <TableRow key={i.localAtendimentoId}>
                      {!propostaSemHospital && <TableCell>{i.nome}</TableCell>}
                      <TableCell>
                        {ehHospital ? (
                          <UBox component={Typography} align="left">
                            {obterValorOrcamentoHospital()}
                          </UBox>
                        ) : (
                          <UBox component={Typography} align="left">
                            {formatarDinheiro(getValorSemComissao(i))}
                          </UBox>
                        )}
                      </TableCell>
                      {!ehHospital && (
                        <TableCell>
                          <Tooltip
                            title={
                              i.taxaPorcentagem
                                ? `Valor referente a ${
                                    !!i.taxaPersonalizadaId
                                      ? i.taxaPersonalizadaId
                                      : i.taxaPorcentagem
                                  }% de taxa`
                                : 'Calculando taxa...'
                            }
                          >
                            <UBox
                              loading={i.loading}
                              component={Typography}
                              textAlign="left"
                              align="left"
                              style={{
                                width: '100%',
                                color:
                                  !i.taxaSyn || i.taxaSyn === 0
                                    ? 'red'
                                    : !!i.taxaPersonalizadaId
                                    ? '#144266'
                                    : 'black',
                                fontWeight: !!i.taxaPersonalizadaId
                                  ? 'bold'
                                  : 'normal'
                              }}
                            >
                              {formatarDinheiro(i.taxaSyn || 0)}
                            </UBox>
                          </Tooltip>
                        </TableCell>
                      )}
                      {/* {JSON.stringify(i.taxa)} */}
                      {((valorOpme > 0 && !propostaSemHospital) ||
                        ehHospital) &&
                        !propostaSemOPME && (
                          <TableCell>
                            <Tooltip
                              title={
                                i.taxaPorcentagem
                                  ? `Valor referente a ${
                                      ehHospital && !i.taxaPersonalizadaOpmeId
                                        ? taxaOpmeHospital
                                        : i.taxa
                                    }% de taxa OPME`
                                  : 'Calculando taxa...'
                              }
                            >
                              <div>
                                <UBox
                                  loading={i.loading}
                                  component={Typography}
                                  align="left"
                                  justifySelf="center"
                                  style={{
                                    width: '100%',
                                    color:
                                      !i.taxaSyn || i.taxaSyn === 0
                                        ? 'red'
                                        : !!i.taxaPersonalizadaOpmeId
                                        ? '#144266'
                                        : 'black',
                                    fontWeight: !!i.taxaPersonalizadaOpmeId
                                      ? 'bold'
                                      : 'normal'
                                  }}
                                >
                                  {obterValorTaxaOpme(i, ehHospital)}
                                </UBox>
                              </div>
                            </Tooltip>
                          </TableCell>
                        )}
                      <TableCell style={{ textAlign: 'right' }}>
                        <PopupState variant="popover" popupId="demo-popup-menu">
                          {popupState => (
                            <React.Fragment>
                              <IconButton
                                aria-controls="simple-menu"
                                aria-haspopup="true"
                                aria-label="mais"
                                {...bindTrigger(popupState)}
                              >
                                <MoreVertIcon />
                              </IconButton>

                              <Menu
                                anchorOrigin={{
                                  vertical: 'top',
                                  horizontal: 'right'
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'right'
                                }}
                                {...bindMenu(popupState)}
                              >
                                {true && !ehHospital && (
                                  <MenuItem
                                    onClick={() => {
                                      popupState.close()
                                      handleOpenModalTaxa(i)
                                    }}
                                  >
                                    Alterar taxa de serviço
                                  </MenuItem>
                                )}
                                {((valorOpme > 0 && !propostaSemHospital) ||
                                  ehHospital) && (
                                  <MenuItem
                                    onClick={() => {
                                      popupState.close()
                                      ehHospital
                                        ? handleEditarTaxaOpmeHospital(i)
                                        : handleOpenModalTaxaOPME(i)
                                    }}
                                  >
                                    Alterar taxa de Opme
                                  </MenuItem>
                                )}
                                {true && !ehHospital && (
                                  <MenuItem
                                    onClick={() => {
                                      popupState.close()
                                      handleOpenModalValores(i)
                                    }}
                                  >
                                    Visualizar valores
                                  </MenuItem>
                                )}
                              </Menu>
                            </React.Fragment>
                          )}
                        </PopupState>
                      </TableCell>
                    </TableRow>
                    <UBox
                      render={!!i.taxaPersonalizadaId}
                      component={TableRow}
                      key={'parent' + i.localAtendimentoId}
                    >
                      <TableCell style={{ padding: 0 }} colSpan={5}>
                        <Alert severity="info">
                          Taxa de serviço alterada por{' '}
                          <b>{i.usuarioTaxaPersonalizada}</b>
                        </Alert>
                      </TableCell>
                    </UBox>
                    <UBox
                      render={!!i.taxaPersonalizadaOpmeId}
                      component={TableRow}
                      key={'parent' + i.localAtendimentoId}
                    >
                      <TableCell style={{ padding: 0 }} colSpan={5}>
                        <Alert severity="info">
                          Taxa de opme apenas para essa proposta foi alterada
                          por <b>{i.usuarioTaxaPersonalizadaOpme}</b>
                        </Alert>
                      </TableCell>
                    </UBox>
                  </>
                ))}
                <TableRow></TableRow>
              </TableBody>
            </Table>
          </Box>

          <Box my={3}>
            <Button
              fullWidth
              loading={loading}
              disabled={loading}
              onClick={() => submit()}
            >
              Gerar proposta
            </Button>
          </Box>
        </DialogContent>
      </Dialog>

      {showModalTaxa && hospitalSelecionado && (
        <ModalEditarTaxa {...dadosModalEditarTaxa(hospitalSelecionado)} />
      )}
      {showModalTaxaOPME && hospitalSelecionado && (
        <ModalEditarTaxaOpme
          {...dadosModalEditarTaxaOPME(hospitalSelecionado)}
        />
      )}
      {showModalTaxaOpmeHospital && hospitalSelecionado && (
        <ModalEditarTaxaOpmeHospital
          {...dadosModalEditarTaxaOPME(hospitalSelecionado)}
        />
      )}

      {showModalValores && hospitalSelecionado && (
        <ModalGenerico
          {...dadosModalVisualizar(
            hospitalSelecionado,
            propostaSemHospital,
            propostaSemOPME
          )}
        />
      )}
    </>
  )
}
