import { TextField as TextFieldMU } from '@material-ui/core'
import React, { CSSProperties, FC, KeyboardEvent, useCallback } from 'react'
import TextField, { TextFieldPropCustom } from './TextField'

type Props = TextFieldPropCustom & {
  // className?: string
  max?: number
  onValueChange: (value: number) => void
  // style?: CSSProperties
  value: number
  hidePrefix?: boolean
  noMoney?: boolean
}

const VALID_FIRST = /^[1-9]{1}$/
const VALID_NEXT = /^[0-9]{1}$/
const DELETE_KEY_CODE = 8

const CurrencyInput = ({
  // className = '',
  max = Number.MAX_SAFE_INTEGER,
  onValueChange,
  // style = {},
  erro,
  hidePrefix = false,
  value,
  noMoney,
  ...rest
}: Props) => {
  const valueAbsTrunc = Math.trunc(Math.abs(value))
  if (
    value !== valueAbsTrunc ||
    !Number.isFinite(value) ||
    Number.isNaN(value)
  ) {
    if (
      value !== valueAbsTrunc &&
      !Number.isNaN(valueAbsTrunc) &&
      Number.isFinite(valueAbsTrunc)
    ) {
      value = valueAbsTrunc
    } else value = 0
  }

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLDivElement>): void => {
      const { key, keyCode } = e
      if (
        (value === 0 && !VALID_FIRST.test(key)) ||
        (value !== 0 && !VALID_NEXT.test(key) && keyCode !== DELETE_KEY_CODE)
      ) {
        return
      }
      const valueString = value.toString()
      let nextValue: number
      if (keyCode !== DELETE_KEY_CODE) {
        const nextValueString: string =
          value === 0 ? key : `${valueString}${key}`
        nextValue = Number.parseInt(nextValueString, 10)
      } else {
        const nextValueString = valueString.slice(0, -1)
        nextValue =
          nextValueString === '' ? 0 : Number.parseInt(nextValueString, 10)
      }
      if (nextValue > max) {
        return
      }
      onValueChange(nextValue)
    },
    [max, onValueChange, value]
  )
  const handleChange = useCallback(() => {
    // DUMMY TO AVOID REACT WARNING
  }, [])
  let valueDisplay = (value / 100).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  })

  // const currencyFractionDigits = new Intl.NumberFormat('pt-BR', {
  //   style: 'currency',
  //   currency: 'BRL'
  // }).resolvedOptions().maximumFractionDigits

  if (hidePrefix) {
    valueDisplay = valueDisplay.replace('R$', '').replace(' ', '')
  }
  if (noMoney) {
    valueDisplay = valueDisplay
      .replace('R$', '')
      .replace(' ', '')
      .replace(',', '')
      .replace('.', '')
      .trim()

    if (valueDisplay[0] === '0') {
      valueDisplay = valueDisplay.substring(1)
    }

    if (valueDisplay[0] === '0') {
      valueDisplay = valueDisplay.substring(1)
    }
    // valueDisplay = value.toString()
  }

  return (
    <>
      {/* <div>{JSON.stringify(rest)}</div> */}
      {/* <TextField
        // key={valueDisplay}
        // className={className}

        inputMode="numeric"
        // onChange={handleChange}
        onKeyDown={handleKeyDown}
        // style={style}
        value={valueDisplay}
        {...rest}
      /> */}
      <TextFieldMU
        // key={valueDisplay}
        // className={className}
        type="tel"
        fullWidth
        variant="outlined"
        inputMode="numeric"
        // onChange={handleChange}
        onKeyDown={handleKeyDown}
        // style={style}
        value={valueDisplay}
        error={rest.errorMessage ? !!rest.errorMessage : !!erro[rest.name]}
        helperText={
          rest.errorMessage ? rest.errorMessage : erro[rest.name]?.message
        }
        {...rest}
      />

      {/* <input
        // className={className}

        inputMode="numeric"
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        // style={style}
        value={valueDisplay}
      /> */}
    </>
  )
}

export default CurrencyInput
