import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  Card,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { Alert } from '@material-ui/lab'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { FiInfo } from 'react-icons/fi'
import Button from 'src/components/ui/Button'
import { TextField } from 'src/components/ui/TextField'
import yup from 'src/components/yupCustom'
import StatusProposta from 'src/core/consts/statusProposta'
import { SynPerfis } from 'src/core/consts/synPerfis'
import formatarDinheiro from 'src/core/formatacoes/formatarDinheiro'
import { primeiraLetraMaiusculo } from 'src/core/helpers/common'
import informarcaoAlert from 'src/core/informacoes/informacaoAlert'
import useAuth from 'src/hooks/useAuth'
import { ResumoPropostaResponse } from 'src/types'
import Ubox from '../ui/Ubox'
import TextoExclusao from './textoExclusao'

const btnRed = {
  border: '1px solid red',
  color: 'red'
}

const contestacaoSchema = yup.object().shape({
  contestacao: yup.string().required().min(4).label('Contestação')
})

const useStyles = makeStyles(theme => ({
  container: {
    // background: theme.palette.background.paper
  },
  OPMECard: {
    padding: '20px',
    marginBottom: '20px',
    boxShadow: 'none',
    border: '1px solid #efefef',
    background: theme.palette.background.paper
  },
  OPMECardItem: {
    margin: '5px 0'
  },
  hospitalCard: {
    color: '#465a81',
    padding: '20px',
    textAlign: 'center',
    border: '1px solid #465b82',
    margin: '20px 0'
  },
  hositalCardText: {
    margin: '1px 0'
  },
  cardDivider: {
    margin: '10px 0'
  },
  motivoCard: {
    border: '1px solid #efefef',
    background: theme.palette.background.paper,
    padding: '10px'
  },
  aprovadoPacienteAlert: {
    backgroundColor: '#8d44ad39',
    color: '#8e44ad',
    '& .MuiAlert-icon': {
      color: '#8e44ad'
    }
  },
  aguardandoPacienteAlert: {
    backgroundColor: '#E67F2439',
    color: '#c26b20',
    '& .MuiAlert-icon': {
      color: '#c26b20'
    }
  },
  propostaVencedoraAlert: {
    backgroundColor: '#009431',
    color: '#fff',
    '& .MuiAlert-icon': {
      color: '#fff'
    }
  }
}))

interface Props {
  handleClose: () => void
  handlePrint: () => void
  financiamento?: (propostaId) => void
  propostaSelecionada: ResumoPropostaResponse
  esconderDadosSensiveis?: Boolean
  submitContestacao?: (data: { contestacao: string }) => Promise<void>
  submitAprovacao?: () => void
  handleEnviarParaPaciente?: () => void
  handleAprovadoPeloPaciente?: () => void
  contestacaoLoading?: boolean
  aprovacaoLoading?: boolean
  loadingEnviar?: boolean
  handlePropostaVencedora?: () => void
  valorExames?: number
  verificarSeExiste?: (valor: boolean, texto: string) => string
}

export default function DetalhesPropostaMobile({
  handleClose,
  handlePrint,
  handleEnviarParaPaciente,
  handleAprovadoPeloPaciente,
  loadingEnviar,
  propostaSelecionada,
  esconderDadosSensiveis,
  submitContestacao,
  contestacaoLoading,
  aprovacaoLoading,
  submitAprovacao,
  handlePropostaVencedora,
  valorExames,
  verificarSeExiste,
  financiamento
}: Props) {
  const styles = useStyles()
  const { user, perfilAtual } = useAuth()
  const ehComercial =
    perfilAtual === SynPerfis.COMERCIAL_B2B ||
    perfilAtual === SynPerfis.COMERCIAL_MEDICOS ||
    perfilAtual === SynPerfis.COMERCIAL_EXPANSAO

  const [loading, setLoading] = useState(false)
  const [showContestacao, setShowContestacao] = useState(false)

  useEffect(() => {
    setLoading(true)
    window.scrollTo(0, 60)

    setTimeout(() => setLoading(false), 600)
  }, [])

  const { register, handleSubmit, errors, watch } = useForm<{
    contestacao: string
  }>({
    defaultValues: {
      contestacao: ''
    },
    resolver: yupResolver(contestacaoSchema)
  })

  const command = watch()

  function obterItensContemplados() {
    var itens = []

    var dados = propostaSelecionada

    itens.push(
      verificarSeExiste(
        !dados?.propostaSemLocalAtendimento,
        'valor do hospital'
      )
    )
    itens.push(verificarSeExiste(dados?.temAnestesia, 'anestesia'))

    itens.push(
      verificarSeExiste(dados?.temPreAnestesica, 'avaliação pré-anestésica')
    )
    itens.push(
      verificarSeExiste(
        dados?.temRiscoCirurgico,
        'avaliação de risco cirúrgico'
      )
    )

    itens.push(verificarSeExiste(dados?.temExames, 'exames pré-operatórios'))
    itens.push(
      verificarSeExiste(dados?.listaOPME?.length !== 0, "OPME's, taxa de OPME")
    )
    itens.push(verificarSeExiste(dados?.temTaxaSyn, 'taxa de serviço'))
    itens.push(
      verificarSeExiste(
        dados?.temReservaSangue,
        'reserva de sangue, bolsa(s) de sangue'
      )
    )

    itens = itens.filter(i => !!i)

    itens.push(
      verificarSeExiste(
        !dados?.propostaSemHonorarioMedico,
        ' honorários equipe médica'
      )
    )

    itens = itens.filter(i => !!i)

    if (itens.length > 0) {
      var texto = itens.slice(0, -1).join(', ')
      texto += (itens.length > 1 ? ' e ' : '') + itens.slice(-1)
      return primeiraLetraMaiusculo(`${texto}`)
    }
  }

  if (loading) {
    return (
      <Box textAlign="center">
        <CircularProgress />
        <div>Carregando...</div>
      </Box>
    )
  }

  return (
    <Box className={styles.container}>
      {!showContestacao ? (
        <Ubox style={{ width: '100%' }}>
          <Box>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="close"
                onClick={() => handleClose()}
              >
                <ArrowBackIcon />
              </IconButton>
              <Typography variant="h6">
                Detalhes da proposta {propostaSelecionada?.codigo}
              </Typography>
            </div>
          </Box>

          <Ubox style={{ width: '100%' }} render={!ehComercial} mt={3}>
            <Grid container>
              <Ubox
                render={perfilAtual !== SynPerfis.PARCEIRO_EXTERNO}
                style={{ width: '100%' }}
              >
                <Grid item xs={12}>
                  {/* {!(perfilAtual === SynPerfis.PACIENTE || perfilAtual === SynPerfis.MEDICO) && */}
                  {![
                    StatusProposta.ENCERRADA.Id,
                    StatusProposta.RECUSADA_PELO_PACIENTE.Id,
                    StatusProposta.RECUSADA_PELO_MEDICO.Id
                  ].includes(propostaSelecionada?.status) && (
                    <Button
                      style={{ marginBottom: '10px' }}
                      variant="outlined"
                      fullWidth
                      onClick={() => handlePrint()}
                    >
                      Visualizar
                    </Button>
                  )}
                  {/* } */}
                </Grid>
                {!!financiamento && (
                  <Grid item xs={12}>
                    {/* {!(perfilAtual === SynPerfis.PACIENTE || perfilAtual === SynPerfis.MEDICO) && */}
                    {![
                      StatusProposta.ENCERRADA.Id,
                      StatusProposta.RECUSADA_PELO_PACIENTE.Id,
                      StatusProposta.RECUSADA_PELO_MEDICO.Id
                    ].includes(propostaSelecionada?.status) && (
                      <Button
                        style={{ marginBottom: '10px' }}
                        variant="outlined"
                        fullWidth
                        onClick={() => financiamento(propostaSelecionada.id)}
                      >
                        Solicitar financiamento
                      </Button>
                    )}
                    {/* } */}
                  </Grid>
                )}
              </Ubox>
              {!esconderDadosSensiveis &&
                propostaSelecionada?.status ===
                  StatusProposta.APROVADA_PELO_MEDICO.Id && (
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      loading={loadingEnviar}
                      disabled={loadingEnviar}
                      onClick={() => {
                        handleEnviarParaPaciente()
                        handleClose()
                      }}
                    >
                      Enviar ao paciente
                    </Button>
                  </Grid>
                )}
              {!esconderDadosSensiveis &&
                propostaSelecionada?.status ===
                  StatusProposta.APROVADA_PELO_PACIENTE.Id && (
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      loading={loadingEnviar}
                      disabled={loadingEnviar}
                      color="secondary"
                      onClick={() => {
                        handlePropostaVencedora()
                        handleClose()
                      }}
                    >
                      Proposta vencedora
                    </Button>
                  </Grid>
                )}

              {!esconderDadosSensiveis &&
                propostaSelecionada?.status ===
                  StatusProposta.AGUARDANDO_APROVACAO_PACIENTE.Id && (
                  <Button
                    fullWidth
                    loading={loadingEnviar}
                    disabled={loadingEnviar}
                    onClick={() => {
                      handleAprovadoPeloPaciente()
                      handleClose()
                    }}
                  >
                    Aprovada pelo paciente
                  </Button>
                )}
            </Grid>
          </Ubox>

          {propostaSelecionada?.status !== 1 && (
            <Box my={3}>
              {propostaSelecionada?.motivo && (
                <Alert severity="error">{propostaSelecionada?.motivo}</Alert>
              )}

              {propostaSelecionada?.status === 2 && (
                <Alert severity="success">O médico aprovou a proposta.</Alert>
              )}

              {propostaSelecionada?.status ===
                StatusProposta.APROVADA_PELO_PACIENTE.Id && (
                <Alert
                  severity="success"
                  className={styles.aprovadoPacienteAlert}
                >
                  {StatusProposta.APROVADA_PELO_PACIENTE.Descricao}
                </Alert>
              )}

              {propostaSelecionada?.status ===
                StatusProposta.AGUARDANDO_APROVACAO_PACIENTE.Id && (
                <Alert
                  severity="success"
                  className={styles.aguardandoPacienteAlert}
                >
                  {StatusProposta.AGUARDANDO_APROVACAO_PACIENTE.Descricao}
                </Alert>
              )}

              {propostaSelecionada?.status === StatusProposta.VENCEDORA.Id && (
                <Alert
                  severity="success"
                  className={styles.propostaVencedoraAlert}
                >
                  {StatusProposta.VENCEDORA.Descricao}
                </Alert>
              )}
            </Box>
          )}
          <Box my={2}>
            {/* <Typography align="left" variant="h6">
              Hospital
            </Typography> */}
            {esconderDadosSensiveis ? (
              <Card className={styles.hospitalCard}>
                <Typography variant="h6" align="center">
                  <b>Valor Total da Proposta:</b>
                </Typography>
                <Grid item xs={12} style={{ marginTop: '10px' }}>
                  <Typography variant="h6">
                    {formatarDinheiro(propostaSelecionada?.valorTotal)}
                  </Typography>
                </Grid>
                <Alert
                  style={{
                    marginBottom: '10px',
                    marginTop: '16px'
                    // textAlign: 'left'
                  }}
                  severity="info"
                >
                  <Typography variant="body1">
                    <b>O valor total da proposta contempla:</b>
                    <br />
                    <span>{obterItensContemplados()}</span>
                  </Typography>
                </Alert>
              </Card>
            ) : (
              <Card className={styles.hospitalCard}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="h6" align="left">
                      <b>{propostaSelecionada?.localAtendimento}</b>
                    </Typography>
                    <Box>
                      <Grid container>
                        {!propostaSelecionada?.propostaSemLocalAtendimento && (
                          <Grid item xs={12} style={{ padding: '5px' }}>
                            <Typography
                              variant="subtitle2"
                              className={styles.hositalCardText}
                              align="left"
                            >
                              Valor hospital:
                            </Typography>

                            <Typography align="left" variant="body2">
                              {propostaSelecionada?.valorSala
                                ? formatarDinheiro(
                                    propostaSelecionada?.valorSala
                                  )
                                : 'Não informado'}
                            </Typography>
                          </Grid>
                        )}
                        {propostaSelecionada?.temAnestesia && (
                          <Grid item xs={12} style={{ padding: '5px' }}>
                            <Typography
                              variant="subtitle2"
                              className={styles.hositalCardText}
                              align="left"
                            >
                              {propostaSelecionada?.equipeAnestesia === 'M'
                                ? 'Valor anestesia (equipe médica): '
                                : 'Valor anestesia: (Hospital)'}
                            </Typography>

                            <Typography align="left" variant="body2">
                              {propostaSelecionada?.valorAnestesia
                                ? formatarDinheiro(
                                    propostaSelecionada?.valorAnestesia || 0
                                  )
                                : 'Não informado'}
                            </Typography>
                          </Grid>
                        )}
                        {propostaSelecionada?.valorBancoSangue !== null && (
                          <Grid item xs={12} style={{ padding: '5px' }}>
                            <Typography
                              variant="subtitle2"
                              className={styles.hositalCardText}
                              align="left"
                            >
                              Valor bolsa de sangue:
                            </Typography>

                            <Typography align="left" variant="body2">
                              {propostaSelecionada?.valorBancoSangue
                                ? formatarDinheiro(
                                    propostaSelecionada?.valorBancoSangue || 0
                                  )
                                : 'R$ 0,00'}
                            </Typography>
                          </Grid>
                        )}
                        {propostaSelecionada?.valorReservaSangue !== null && (
                          <Grid item xs={12} style={{ padding: '5px' }}>
                            <Typography
                              variant="subtitle2"
                              className={styles.hositalCardText}
                              align="left"
                            >
                              Valor reserva de sangue:
                            </Typography>

                            <Typography align="left" variant="body2">
                              {propostaSelecionada?.valorReservaSangue
                                ? formatarDinheiro(
                                    propostaSelecionada?.valorReservaSangue || 0
                                  )
                                : 'R$ 0,00'}
                            </Typography>
                          </Grid>
                        )}

                        {propostaSelecionada?.valorPreAnestesica !== null && (
                          <Grid item xs={12} style={{ padding: '5px' }}>
                            <Typography
                              variant="subtitle2"
                              className={styles.hositalCardText}
                              align="left"
                            >
                              Valor avaliação pré-anestésica:
                            </Typography>

                            <Typography align="left" variant="body2">
                              {propostaSelecionada?.valorPreAnestesica
                                ? formatarDinheiro(
                                    propostaSelecionada?.valorPreAnestesica || 0
                                  )
                                : 'R$ 0,00'}
                            </Typography>
                          </Grid>
                        )}

                        {propostaSelecionada?.valorRiscoCirurgico !== null && (
                          <Grid item xs={12} style={{ padding: '5px' }}>
                            <Typography
                              variant="subtitle2"
                              className={styles.hositalCardText}
                              align="left"
                            >
                              Valor avaliação risco cirúrgico:
                            </Typography>

                            <Typography align="left" variant="body2">
                              {propostaSelecionada?.valorRiscoCirurgico
                                ? formatarDinheiro(
                                    propostaSelecionada?.valorRiscoCirurgico ||
                                      0
                                  )
                                : 'R$ 0,00'}
                            </Typography>
                          </Grid>
                        )}

                        {perfilAtual === SynPerfis.OPERACIONAL &&
                          !propostaSelecionada?.propostaSemOpme &&
                          propostaSelecionada?.status !==
                            StatusProposta.PENDENTE_REVISAO.Id && (
                            <Grid item xs={12} style={{ padding: '5px' }}>
                              <Typography
                                variant="subtitle2"
                                className={styles.hositalCardText}
                                align="left"
                              >
                                Total OPME:
                              </Typography>

                              <Typography align="left" variant="body2">
                                {formatarDinheiro(
                                  propostaSelecionada?.valorTotalOPME
                                ) || ' R$ 0,00'}
                              </Typography>
                            </Grid>
                          )}

                        {!propostaSelecionada?.propostaSemLocalAtendimento && (
                          <Grid item xs={12} style={{ padding: '5px' }}>
                            <Typography
                              variant="subtitle2"
                              className={styles.hositalCardText}
                              align="left"
                            >
                              Taxa OPME ({propostaSelecionada?.taxaOPME}
                              %):
                            </Typography>

                            <Typography align="left" variant="body2">
                              {formatarDinheiro(
                                propostaSelecionada?.valorOPME
                              ) || ' R$ 0,00'}
                            </Typography>
                          </Grid>
                        )}
                        {propostaSelecionada?.status !==
                          StatusProposta.PENDENTE_REVISAO.Id &&
                          perfilAtual !== SynPerfis.OPERACIONAL_HOSPITAL &&
                          perfilAtual !== SynPerfis.ADMIN_HOSPITAL && (
                            <Grid item xs={12} style={{ padding: '5px' }}>
                              <Typography
                                variant="subtitle2"
                                className={styles.hositalCardText}
                                align="left"
                              >
                                Taxa de serviço (
                                {propostaSelecionada?.taxaSYN.toFixed(2)}
                                %):
                              </Typography>
                              <Typography align="left" variant="body2">
                                {formatarDinheiro(
                                  propostaSelecionada?.valorComissaoSYN
                                ) || ' R$ 0,00'}
                              </Typography>
                            </Grid>
                          )}
                        {/* <Grid item xs={12} style={{ padding: '5px' }}>
                          <Typography
                            variant="subtitle2"
                            className={styles.hositalCardText}
                            align="left"
                          >
                            Exames pré-operatórios:
                          </Typography>
                          <Typography align="left" variant="body2">
                            {formatarDinheiro(
                              propostaSelecionada?.valorExamesPreOperatorios
                            ) || ' R$ 0,00'}
                          </Typography>
                        </Grid> */}
                        {!propostaSelecionada?.propostaSemHonorarioMedico &&
                          propostaSelecionada?.status !==
                            StatusProposta.PENDENTE_REVISAO.Id &&
                          perfilAtual === SynPerfis.OPERACIONAL && (
                            <Grid item xs={12} style={{ padding: '5px' }}>
                              <Typography
                                variant="subtitle2"
                                className={styles.hositalCardText}
                                align="left"
                              >
                                Honorários equipe médica:
                              </Typography>
                              <Typography align="left" variant="body2">
                                {formatarDinheiro(
                                  propostaSelecionada?.honorarioMedico
                                ) || ' R$ 0,00'}
                              </Typography>
                            </Grid>
                          )}

                        <Ubox
                          render={
                            propostaSelecionada?.informacaoExame?.length > 0
                          }
                        >
                          <Grid item xs={12} sm={12} style={{ padding: '5px' }}>
                            <Grid
                              container
                              direction="row"
                              alignItems="flex-end"
                            >
                              <Grid item xs={12} sm={12}>
                                <Typography
                                  variant="subtitle2"
                                  className={styles.hositalCardText}
                                  align="left"
                                >
                                  Exames solicitados pelo médico:
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <Typography align="left" variant="body2">
                                  {propostaSelecionada?.informacaoExame}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Ubox>
                        {perfilAtual === SynPerfis.OPERACIONAL && (
                          <Grid item xs={12}>
                            <Divider className={styles.cardDivider} />
                            <Typography variant="h6">
                              <strong>Valor Total:</strong>{' '}
                              {formatarDinheiro(
                                propostaSelecionada?.valorTotal
                              )}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Card>
            )}
          </Box>

          <Ubox
            style={{ marginBottom: '10px' }}
            render={
              (propostaSelecionada.propostaCentroCirurgicoAmbulatorial ===
                true ||
                propostaSelecionada?.propostaFinalDeSemana === true ||
                propostaSelecionada.propostaDiasUteis === true) &&
              (!!propostaSelecionada.pacoteInteligente ||
                !!propostaSelecionada?.propostaFinalDeSemana)
            }
          >
            <Alert
              severity="warning"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                padding: '8px'
              }}
              iconMapping={{ warning: <FiInfo style={{ color: 'orange' }} /> }}
            >
              {informarcaoAlert(propostaSelecionada)}
              <br />

              {propostaSelecionada?.propostaFinalDeSemana === true &&
                'Sábado a partir de 12h até domingo 23h59 e feriados.'}
            </Alert>
          </Ubox>

          {propostaSelecionada?.informacaoExame && (
            <Box width="100%" textAlign="left" my={2}>
              <Box mt={2}>
                <Alert severity="info">
                  <Typography variant="subtitle2">
                    {propostaSelecionada?.informacaoExame}
                  </Typography>
                </Alert>
              </Box>
            </Box>
          )}
          <Ubox render={!!propostaSelecionada.observacaoHospital}>
            <Alert severity="info">
              <Typography
                variant="subtitle2"
                className={styles.hositalCardText}
                align="left"
                color={
                  propostaSelecionada?.status === 9
                    ? 'textSecondary'
                    : 'primary'
                }
              >
                Observação do hospital:
              </Typography>

              <Typography
                align="left"
                variant="body2"
                color={
                  propostaSelecionada?.status === 9
                    ? 'textSecondary'
                    : 'primary'
                }
              >
                {propostaSelecionada?.observacaoHospital}
              </Typography>
            </Alert>
          </Ubox>

          {propostaSelecionada?.propostaSemLocalAtendimento && (
            <Box width="100%" textAlign="left" my={2}>
              <Box mt={2}>
                <Alert severity="info">
                  <Typography variant="subtitle2">
                    Proposta não contempla local de atendimento.
                  </Typography>
                </Alert>
              </Box>
            </Box>
          )}

          {propostaSelecionada?.propostaSemHonorarioMedico && (
            <Box width="100%" textAlign="left" my={2}>
              <Box mt={2}>
                <Alert severity="info">
                  <Typography variant="subtitle2">
                    Proposta não contempla honorários da equipe médica.
                  </Typography>
                </Alert>
              </Box>
            </Box>
          )}
          {propostaSelecionada?.listaOPME?.length === 0 ? (
            <Box width="100%" textAlign="left" my={2}>
              <Box mt={2}>
                <Alert severity="info">
                  <Typography variant="subtitle2">
                    Proposta não contempla itens OPME.
                  </Typography>
                </Alert>
              </Box>
            </Box>
          ) : (
            <Box my={2}>
              <Typography variant="h6">Lista OPME</Typography>
              {propostaSelecionada?.listaOPME.map(o => {
                return (
                  <Card className={styles.OPMECard} key={o.id}>
                    <Box mb={1}>
                      <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                      >
                        <Typography variant="h6">{o.nomeOPME}</Typography>
                        <Typography variant="body2">
                          <strong>Qtd.</strong> {o.quantidade}
                        </Typography>
                      </Grid>
                    </Box>

                    <Grid container>
                      <Grid item xs={12} className={styles.OPMECardItem}>
                        <Typography variant="body2">
                          <strong>Fornecedor:</strong> {o.nomeParceiro}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} className={styles.OPMECardItem}>
                        <Typography variant="body2">
                          <strong>Fabricante:</strong> {o.fabricante}
                        </Typography>
                      </Grid>

                      {!esconderDadosSensiveis && (
                        <Ubox
                          render={
                            !!o.pacoteInteligenteId &&
                            !!o.valorUnitario &&
                            perfilAtual !== SynPerfis.OPERACIONAL_HOSPITAL &&
                            perfilAtual !== SynPerfis.ADMIN_HOSPITAL
                          }
                          // component={Grid}
                          // container
                          item
                          xs={12}
                          className={styles.OPMECardItem}
                        >
                          <Typography variant="body2">
                            <strong>Valor un.:</strong>{' '}
                            {formatarDinheiro(o.valorUnitario)}
                          </Typography>
                        </Ubox>
                      )}

                      <Grid item xs={12} className={styles.OPMECardItem}>
                        <Typography variant="body2">
                          <strong>Descrição:</strong>{' '}
                          {o.descricao ? o.descricao : 'Não informado'}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} className={styles.OPMECardItem}>
                        <Typography variant="body2">
                          <strong>Observação:</strong>{' '}
                          {o.observacao ? o.observacao : 'Não informado'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Card>
                )
              })}
            </Box>
          )}

          {propostaSelecionada.examesLaboratorio.exames.length !== 0 && (
            <Box my={2} style={{ borderTop: '1px solid #c4c4c4' }}>
              <Box mt={2}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <Typography variant="h6">
                    Lista de exames pré-operatórios
                  </Typography>
                  <Typography variant="subtitle1">
                    <strong>Total exames:</strong>{' '}
                    {formatarDinheiro(valorExames)}
                  </Typography>
                </div>
              </Box>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Código</TableCell>
                    <TableCell>Descrição</TableCell>
                    {!esconderDadosSensiveis && <TableCell>Valor</TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {propostaSelecionada.examesLaboratorio.exames?.map(item => {
                    return (
                      <TableRow key={item.id}>
                        <TableCell>{item.codigo}</TableCell>
                        <TableCell>{item.descricao}</TableCell>
                        {!esconderDadosSensiveis && (
                          <TableCell>{item.valor}</TableCell>
                        )}
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </Box>
          )}
          <Box width="100%" textAlign="left" my={2}>
            <TextoExclusao />
          </Box>
          {perfilAtual === SynPerfis.MEDICO &&
            propostaSelecionada?.status ===
              StatusProposta.AGUARDANDO_APROVACAO_MEDICO.Id && (
              <Box mt={3}>
                <Grid container>
                  <Grid item xs={12}>
                    <Button
                      style={{ ...btnRed, margin: '10px 0' }}
                      size="large"
                      color="default"
                      variant="outlined"
                      fullWidth
                      onClick={() => setShowContestacao(true)}
                    >
                      Recusar
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      size="large"
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={() => submitAprovacao()}
                      loading={aprovacaoLoading}
                      disabled={aprovacaoLoading}
                    >
                      Aprovar
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            )}

          {perfilAtual === SynPerfis.PACIENTE &&
            propostaSelecionada?.status ===
              StatusProposta.AGUARDANDO_APROVACAO_PACIENTE.Id && (
              <Box mt={3}>
                <Grid container>
                  <Grid item xs={12}>
                    <Button
                      style={{ ...btnRed, margin: '10px 0' }}
                      size="large"
                      color="default"
                      variant="outlined"
                      fullWidth
                      onClick={() => setShowContestacao(true)}
                    >
                      Recusar
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      size="large"
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={() => submitAprovacao()}
                      loading={aprovacaoLoading}
                      disabled={aprovacaoLoading}
                    >
                      Aprovar
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            )}
        </Ubox>
      ) : (
        <Box>
          <Box mb={2}>
            <Grid item xs={12}>
              <Typography variant="h6">
                Por favor, conte-nos o motivo por ter recusado
              </Typography>
            </Grid>
          </Box>
          <form onSubmit={handleSubmit(submitContestacao)}>
            <TextField
              erro={errors}
              multiline
              rows={6}
              name="contestacao"
              label="Motivação"
              inputRef={register}
              value={command?.contestacao}
            />
            <Box my={4}>
              <Button
                size="large"
                variant="contained"
                color="default"
                fullWidth
                onClick={() => setShowContestacao(false)}
                type="reset"
              >
                Cancelar
              </Button>
              <Button
                size="large"
                variant="contained"
                color="primary"
                style={{ marginTop: '10px' }}
                fullWidth
                type="submit"
                loading={contestacaoLoading}
                disabled={contestacaoLoading}
              >
                Enviar
              </Button>
            </Box>
          </form>
        </Box>
      )}
    </Box>
  )
}
