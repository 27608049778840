import { AxiosResponse } from 'axios';
import api from 'src/services/api';

export interface ISalvarEquipamentoCommand {
  id?: string;
  descricao: string;
  ativo: boolean;
}

export interface IEquipamentoResponse {
  id: string;
  descricao: string;
  ativo: boolean;
}

export interface EquipamentoParams {
  pageNumber?: number;
  pageSize?: number;
  mostrarDesativados?: boolean;
  descricao?: string;
}

export const obterTodosEquipamentos = (
  params: EquipamentoParams
): Promise<AxiosResponse<IEquipamentoResponse[]>> => {
  const queryParams = new URLSearchParams();

  if (params.pageNumber) queryParams.append('pageNumber', params.pageNumber.toString());
  if (params.pageSize) queryParams.append('pageSize', params.pageSize.toString());
  if (params.mostrarDesativados !== undefined) queryParams.append('mostrarDesativados', params.mostrarDesativados.toString());
  if (params.descricao) queryParams.append('descricao', params.descricao);

  return api.get(`/equipamento/search?${queryParams.toString()}`);
};

export const obterEquipamentosTenant = (
  params: EquipamentoParams
): Promise<AxiosResponse<IEquipamentoResponse[]>> => {
  const queryParams = new URLSearchParams();

  if (params.pageNumber) queryParams.append('pageNumber', params.pageNumber.toString());
  if (params.pageSize) queryParams.append('pageSize', params.pageSize.toString());
  if (params.mostrarDesativados !== undefined) queryParams.append('mostrarDesativados', params.mostrarDesativados.toString());
  if (params.descricao) queryParams.append('descricao', params.descricao);

  return api.get(`/equipamento/tenant/search?${queryParams.toString()}`);
};

export const obterEquipamentosModelo = (
  params: EquipamentoParams
): Promise<AxiosResponse<IEquipamentoResponse[]>> => {
  const queryParams = new URLSearchParams();

  if (params.pageNumber) queryParams.append('pageNumber', params.pageNumber.toString());
  if (params.pageSize) queryParams.append('pageSize', params.pageSize.toString());
  if (params.mostrarDesativados !== undefined) queryParams.append('mostrarDesativados', params.mostrarDesativados.toString());
  if (params.descricao) queryParams.append('descricao', params.descricao);

  return api.get(`/equipamento/default/search?${queryParams.toString()}`);
};

export const obterEquipamentoPorId: (
  id: string
) => Promise<AxiosResponse<IEquipamentoResponse>> = (id) => {
  return api.get(`/equipamento/${id}`);
};

export const excluirEquipamento: (
  id: string
) => Promise<AxiosResponse<string>> = (id) => {
  return api.delete(`/equipamento/${id}`);
};

export const ativarEquipamento: (
  id: string
) => Promise<AxiosResponse<string>> = (id) => {
  return api.put(`/equipamento/${id}/ativar`);
};

export const adicionarEquipamento: (
  command: ISalvarEquipamentoCommand
) => Promise<AxiosResponse<string>> = (command) => {
  return api.post('/equipamento/adicionar', command);
};

export const editarEquipamento: (
  id: string,
  command: ISalvarEquipamentoCommand
) => Promise<AxiosResponse<string>> = (id, command) => {
  return api.put(`/equipamento/${id}/salvar`, command);
};
