import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  TextField,
  Typography,
  CircularProgress,
  Button,
  Switch,
  Modal
} from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import Pagination from '@material-ui/lab/Pagination';
import { obterEquipamentosModelo, ativarEquipamento } from 'src/api/equipamento';

const EquipamentoModal = ({ fecharModal, atualizarLista, onAdicionar }) => {
  const [equipamentosModelo, setEquipamentosModelo] = useState([]);
  const [termoPesquisa, setTermoPesquisa] = useState('');
  const [totalRegistros, setTotalRegistros] = useState<number>(0);
  const [modoAdicionar, setModoAdicionar] = useState(false);
  const [novoEquipamento, setNovoEquipamento] = useState({ descricao: '', valor: 0, ativo: true });
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  useEffect(() => {
    carregarEquipamentosModelo({
      pageNumber: currentPage,
      pageSize,
      mostrarDesativados: false,
      descricao: termoPesquisa || ''
    });
  }, [termoPesquisa, currentPage]);

  const carregarEquipamentosModelo = async (params: any) => {
    try {
      setLoading(true);
      const response = await obterEquipamentosModelo(params);
      const pagination = JSON.parse(response.headers['pagination']);
      setTotalRegistros(pagination.totalItems);
      setEquipamentosModelo(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Erro ao carregar equipamentos modelo:', error);
      setLoading(false);
    }
  };

  const handleAdicionarEquipamento = async () => {
    try {
      if (!novoEquipamento.descricao) {
        alert('Descrição deve ser preenchida corretamente!');
        return;
      }
      await onAdicionar(novoEquipamento.descricao);
      setModoAdicionar(false);
      carregarEquipamentosModelo({
        pageNumber: currentPage,
        pageSize,
        mostrarDesativados: false,
        descricao: termoPesquisa || ''
      });
    } catch (error) {
      console.error('Erro ao adicionar equipamento:', error);
    }
  };

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleAdicionarEquipamento();
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };

  return (
    <Modal open={true} onClose={fecharModal}>
      <Box
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90%', // Ajusta melhor para telas menores
          maxWidth: '600px', // Define um limite de largura
          maxHeight: '80vh', // Define um limite de altura para permitir rolagem
          overflowY: 'auto', // Habilita a rolagem se necessário
          backgroundColor: 'white',
          padding: 16,
          borderRadius: 8,
          boxShadow: '0px 4px 6px rgba(0,0,0,0.1)'
        }}
      >
        {/* Cabeçalho */}
        <Grid
          container
          alignItems="center"
          style={{ marginBottom: 16, justifyContent: 'space-between' }}
        >
          <Typography variant="h6">Adicionar Equipamento</Typography>
          <Button
            startIcon={<AddIcon />}
            color="primary"
            variant="contained"
            onClick={() => setModoAdicionar(true)}
          >
            Novo Equipamento
          </Button>
        </Grid>

        {/* Campo de Pesquisa */}
        {!modoAdicionar && (
          <TextField
            fullWidth
            placeholder="Pesquisar equipamentos"
            value={termoPesquisa}
            onChange={(e) => setTermoPesquisa(e.target.value)}
            style={{ marginBottom: 16 }}
          />
        )}

        {/* Corpo da Modal */}
        {loading ? (
          <CircularProgress />
        ) : !modoAdicionar ? (
          <>
            <Typography variant="body2" style={{ marginBottom: 16 }}>
              {totalRegistros} {totalRegistros === 1 ? 'equipamento encontrado' : 'equipamentos encontrados'}
            </Typography>
            <div style={{ maxHeight: '40vh', overflowY: 'auto' }}>
              {equipamentosModelo.map((equipamento) => (
                <Grid container key={equipamento.id} alignItems="center" style={{ marginBottom: 8 }}>
                  <Grid item xs={10}>
                    <Typography>{equipamento.descricao}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Switch onChange={() => ativarEquipamento(equipamento.id)} />
                  </Grid>
                </Grid>
              ))}
            </div>

            {/* Paginação */}
            <Pagination
              count={Math.ceil(totalRegistros / pageSize)}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
              style={{ marginTop: 16, display: 'flex', justifyContent: 'center' }}
            />
          </>
        ) : (
          // Formulário para Adicionar Equipamento
          <form onSubmit={handleFormSubmit}>
            <TextField
              fullWidth
              label="Nome do Equipamento"
              value={novoEquipamento.descricao}
              onChange={(e) => setNovoEquipamento({ ...novoEquipamento, descricao: e.target.value })}
              style={{ marginBottom: 8 }}
              required
            />
            {/* <TextField
              fullWidth
              label="Valor"
              type="number"
              value={novoEquipamento.valor}
              onChange={(e) => setNovoEquipamento({ ...novoEquipamento, valor: parseFloat(e.target.value) })}
              style={{ marginBottom: 16 }}
              required
            /> */}
            <Grid container spacing={2}>
              <Grid item>
                <Button type="submit" color="primary" variant="contained">
                  Salvar
                </Button>
              </Grid>
              <Grid item>
                <Button onClick={() => setModoAdicionar(false)} color="secondary" variant="outlined">
                  Cancelar
                </Button>
              </Grid>
            </Grid>
          </form>
        )}

        {/* Botão Concluir */}
        <Button
          onClick={() => {
            atualizarLista({ pageNumber: 1, pageSize });
            fecharModal();
          }}
          style={{ marginTop: 16, display: 'block', marginLeft: 'auto' }}
          color="primary"
          variant="contained"
        >
          Concluir
        </Button>
      </Box>
    </Modal>

  );
};

export default EquipamentoModal;
