export const onlyNumber: (text: string) => string = text => {
  if (!text) return ''
  return text.replace(/\D/g, '')
}

export const primeiroNome: (text: string) => string = text => {
  if (!text) return ''

  var idx = text.indexOf(' ')
  if (idx === -1) return text
  var nome = text.substring(0, idx)
  return nome
}

export const obterNomeSobrenome: (text: string) => string = text => {
  if (!text) return ''

  var array = text.split(' ')
  if (array.length === 1) return text

  var nomeSobrenome = `${array[0]} ${array[1]}`
  return nomeSobrenome
}

export const obterNomeUltimoSobrenome: (text: string) => string = text => {
  if (!text) return ''

  var array = text.trim().split(' ')
  if (array.length === 1) return text

  var nomeUltimoSobrenome = `${array[0]} ${array[array.length - 1]}`
  return nomeUltimoSobrenome
}

export const obterIniciais: (text: string) => string = text => {
  if (!text) return ''

  var listaNome = obterNomeSobrenome(text).toLocaleUpperCase().split(' ')
  var iniciais = listaNome.map(n => n.slice(0, 1)).join('')

  return iniciais
}

export function primeiraLetraMaiusculo(texto) {
  if (!texto) return ''

  return `${texto.slice(0, 1).toLocaleUpperCase()}${texto.slice(
    1,
    texto.length
  )}`
}
/* eslint-disable eqeqeq */
export function verificarCNPJ(cnpj) {
  cnpj = cnpj.replace(/[^\d]+/g, '')

  if (cnpj == '') return false

  if (cnpj.length != 14) return false

  if (
    cnpj == '00000000000000' ||
    cnpj == '11111111111111' ||
    cnpj == '22222222222222' ||
    cnpj == '33333333333333' ||
    cnpj == '44444444444444' ||
    cnpj == '55555555555555' ||
    cnpj == '66666666666666' ||
    cnpj == '77777777777777' ||
    cnpj == '88888888888888' ||
    cnpj == '99999999999999'
  ) {
    return false
  }

  var tamanho = cnpj.length - 2
  var numeros = cnpj.substring(0, tamanho)
  var digitos = cnpj.substring(tamanho)
  var soma = 0
  var pos = tamanho - 7
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--
    if (pos < 2) pos = 9
  }
  var resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
  if (resultado != digitos.charAt(0)) return false
  tamanho = tamanho + 1
  numeros = cnpj.substring(0, tamanho)
  soma = 0
  pos = tamanho - 7
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--
    if (pos < 2) pos = 9
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
  if (resultado != digitos.charAt(1)) return false

  return true
}

export const removerAcento = (text: string) => {
  if (!text) return ''

  return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

export const gerarGuid = () => {
  let guid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
    /[xy]/g,
    function (c) {
      let r = (Math.random() * 16) | 0
      let v = c === 'x' ? r : (r & 0x3) | 0x8
      return v.toString(16)
    }
  )
  return guid
}
export function getUrlParameter(name) {
  name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]')
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)')
  var results = regex.exec(location.search || location.hash)
  return results === null
    ? ''
    : decodeURIComponent(results[1].replace(/\+/g, ' '))
}

export function verificarCPF(strCPF) {
  var numero = onlyNumber(strCPF)
  strCPF = numero

  var Soma
  var Resto
  Soma = 0
  if (strCPF === '00000000000') return false
  if (strCPF === '11111111111') return false
  if (strCPF === '22222222222') return false
  if (strCPF === '33333333333') return false
  if (strCPF === '44444444444') return false
  if (strCPF === '55555555555') return false
  if (strCPF === '66666666666') return false
  if (strCPF === '77777777777') return false
  if (strCPF === '88888888888') return false
  if (strCPF === '99999999999') return false

  for (let i = 1; i <= 9; i++) {
    Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i)
  }
  Resto = (Soma * 10) % 11

  if (Resto === 10 || Resto === 11) Resto = 0
  if (Resto !== parseInt(strCPF.substring(9, 10))) return false

  Soma = 0
  for (let i = 1; i <= 10; i++) {
    Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i)
  }
  Resto = (Soma * 10) % 11

  if (Resto === 10 || Resto === 11) Resto = 0
  if (Resto !== parseInt(strCPF.substring(10, 11))) return false
  return true
}

export function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text)
    return
  }

  navigator.clipboard.writeText(text).then(
    function () {
      // console.log('Async: Copying to clipboard was successful!')
    },
    function (err) {
      console.error('Async: Could not copy text: ', err)
    }
  )
}

function fallbackCopyTextToClipboard(text) {
  var textArea = document.createElement('textarea')
  textArea.value = text

  // Avoid scrolling to bottom
  textArea.style.top = '0'
  textArea.style.left = '0'
  textArea.style.position = 'fixed'

  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    var successful = document.execCommand('copy')
    var msg = successful ? 'successful' : 'unsuccessful'
    // console.log('Fallback: Copying text command was ' + msg)
  } catch (err) {
    // console.error('Fallback: Oops, unable to copy', err)
  }

  document.body.removeChild(textArea)
}
