import { useEffect, useState } from 'react'
import service from 'src/api/atividade'
import { SynPerfis } from 'src/core/consts/synPerfis'
import { gerarGuid } from 'src/core/helpers/common'
import useAuth from 'src/hooks/useAuth'
import { NotificacaoMemo } from 'src/signalR/synHub'
import PendenciaContext from './context'

const PendenciasProvider = ({ children }) => {
  const { signed, perfilAtual } = useAuth()
  const [qtdPendencias, setQtdPendencias] = useState<number>(0)
  useEffect(() => {
    if (
      signed &&
      (perfilAtual === SynPerfis.ADMIN_HOSPITAL ||
        perfilAtual === SynPerfis.OPERACIONAL_HOSPITAL)
    ) {
      var funcaoAtualizar = async () => {
        await atualizarQtdPendencias()
      }
      setTimeout(() => {
        funcaoAtualizar()
      }, 1500)

      var intervaloPendencias = setInterval(() => {
        atualizarQtdPendencias()
      }, 20 * 60 * 1000)
    }

    return () => {
      clearInterval(intervaloPendencias)
    }
  }, [signed, perfilAtual])

  async function atualizarQtdPendencias() {
    try {
      var response = await service.obterQtdPendencias()
      setQtdPendencias(response.data)
    } catch (error) {
      // showNotificationErrorAPI(error)
    }
  }

  return (
    <PendenciaContext.Provider
      value={{
        qtdPendencias,
        atualizarQtdPendencias
      }}
    >
      {children}
    </PendenciaContext.Provider>
  )
}

export default PendenciasProvider
