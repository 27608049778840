import { AxiosResponse } from 'axios'
import { IUsuarioAtividade } from 'src/contexts/Atividade/context'
import SynTipoParceiro from 'src/core/consts/synTipoParceiro'
import api from 'src/services/api'

import {
  AlterarStatusPropostaCommand,
  AlterarStatusSolicitacaoOcamentoCommand,
  IHonorariosResponse,
  IOrcamentoDetalhadoResponse,
  IOrcamentoParceiroDetalhadoResponse,
  IOrcamentoPreenchido,
  ItemListagemOcorrenciaResponse,
  //SalvarPacoteCommand,
  ItemListagemSolicitacaoOrcamentoResponse,
  ListagemQuery,
  ListagemResponse,
  LogStatusSolicitacaoOrcamentoResponse,
  ObjetoDescricaoGenerico,
  OperacionalResponse,
  ResponderOcorenciaCommand,
  SalvarHonorarioCommand,
  SetarTagCommand,
  SolicitarOcamentoCommand,
  SolicitarOcamentoHospitalCommand,
  SolicitarOcamentoInteligenteCommand,
  TagResponse
} from 'src/types'

export const listagemOrcamento: (
  query: ListagemQuery
) => Promise<
  AxiosResponse<ListagemResponse<ItemListagemSolicitacaoOrcamentoResponse>>
> = query => {
  try {
    return api.get(
      `/solicitacaoOrcamento/listagem?${new URLSearchParams(query)}`
    )
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const listagemOrcamentoId: (
  codigo: string,
  perfilAtual: string
) => Promise<AxiosResponse<ItemListagemSolicitacaoOrcamentoResponse>> = (
  codigo,
  perfilAtual
) => {
  try {
    return api.get(
      `/solicitacaoOrcamento/${codigo}/listagemPorId/${perfilAtual}`
    )
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const listagemOrcamentoParceiro: (
  query: ListagemQuery,
  mostrarInteligente: boolean,
  mostrarCotacao: boolean
) => Promise<
  AxiosResponse<ListagemResponse<ItemListagemSolicitacaoOrcamentoResponse>>
> = (query, mostrarInteligente, mostrarCotacao) => {
  try {
    return api.get(
      `/solicitacaoOrcamento/parceiro?${new URLSearchParams(
        query
      )}&mostrarCotacao=${mostrarCotacao}&mostrarInteligente=${mostrarInteligente}`
    )
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const obterOrcamentoPorId: (
  id: string
) => Promise<AxiosResponse<IOrcamentoDetalhadoResponse>> = id => {
  try {
    return api.get(`/solicitacaoOrcamento/${id}`)
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const obterParceirosOPMEPorSolicitacao: (
  id: string
) => Promise<AxiosResponse<ObjetoDescricaoGenerico[]>> = id => {
  try {
    var response = api.get('/solicitacaoOrcamento/opme/' + id)
    return response
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const obterLoacaisAtendimentoAsync: (
  id: string
) => Promise<AxiosResponse<ObjetoDescricaoGenerico[]>> = id => {
  try {
    var response = api.get('/solicitacaoOrcamento/hospitais/' + id)
    return response
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const obterOrcamentoParceiroPorId: (
  id: string
) => Promise<AxiosResponse<IOrcamentoParceiroDetalhadoResponse>> = id => {
  try {
    return api.get(`/solicitacaoOrcamento/${id}/parceiro`)
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const salvarSolicitacaoOrcamento: (
  command: SolicitarOcamentoCommand
) => Promise<AxiosResponse<string>> = command => {
  try {
    let body = { ...command }
    body.paciente.id = !body.paciente.id ? null : body.paciente.id
    return api.post('/solicitacaoOrcamento', command)
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const salvarSolicitacaoOrcamentoInteligente: (
  command: SolicitarOcamentoInteligenteCommand
) => Promise<AxiosResponse<string>> = command => {
  let body = { ...command }
  body.paciente.id = !body.paciente.id ? null : body.paciente.id
  return api.post('/solicitacaoOrcamento/inteligente', command)
}

export interface ValoresInteligentesResponse {
  descricao?: string
  internacaoId?: string
  apartamento?: boolean
  propostaDiasUteis?: boolean
  propostaFinalDeSemana?: boolean
  propostaCentroCirurgicoAmbulatorial?: boolean
  cartaoDesconto?: boolean
  valor?: number
  valorProposta?: number
  valorPropostaEnfermaria?: number
  valorPropostaApartamento?: number
}

export const obterValoresOrcamentoInteligente: (
  command: SolicitarOcamentoInteligenteCommand
) => Promise<AxiosResponse<ValoresInteligentesResponse[]>> = command => {
  let body = { ...command }
  body.paciente.id = !body.paciente.id ? null : body.paciente.id
  return api.post('/solicitacaoOrcamento/inteligente/valores', command)
}

export const excluirOrcamento: (id: string) => Promise<boolean> = async id => {
  try {
    await api.delete(`/solicitacaoOrcamento/${id}`)
    return true
  } catch (err) {
    console.log('@ERROR: ', err)
    return false
  }
}

export const alterarStatusSolicitacaoOrcamento: (
  command: AlterarStatusSolicitacaoOcamentoCommand
) => Promise<AxiosResponse<void>> = command => {
  try {
    return api.post('/solicitacaoOrcamento/alterarStatus', command)
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const cadastrarResponsavelSolicitacaoOrcamento: (
  idUsuario: string,
  idSolicitacao: string
) => Promise<AxiosResponse<void>> = async (idUsuario, idSolicitacao) => {
  try {
    return api.post(
      `/solicitacaoOrcamento/${idUsuario}/responsavel/${idSolicitacao}`
    )
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const alterarStatusProposta: (
  command: AlterarStatusPropostaCommand
) => Promise<AxiosResponse<void>> = command => {
  try {
    return api.post('/proposta/alterarStatus', command)
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const alterarStatusPropostaEncerrada: (
  command: AlterarStatusPropostaCommand
) => Promise<AxiosResponse<void>> = command => {
  try {
    return api.post('/proposta/voltarUltimoStatus', command)
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const responderOcorenciaSolicitacaoOrcamento: (
  command: ResponderOcorenciaCommand
) => Promise<AxiosResponse<void>> = command => {
  try {
    return api.post('/solicitacaoOrcamento/responderOcorencia', command)
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const obterStatusSolicitacaoOrcamento: () => Promise<
  AxiosResponse<ObjetoDescricaoGenerico[]>
> = () => {
  try {
    return api.get('/solicitacaoOrcamento/Status')
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const obterMotivosSolicitacao: () => Promise<
  AxiosResponse<ObjetoDescricaoGenerico[]>
> = () => {
  try {
    return api.get('/solicitacaoOrcamento/motivoCancelamento')
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const obterLogStatusSolicitacaoOrcamento: (
  id: string,
  perfilAtual: string
) => Promise<AxiosResponse<LogStatusSolicitacaoOrcamentoResponse[]>> = (
  id,
  perfilAtual
) => {
  try {
    return api.get(`/solicitacaoOrcamento/LogStatus/${id}/${perfilAtual}`)
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const obterOrcamentosParaSolicitacao: (
  id: string
) => Promise<AxiosResponse<IOrcamentoPreenchido>> = id => {
  try {
    return api.get(`/solicitacaoOrcamento/${id}/orcamentos`)
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

/* GERENCIAR TAGS DA SOLICITAÇÃO */

export const listarTodasTags: () => Promise<
  AxiosResponse<TagResponse[]>
> = () => {
  try {
    return api.post('/solicitacaoOrcamento/tag/listagem')
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const listarTagsPorSolicitacao: (
  solicitacaoId?: string
) => Promise<AxiosResponse<TagResponse[]>> = solicitacaoId => {
  try {
    return api.post(`/solicitacaoOrcamento/tag/listagem/${solicitacaoId}`)
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const cadastrarTag: (
  descricao: string
) => Promise<AxiosResponse> = descricao => {
  try {
    const body = {
      descricao
    }
    return api.post('/tag', body)
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const incluirTag: (
  command: SetarTagCommand
) => Promise<AxiosResponse> = command => {
  try {
    return api.post('/solicitacaoOrcamento/tag/incluir', command)
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const removerTag: (
  command: SetarTagCommand
) => Promise<AxiosResponse> = command => {
  try {
    return api.post('/solicitacaoOrcamento/tag/remover', command)
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const deleteTag: (tagId: string) => Promise<AxiosResponse> = tagId => {
  try {
    const body = {
      tagId
    }
    return api.post('/tag/excluir', body)
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const listagemOrcamentoPaciente: (
  query: ListagemQuery
) => Promise<
  AxiosResponse<ListagemResponse<ItemListagemSolicitacaoOrcamentoResponse>>
> = query => {
  return api.get(
    `/solicitacaoOrcamento/listagem/paciente?${new URLSearchParams(query)}`
  )
}

export const salvarSolicitacaoOrcamentoHospital: (
  command: SolicitarOcamentoHospitalCommand
) => Promise<AxiosResponse<string>> = command => {
  try {
    let body = { ...command }
    body.paciente.id = !body.paciente.id ? null : body.paciente.id
    return api.post('/solicitacaoOrcamento/parceiro', command)
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export function listagemOrcamentoHospital(
  query: ListagemQuery,
  ehPendenteRevisaoValores: boolean
): Promise<
  AxiosResponse<ListagemResponse<ItemListagemSolicitacaoOrcamentoResponse>>
> {
  return api.get(
    `/solicitacaoOrcamento/parceiro/hospital?${new URLSearchParams(
      query
    )}&ehPendenteRevisaoValores=${ehPendenteRevisaoValores}`
  )
}

export function listagemOcorrencias(
  solicitacaoId: string
): Promise<AxiosResponse<ListagemResponse<ItemListagemOcorrenciaResponse>>> {
  return api.get(`/solicitacaoOrcamento/ocorrencias/${solicitacaoId}`)
}

export function listagemOcorrenciasParceiro(
  solicitacaoId: string,
  tipoParceiro: string
): Promise<AxiosResponse<ListagemResponse<ItemListagemOcorrenciaResponse>>> {
  return api.get(
    `/solicitacaoOrcamento/ocorrencias-parceiro/${solicitacaoId}/${tipoParceiro}`
  )
}

export const obterHonorariosPorId: (
  id: string
) => Promise<AxiosResponse<IHonorariosResponse>> = id => {
  try {
    return api.get(`/solicitacaoOrcamento/${id}/honorario`)
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const salvarHonorarios: (
  command: SalvarHonorarioCommand
) => Promise<AxiosResponse> = command => {
  try {
    return api.post('/solicitacaoOrcamento/salvarHonorario', command)
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

//alterar solicitaçao para Procedimento Realizado
export const alterarStatusProcedimentoRealizado: (
  idSolicitacao: string,
  perfilAtual: string
) => Promise<AxiosResponse> = async (idSolicitacao, perfilAtual) => {
  try {
    return await api.put(
      `/solicitacaoOrcamento/${idSolicitacao}/${perfilAtual}/AlterarStatusParaProcedimentoRealizado`
    )
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export function listarOperacionais(): Promise<
  AxiosResponse<OperacionalResponse[]>
> {
  try {
    return api.get('/solicitacaoOrcamento/buscarOperacionais')
  } catch (err) {
    console.log('@Error: ', err)
  }
}
export function listarUsuarios(): Promise<AxiosResponse<IUsuarioAtividade[]>> {
  try {
    return api.get('/solicitacaoOrcamento/buscarUsuarios')
  } catch (err) {
    console.log('@Error: ', err)
  }
}

export const habilitarPagamento: (
  solicitacaoId: string,
  habilitado: boolean
) => Promise<AxiosResponse<string>> = (solicitacaoId, habilitado) => {
  try {
    return api.post('/solicitacaoOrcamento/habilitarPagamento', {
      habilitado: habilitado,
      id: solicitacaoId
    })
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export interface ComprovanteCommand {
  tipo: number
  arquivo: any
  destinatarioId: string
  observacao: string
  solicitacaoId: string
}
export const anexarComprovante: (
  command: ComprovanteCommand
) => Promise<AxiosResponse<any>> = command => {
  try {
    const fromData = new FormData()
    fromData.append('Arquivo', command.arquivo)
    fromData.append('Tipo', command.tipo.toString())
    fromData.append('Observacao', command.observacao)
    fromData.append('DestinatarioId', command.destinatarioId)

    return api.post(
      `/solicitacaoOrcamento/${command.solicitacaoId}/comprovantes`,
      fromData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export interface ComprovanteResponse {
  tipo: number
  observacao: string
  enviadoPor: string
  enviado: string
  url: string
  extensao: string
  contentType: string
  destinatarioId: string
}

export const obterComprovantesOperacional: (
  id: string
) => Promise<AxiosResponse<ComprovanteResponse[]>> = id => {
  return api.get(`/solicitacaoOrcamento/${id}/comprovantes`)
}

export const obterComprovantesHospital: (
  id: string
) => Promise<AxiosResponse<ComprovanteResponse[]>> = id => {
  return api.get(`/solicitacaoOrcamento/${id}/comprovantes/hospital`)
}

export const obterComprovantesPorDestinatario: (
  solicitacaoId: string,
  tipo: number,
  destinatarioId: string,
  perfilAtual: string
) => Promise<AxiosResponse<ComprovanteResponse[]>> = (
  solicitacaoId,
  tipo,
  destinatarioId,
  perfilAtual
) => {
  return api.get(
    `/solicitacaoOrcamento/${solicitacaoId}/comprovantes/${tipo}/${destinatarioId}/${perfilAtual}`
  )
  // return api.post('/solicitacaoOrcamento/comprovantes/', {
  //   habilitado: habilitado,
  //   id: solicitacaoId
  // })
}

export const obterPendencias: () => Promise<AxiosResponse<any>> = () => {
  return api.get('/solicitacaoOrcamento/pendencias')
}

export const liberarEdicao: (
  idSolicitacao: string,
  perfilAtual: string
) => Promise<AxiosResponse> = (idSolicitacao, perfilAtual) => {
  return api.put(
    `/solicitacaoOrcamento/liberarEdicao/${idSolicitacao}/${perfilAtual}`
  )
}
