import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Modal,
  Paper,
  Grid
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EquipamentoListItem from './EquipamentoListItem';
import { obterEquipamentosTenant, editarEquipamento, adicionarEquipamento, excluirEquipamento } from 'src/api/equipamento';
import EquipamentoModal from './EquipamentoModal';
import Swal from 'sweetalert2';

const EquipamentoList = () => {
  const [equipamentos, setEquipamentos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalRegistros, setTotalRegistros] = useState<number>(0);
  const [modalAberta, setModalAberta] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  const [termoPesquisa, setTermoPesquisa] = useState('');
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    carregarEquipamentos({
      pageNumber: 1,
      pageSize,
      mostrarDesativados: false,
      descricao: termoPesquisa || ''
    });
  }, [termoPesquisa]);

  const carregarEquipamentos = async (params: any) => {
    try {
      setLoading(true);
      const response = await obterEquipamentosTenant(params);
      const pagination = JSON.parse(response.headers['pagination']);
      setTotalRegistros(pagination.totalItems);
      setTotalPages(pagination.totalPages);
      setEquipamentos(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Erro ao carregar equipamentos:', error);
      setLoading(false);
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    carregarEquipamentos({
      pageNumber: page,
      pageSize,
      mostrarDesativados: false,
      descricao: termoPesquisa || ''
    });
  };

  const handleEditar = async (id: string, descricao: string) => {
    try {
      await editarEquipamento(id, { descricao, ativo: true });
      Swal.fire('Atualizado!', 'O equipamento foi atualizado com sucesso.', 'success');

      carregarEquipamentos({
        pageNumber: currentPage,
        pageSize,
        mostrarDesativados: false,
        descricao: termoPesquisa || ''
      });
    } catch (error) {
      console.error('Erro ao editar equipamento:', error);
      Swal.fire('Erro!', 'Ocorreu um erro ao atualizar o equipamento.', 'error');
    }
  };

  const handleAdicionar = async (descricao: string) => {
    try {
      if (!descricao) {
        Swal.fire('Erro!', 'A descrição deve ser preenchida corretamente.', 'error');
        return;
      }
      const novoEquipamento = {
        descricao: descricao.trim(),
        ativo: true
      };

      await adicionarEquipamento(novoEquipamento);
      Swal.fire('Sucesso!', 'O equipamento foi adicionado com sucesso.', 'success');

      // Recarregar a lista de equipamentos
      carregarEquipamentos({
        pageNumber: 1,
        pageSize,
        mostrarDesativados: false,
        descricao: termoPesquisa || ''
      });

      // Fechar a modal
      setModalAberta(false);
    } catch (error) {
      console.error('Erro ao adicionar equipamento:', error);
      Swal.fire('Erro!', 'Ocorreu um erro ao adicionar o equipamento.', 'error');
    }
  };

  const handleExcluir = async (id: string) => {
    try {
      const confirmacao = await Swal.fire({
        title: 'Tem certeza?',
        text: 'Esta ação desativará o equipamento!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, excluir',
        cancelButtonText: 'Cancelar'
      });

      if (confirmacao.isConfirmed) {
        await excluirEquipamento(id);
        Swal.fire('Excluído!', 'O equipamento foi excluído com sucesso.', 'success');

        carregarEquipamentos({
          pageNumber: currentPage,
          pageSize,
          mostrarDesativados: false,
          descricao: termoPesquisa || ''
        });
      }
    } catch (error) {
      console.error('Erro ao excluir equipamento:', error);
      Swal.fire('Erro!', 'Ocorreu um erro ao excluir o equipamento.', 'error');
    }
  };

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Typography variant="h5">Equipamentos</Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setModalAberta(true)}
        >
          Novo Equipamento
        </Button>
      </Box>

      <TextField
        fullWidth
        placeholder="Pesquisar equipamentos"
        value={termoPesquisa}
        onChange={(e) => setTermoPesquisa(e.target.value)}
        style={{ marginBottom: 16 }}
      />

      <Typography variant="h6" style={{ marginBottom: '16px' }}>
        {totalRegistros} {totalRegistros === 1 ? 'equipamento encontrado' : 'equipamentos encontrados'}
      </Typography>

      {loading ? (
        <CircularProgress />
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Descrição</TableCell>
                <TableCell align="right">Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {equipamentos.map((equipamento) => (
                <EquipamentoListItem
                  key={equipamento.id}
                  equipamento={equipamento}
                  onEditar={(id, novaDescricao) => handleEditar(id, novaDescricao)}
                  onExcluir={handleExcluir}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Grid container alignItems="center" style={{ marginTop: 16, justifyContent: 'space-between' }}>
        <Typography>
          Página {currentPage} de {totalPages}
        </Typography>
        <Box>
          <Button
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
            style={{ marginRight: 8 }}
          >
            Anterior
          </Button>
          <Button
            disabled={currentPage === totalPages}
            onClick={() => handlePageChange(currentPage + 1)}
          >
            Próximo
          </Button>
        </Box>
      </Grid>

      <Modal open={modalAberta} onClose={() => setModalAberta(false)}>
        <EquipamentoModal fecharModal={() => setModalAberta(false)} atualizarLista={carregarEquipamentos} onAdicionar={handleAdicionar} />
      </Modal>
    </Box>
  );
};

export default EquipamentoList;
