import React, { useState } from 'react';
import {
  TableRow,
  TableCell,
  IconButton,
  TextField,
  Menu,
  MenuItem
} from '@material-ui/core';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';
import { FaCheck, FaTimes } from 'react-icons/fa';

interface Equipamento {
  id: string;
  descricao: string;
  ativo: boolean;
}

interface Props {
  equipamento: Equipamento;
  onEditar: (id: string, novaDescricao: string) => void;
  onExcluir: (id: string) => void;
}

const EquipamentoListItem: React.FC<Props> = ({ equipamento, onEditar, onExcluir }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [editando, setEditando] = useState(false);
  const [novaDescricao, setNovaDescricao] = useState(equipamento.descricao);

  const handleSalvarClick = () => {
    if (!novaDescricao.trim()) {
      console.error('Descrição inválida');
      return;
    }
    onEditar(equipamento.id, novaDescricao);
    setEditando(false);
  };

  const handleCancelarClick = () => {
    setNovaDescricao(equipamento.descricao);
    setEditando(false);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSalvarClick();
    } else if (event.key === 'Escape') {
      handleCancelarClick();
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditarClick = () => {
    setEditando(true);
    handleClose();
  };

  return (
    <TableRow>
      {/* Coluna Descrição */}
      <TableCell>
        {editando ? (
          <TextField
            fullWidth
            value={novaDescricao}
            onChange={(e) => setNovaDescricao(e.target.value)}
            onKeyDown={handleKeyDown} // Captura Enter e Esc
          />
        ) : (
          equipamento.descricao
        )}
      </TableCell>

      {/* Coluna Ações */}
      <TableCell align="right">
        {editando ? (
          <>
            <IconButton onClick={handleSalvarClick} color="primary">
              <FaCheck size={16} color="green" />
            </IconButton>
            <IconButton onClick={handleCancelarClick} color="secondary">
              <FaTimes size={16} color="red" />
            </IconButton>
          </>
        ) : (
          <>
            <IconButton onClick={handleClick}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleEditarClick}>Editar</MenuItem>
              <MenuItem onClick={() => { handleClose(); onExcluir(equipamento.id); }}>Excluir</MenuItem>
            </Menu>
          </>
        )}
      </TableCell>
    </TableRow>
  );
};

export default EquipamentoListItem;
