import { Box, Tab, Tabs, Typography } from '@material-ui/core'
import { useEffect, useState } from 'react'
import Ubox from 'src/components/ui/Ubox'
import useAuth from 'src/hooks/useAuth'
import ListagemPacotes from './listagemPacotes'
import ListagemPacotesInteligentes from './listagemPacotesInteligentes'
import { SynPerfis } from 'src/core/consts/synPerfis'
import { useQueryParams } from 'src/hooks/useQuery'
import { SynRoutes } from 'src/core/consts/synRoutes'
import { useHistory } from 'react-router-dom'

function listagem() {
  const { perfilAtual } = useAuth()
  const query = useQueryParams()
  const history = useHistory()

  const [tabValue, setTabValue] = useState<0 | 1>(0)
  let perfilHospital = [
    SynPerfis.ADMIN_HOSPITAL,
    SynPerfis.OPERACIONAL_HOSPITAL
  ].includes(perfilAtual)

  useEffect(() => {
    const tipo = query.get('tipo') || ''
    if (tipo?.toLowerCase() === 'inteligente' || perfilHospital) {
      setTabValue(1)
    }
  }, [perfilHospital])

  if (perfilAtual === SynPerfis.PARCEIRO_EXTERNO) {
    return (
      <Ubox>
        <Box mb={2}>
          <Typography variant="h5">Pacotes de procedimentos</Typography>
        </Box>

        <ListagemPacotes />
      </Ubox>
    )
  }

  if (perfilHospital) {
    return (
      <Ubox>
        <Box mb={2}>
          <Typography variant="h5">Pacotes instantâneos</Typography>
        </Box>

        <ListagemPacotesInteligentes />
      </Ubox>
    )
  }

  return (
    <Box>
      <Box mb={2}>
        <Typography variant="h5">Pacotes de procedimentos</Typography>
      </Box>
      <Tabs
        value={tabValue}
        onChange={(e, value) => {
          if (value === 0) {
            history.replace(
              `${SynRoutes.listagemPacotesOperacional}?tipo=cotacao`
            )
          } else {
            history.replace(
              `${SynRoutes.listagemPacotesOperacional}?tipo=inteligente`
            )
          }
          setTabValue(value)
        }}
        textColor="primary"
        indicatorColor="primary"
        style={{ marginBottom: '1em' }}
      >
        <Tab label="Pacotes cotações" value={0} />
        <Tab label="Pacotes instantâneos" value={1} />
      </Tabs>

      <Ubox hidden={!(tabValue === 0)}>
        <ListagemPacotes />
      </Ubox>

      <Ubox hidden={!(tabValue === 1)}>
        <ListagemPacotesInteligentes />
      </Ubox>
    </Box>
  )
}

export default listagem
