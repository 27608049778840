import { Box, useMediaQuery, useTheme } from '@material-ui/core'
import { IPacoteInteligenteForm } from 'src/components/pacoteInteligente/FormPacoteInteligente'
import Drawer from 'src/components/ui/Drawer'
import { Steps } from '../pacote/pacoteInteligente/formConfiguracao'
import { FormConfiguracaoV2 } from '../pacote/pacoteInteligente/formConfiguracaoV2'
import { stepsFormulario } from './formUnificadoV2/formUnificadoV2'

type Props = {
  Form?: any
  etapa: Steps
  pacote: IPacoteInteligenteForm
  open: boolean
  handleOnClose: () => void
  handleSalvarAjuste?: (dados: IPacoteInteligenteForm) => void
}

function DrawerAjustarPacoteInteligente({ Form, ...props }: Props) {
  const theme = useTheme()
  const ehXS = useMediaQuery(theme.breakpoints.down('xs'))

  function obterForm() {
    return (
      <FormConfiguracaoV2
        // pacote={props.pacote}
        etapaAjuste={props.etapa}
        handleSalvarAjuste={props.handleSalvarAjuste}
        isback={() => {}}
        pacote={props.pacote}
        setPacote={pacote => {
          // setPacoteInstantaneoSelecionado(pacote)
        }}
        activeStep={props.etapa}
        setActiveStep={step => {
          // handleChangeStep(step)
        }}
        stepsFormulario={JSON.parse(JSON.stringify(stepsFormulario))}
      />
    )
  }

  return (
    <Drawer
      anchor="right"
      open={props.open}
      titulo="Ajustar pacote para o paciente"
      handleOnClose={props.handleOnClose}
      BackdropProps={{ invisible: false }}
      disableBackdropClick
      noPadding={ehXS}
      // elevation={2}
      disableEscapeKeyDown={true}
    >
      {/* {JSON.stringify(props)} */}
      <Box mt={3}>{obterForm()}</Box>
    </Drawer>
  )
}

export default DrawerAjustarPacoteInteligente
