import React, { useEffect, useState } from 'react'

import {
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  makeStyles,
  withStyles
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { Alert } from '@material-ui/lab'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import { AiTwotoneThunderbolt } from 'react-icons/ai'
import { BiNetworkChart } from 'react-icons/bi'
import { BsBell, BsBellFill } from 'react-icons/bs'
import { FaChild, FaMoneyCheckAlt, FaTasks } from 'react-icons/fa'
import { RiEyeLine } from 'react-icons/ri'
import { buscarPermissoesPorId } from 'src/api/medico'
import ChipStatusOrcamento from 'src/components/solicitacao/ChipStatusOrcamento'
import { Button as ButtonSrc } from 'src/components/ui/Button'
import SetoresLocalAtendimento from 'src/core/consts/setorLocalAtendimento'
import StatusProposta from 'src/core/consts/statusProposta'
import {
  esconderFooterParceiro,
  statusOrcamento
} from 'src/core/consts/statusSolicitacao'
import { SynPerfis } from 'src/core/consts/synPerfis'
import { SynRoutes } from 'src/core/consts/synRoutes'
import SynTipoParceiro from 'src/core/consts/synTipoParceiro'
import formatarDinheiro from 'src/core/formatacoes/formatarDinheiro'
import { primeiroNome } from 'src/core/helpers/common'
import useAtividade from 'src/hooks/useAtividade'
import useAuth from 'src/hooks/useAuth'
import Swal from 'sweetalert2'
import { formatarDataHoraLocal } from '../../core/formatacoes/formatarData'
import {
  ItemListagemAssistenteMedicoResponse,
  ItemListagemSolicitacaoOrcamentoResponse,
  OperacionalResponse,
  SetarTagCommand
} from '../../types'
import DrawerDadosPaciente from '../paciente/Drawer'
import UBox from '../ui/Ubox'
import ButtonAlterarStatusOrcamento from './ButtonStatusOrcamento'

/* O arquivo ta dividido em:
  Menu do operacional;
  Menu do comercial
  Menu do médico;

  Title do Card

  Footer do Card

  Types;

  Componente Card
*/

const parceiros = [
  SynPerfis.PARCEIRO,
  SynPerfis.ADMIN_HOSPITAL,
  SynPerfis.OPERACIONAL_HOSPITAL,
  SynPerfis.COTACAO_HOSPITAL,
  SynPerfis.TESOURARIA_HOSPITAL
]

function mostrarLiberarEdicao(solicitacao, perfilAtual) {
  return (
    [
      statusOrcamento.AGUARDANDO_APROVACAO_MEDICO.Id,
      statusOrcamento.AGUARDANDO_RESPOSTA_PACIENTE.Id,
      statusOrcamento.EM_ANDAMENTO.Id,
      statusOrcamento.AGUARDANDO_CONFIRMACAO_PAGAMENTO.Id
    ].includes(solicitacao.status?.id) &&
    !solicitacao.pacoteInteligenteId &&
    (perfilAtual === SynPerfis.OPERACIONAL ||
      perfilAtual === SynPerfis.ADMIN_HOSPITAL ||
      perfilAtual === SynPerfis.OPERACIONAL_HOSPITAL)
  )
}

// #region Menu do Operacional
interface MenuOperacionalProps {
  handleAlterarStatus?: (item: ItemListagemSolicitacaoOrcamentoResponse) => void
  handleAtividades?: (item: ItemListagemSolicitacaoOrcamentoResponse) => void
  handleAlterarHonorarios?: (
    item: ItemListagemSolicitacaoOrcamentoResponse
  ) => void
  handleInstrucoesNF: (item: ItemListagemSolicitacaoOrcamentoResponse) => void
  handleExcluir?: (itemId: string) => void
  handleLogStatus?: (item: ItemListagemSolicitacaoOrcamentoResponse) => void
  handleCadastrarCpf?: (item: ItemListagemSolicitacaoOrcamentoResponse) => void
  handleAnexo?: (item: ItemListagemSolicitacaoOrcamentoResponse) => void
  setUltimaSolicitacao: (item: ItemListagemSolicitacaoOrcamentoResponse) => void
  solicitacao: ItemListagemSolicitacaoOrcamentoResponse
  navigate: (route: string) => void
  handleOperacional?: OperacionalResponse[]
  handleAbrirModalDono?: (
    item: ItemListagemSolicitacaoOrcamentoResponse
  ) => void
  handleEditar: (item: ItemListagemSolicitacaoOrcamentoResponse) => void
  handleHistoricoEditar: (
    item: ItemListagemSolicitacaoOrcamentoResponse
  ) => void
  handleLiberarEdicao: (item: ItemListagemSolicitacaoOrcamentoResponse) => void
}
function MenuOperacional({
  handleCadastrarCpf,
  handleLogStatus,
  handleExcluir,
  setUltimaSolicitacao,
  solicitacao,
  navigate,
  handleAlterarStatus,
  handleInstrucoesNF,
  handleAtividades,
  handleAlterarHonorarios,
  handleOperacional,
  handleAnexo,
  handleAbrirModalDono,
  handleEditar,
  handleHistoricoEditar,
  handleLiberarEdicao
}: MenuOperacionalProps) {
  const { obterQtdePendente } = useAtividade()
  const s = useStyles()
  const { signOut, user, perfilAtual } = useAuth()

  const [pacienteId, setPacienteId] = useState('')
  const [pacienteTemCpf, setPacienteTemCpf] = useState(false)
  const [mostrarDadosPaciente, setMostrarDadosPaciente] = useState(false)
  function handleOpenDadosPaciente(
    item: ItemListagemSolicitacaoOrcamentoResponse
  ) {
    setPacienteId(item.pacienteId)
    setPacienteTemCpf(item.pacienteTemCpf)
    setMostrarDadosPaciente(true)
  }
  async function handleCloseDadosPaciente(atualizou, id?) {
    setMostrarDadosPaciente(false)
    try {
      if (!!atualizou) {
        location.reload()
      }
    } catch (error) {}
  }

  return (
    <>
      {/* dados: {JSON.stringify(solicitacao)} */}
      <PopupState variant="popover" popupId="demo-popup-menu">
        {popupState => (
          <React.Fragment>
            {perfilAtual !== SynPerfis.PARCEIRO_EXTERNO &&
              perfilAtual !== SynPerfis.ADMIN_HOSPITAL &&
              perfilAtual !== SynPerfis.OPERACIONAL_HOSPITAL && (
                <Tooltip
                  title={
                    obterQtdePendente(solicitacao.id) > 0
                      ? `${obterQtdePendente(solicitacao.id)} tarefas pendentes`
                      : 'Nenhuma tarefa pendente'
                  }
                >
                  <IconButton
                    style={{ fontSize: '22px' }}
                    onClick={() => handleAtividades(solicitacao)}
                  >
                    {obterQtdePendente(solicitacao.id) > 0 ? (
                      <StyledBadge
                        className={s.badgeCustom}
                        color="secondary"
                        badgeContent={obterQtdePendente(solicitacao.id)}
                      >
                        <FaTasks style={{ color: '#3bd1b6' }} />
                      </StyledBadge>
                    ) : (
                      <FaTasks />
                    )}
                  </IconButton>
                </Tooltip>
              )}

            {perfilAtual !== SynPerfis.PARCEIRO_EXTERNO && (
              <Tooltip
                title={
                  solicitacao.qtdOcorrenciasOperacional > 0
                    ? `${solicitacao.qtdOcorrenciasOperacional} ocorrências`
                    : 'Nenhuma ocorrência'
                }
              >
                <IconButton
                  style={{ fontSize: '22px' }}
                  onClick={() => handleLogStatus(solicitacao)}
                >
                  {solicitacao.qtdOcorrenciasOperacional > 0 ? (
                    <StyledBadge
                      color="secondary"
                      badgeContent={solicitacao.qtdOcorrenciasOperacional}
                    >
                      <BsBellFill style={{ color: '#3bd1b6' }} />
                    </StyledBadge>
                  ) : (
                    <BsBell />
                  )}
                </IconButton>
              </Tooltip>
            )}

            {perfilAtual !== SynPerfis.PARCEIRO_EXTERNO && (
              <>
                <IconButton
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  aria-label="mais"
                  {...bindTrigger(popupState)}
                >
                  <MoreVertIcon />
                </IconButton>

                <Menu
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  {...bindMenu(popupState)}
                >
                  {perfilAtual !== SynPerfis.FINANCEIRO && (
                    <>
                      {!solicitacao.pacienteTemCpf && (
                        <MenuItem
                          onClick={() => {
                            popupState.close()
                            handleCadastrarCpf(solicitacao)
                          }}
                        >
                          Cadastrar CPF
                        </MenuItem>
                      )}

                      {solicitacao.status.id ===
                        statusOrcamento.SOLICITACAO_RECEBIDA.Id &&
                        perfilAtual === SynPerfis.OPERACIONAL && (
                          <>
                            <MenuItem
                              onClick={() => {
                                popupState.close()
                                handleExcluir(solicitacao.id)
                              }}
                            >
                              Excluir
                            </MenuItem>
                          </>
                        )}

                      {solicitacao.status.id ===
                        statusOrcamento.SOLICITACAO_RECEBIDA.Id &&
                        (perfilAtual === SynPerfis.ADMIN_HOSPITAL ||
                          perfilAtual === SynPerfis.OPERACIONAL_HOSPITAL ||
                          perfilAtual === SynPerfis.OPERACIONAL) &&
                        !solicitacao.pacoteInteligenteId && (
                          <>
                            <MenuItem
                              onClick={() => {
                                popupState.close()
                                handleEditar(solicitacao)
                              }}
                            >
                              Editar
                            </MenuItem>
                          </>
                        )}
                      {(perfilAtual === SynPerfis.ADMIN_HOSPITAL ||
                        perfilAtual === SynPerfis.OPERACIONAL_HOSPITAL ||
                        perfilAtual === SynPerfis.OPERACIONAL) &&
                        !solicitacao.pacoteInteligenteId && (
                          <>
                            <MenuItem
                              onClick={() => {
                                popupState.close()
                                handleHistoricoEditar(solicitacao)
                              }}
                            >
                              Histórico de edição
                            </MenuItem>
                          </>
                        )}
                      {!!mostrarLiberarEdicao(solicitacao, perfilAtual) && (
                        <>
                          <MenuItem
                            onClick={() => {
                              popupState.close()
                              handleLiberarEdicao(solicitacao)
                            }}
                          >
                            Liberar para edição
                          </MenuItem>
                        </>
                      )}
                      {!solicitacao?.pacoteInteligenteId && (
                        <MenuItem
                          onClick={() => {
                            popupState.close()
                            navigate(
                              `${SynRoutes.dadosSolicitacaoOperacionalOPME}?id=${solicitacao.id}`
                            )
                            setUltimaSolicitacao(solicitacao)
                          }}
                        >
                          Preparar propostas
                        </MenuItem>
                      )}
                      <MenuItem
                        onClick={() => {
                          popupState.close()
                          handleAlterarStatus(solicitacao)
                        }}
                      >
                        Alterar status
                      </MenuItem>
                      {perfilAtual !== SynPerfis.ADMIN_HOSPITAL &&
                        perfilAtual !== SynPerfis.OPERACIONAL_HOSPITAL && (
                          <MenuItem
                            onClick={() => {
                              popupState.close()
                              handleAtividades(solicitacao)
                            }}
                          >
                            Atividades
                          </MenuItem>
                        )}

                      {!solicitacao?.pacoteInteligenteId && (
                        <MenuItem
                          onClick={() => {
                            popupState.close()
                            handleAlterarHonorarios(solicitacao)
                          }}
                        >
                          Alterar honorários
                        </MenuItem>
                      )}

                      <MenuItem
                        onClick={() => {
                          popupState.close()
                          handleLogStatus(solicitacao)
                        }}
                      >
                        Histórico de status
                      </MenuItem>
                    </>
                  )}
                  <MenuItem
                    onClick={() => {
                      popupState.close()
                      handleAnexo(solicitacao)
                    }}
                  >
                    Comprovantes de pagamento
                  </MenuItem>
                  {perfilAtual !== SynPerfis.FINANCEIRO && (
                    <>
                      <MenuItem
                        onClick={() => {
                          popupState.close()
                          handleOpenDadosPaciente(solicitacao)
                        }}
                      >
                        Atualizar paciente
                      </MenuItem>

                      {!!solicitacao?.propostaVencedoraId &&
                        ![
                          SynPerfis.OPERACIONAL_HOSPITAL,
                          SynPerfis.ADMIN_HOSPITAL
                        ].includes(perfilAtual) && (
                          <MenuItem
                            onClick={() => {
                              popupState.close()
                              handleInstrucoesNF(solicitacao)
                            }}
                          >
                            Reenviar instruções NF p/ paciente
                          </MenuItem>
                        )}
                    </>
                  )}
                  {/* <MenuItem
                onClick={() => {
                  popupState.close()
                  handleExcluir(solicitacao.id)
                }}
                className="danger"
              >
                Excluir
              </MenuItem> */}
                </Menu>
              </>
            )}
          </React.Fragment>
        )}
      </PopupState>
      <DrawerDadosPaciente
        open={mostrarDadosPaciente}
        handleOnClose={handleCloseDadosPaciente}
        id={pacienteId}
        temCpf={pacienteTemCpf}
      />
    </>
  )
}

// #endregion

// #region Menu do Comercial
interface MenuComercialProps {
  handleLogStatus?: (item: ItemListagemSolicitacaoOrcamentoResponse) => void
  solicitacao: ItemListagemSolicitacaoOrcamentoResponse
  handleVisualizarDetalhes: (
    item: ItemListagemSolicitacaoOrcamentoResponse
  ) => void
}

function MenuComercial({
  handleLogStatus,
  solicitacao,
  handleVisualizarDetalhes
}: MenuComercialProps) {
  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {popupState => (
        <React.Fragment>
          <IconButton
            onClick={() => {
              popupState.close()
              handleVisualizarDetalhes(solicitacao)
            }}
          >
            <RiEyeLine />
          </IconButton>
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            aria-label="mais"
            {...bindTrigger(popupState)}
          >
            <MoreVertIcon />
          </IconButton>

          <Menu
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            {...bindMenu(popupState)}
          >
            <MenuItem
              onClick={() => {
                popupState.close()
                handleLogStatus(solicitacao)
              }}
            >
              Histórico de status
            </MenuItem>
          </Menu>
        </React.Fragment>
      )}
    </PopupState>
  )
}

// #endregion

// #region Menu do médico
interface MenuMedicoProps {
  solicitacao: ItemListagemSolicitacaoOrcamentoResponse
  handleVisualizarDetalhes: (
    item: ItemListagemSolicitacaoOrcamentoResponse
  ) => void
  handleVisualizarComprovantes: (
    item: ItemListagemSolicitacaoOrcamentoResponse
  ) => void
  handleImprimir: (item: ItemListagemSolicitacaoOrcamentoResponse) => void
  handleEditar: (item: ItemListagemSolicitacaoOrcamentoResponse) => void
  handleVisualizarOcorrencias: (itemId: string) => void
  handleAlterarStatusProcedimentoRealizado: (idSolicitacao: string) => void
  handleHistoricoEditar: (
    item: ItemListagemSolicitacaoOrcamentoResponse
  ) => void
  permissoesSecretaria: ItemListagemAssistenteMedicoResponse
  perfil: Perfil
  handleLiberarEdicao: (item: ItemListagemSolicitacaoOrcamentoResponse) => void
}

const StyledBadge = withStyles(theme => ({
  badge: {
    right: -3,
    top: 8,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px'
  }
}))(Badge)

function MenuMedico({
  solicitacao,
  perfil,
  handleVisualizarDetalhes,
  handleImprimir,
  handleEditar,
  handleVisualizarOcorrencias,
  handleVisualizarComprovantes,
  handleAlterarStatusProcedimentoRealizado,
  handleHistoricoEditar,
  permissoesSecretaria
}: MenuMedicoProps) {
  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {popupState => (
        <React.Fragment>
          <IconButton
            onClick={() => {
              popupState.close()
              handleVisualizarDetalhes(solicitacao)
            }}
          >
            <RiEyeLine />
          </IconButton>
          <Tooltip
            title={
              solicitacao.qtdOcorrencias > 0
                ? `${solicitacao.qtdOcorrencias} ocorrências`
                : 'Nenhuma ocorrência'
            }
          >
            <IconButton
              style={{ fontSize: '22px' }}
              onClick={() => handleVisualizarOcorrencias(solicitacao.id)}
            >
              {solicitacao.qtdOcorrencias > 0 ? (
                <StyledBadge
                  color="secondary"
                  badgeContent={solicitacao.qtdOcorrencias}
                >
                  <BsBellFill style={{ color: '#3bd1b6' }} />
                </StyledBadge>
              ) : (
                <BsBell />
              )}
            </IconButton>
          </Tooltip>

          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            aria-label="mais"
            {...bindTrigger(popupState)}
          >
            <MoreVertIcon />
          </IconButton>

          <Menu
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            {...bindMenu(popupState)}
          >
            <MenuItem
              onClick={() => {
                popupState.close()
                handleVisualizarDetalhes(solicitacao)
              }}
            >
              Visualizar
            </MenuItem>
            {solicitacao.status.id ===
              statusOrcamento.SOLICITACAO_RECEBIDA.Id &&
              (perfil !== 'secretaria' ||
                (perfil === 'secretaria' &&
                  !!permissoesSecretaria?.podeEditarSolicitacao)) &&
              !solicitacao.pacoteInteligenteId && (
                <MenuItem
                  onClick={() => {
                    popupState.close()
                    handleEditar(solicitacao)
                  }}
                  style={{ width: '200px' }}
                >
                  Editar
                </MenuItem>
              )}

            <MenuItem
              onClick={() => {
                popupState.close()
                handleHistoricoEditar(solicitacao)
              }}
            >
              Histórico de edição
            </MenuItem>
            <MenuItem
              onClick={() => {
                popupState.close()
                handleVisualizarOcorrencias(solicitacao.id)
              }}
            >
              Ocorrências
            </MenuItem>
            <MenuItem
              onClick={() => {
                popupState.close()
                handleVisualizarComprovantes(solicitacao)
              }}
            >
              Ver comprovantes
            </MenuItem>
            <MenuItem
              onClick={() => {
                popupState.close()
                handleImprimir(solicitacao)
              }}
              style={{ width: '200px' }}
            >
              Imprimir guia
            </MenuItem>
          </Menu>
        </React.Fragment>
      )}
    </PopupState>
  )
}

//#endregion

// #region Menu do médico
interface MenuParceiroProps {
  solicitacao: ItemListagemSolicitacaoOrcamentoResponse
  handleVisualizarOcorrencias: (itemId: string) => void
  handleVisualizarDetalhes: (
    item: ItemListagemSolicitacaoOrcamentoResponse
  ) => void
}

function MenuParceiro({
  solicitacao,
  handleVisualizarOcorrencias,
  handleVisualizarDetalhes
}: MenuParceiroProps) {
  const { user } = useAuth()
  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {popupState => (
        <React.Fragment>
          {user?.tipoParceiro === SynTipoParceiro.HOSPITAL_CLINICA.id && (
            <IconButton
              onClick={() => {
                popupState.close()
                handleVisualizarDetalhes(solicitacao)
              }}
            >
              <RiEyeLine />
            </IconButton>
          )}
          <Tooltip
            title={
              solicitacao.qtdOcorrencias > 0
                ? `${solicitacao.qtdOcorrencias} ocorrências`
                : 'Nenhuma ocorrência'
            }
          >
            <IconButton
              style={{ fontSize: '22px' }}
              onClick={() => handleVisualizarOcorrencias(solicitacao.id)}
            >
              {solicitacao.qtdOcorrencias > 0 ? (
                <StyledBadge
                  color="secondary"
                  badgeContent={solicitacao.qtdOcorrencias}
                >
                  <BsBellFill style={{ color: '#3bd1b6' }} />
                </StyledBadge>
              ) : (
                <BsBell />
              )}
            </IconButton>
          </Tooltip>
        </React.Fragment>
      )}
    </PopupState>
  )
}

//#endregion

//#region Title do Card
type RenderTitleProps = {
  perfil: Perfil
  solicitacao: ItemListagemSolicitacaoOrcamentoResponse
  handleVisualizarDetalhes: (
    item: ItemListagemSolicitacaoOrcamentoResponse
  ) => void
  handleAbrirSolicitacao: (
    item: ItemListagemSolicitacaoOrcamentoResponse
  ) => void
  handleAlterarStatus: (item: ItemListagemSolicitacaoOrcamentoResponse) => void
  handleVisualizarOcorrencias: (solicitacaoId: string) => void
  navigate?: (route: string) => void
}

function renderTitle({
  perfil,
  solicitacao,
  handleAbrirSolicitacao,
  handleVisualizarDetalhes,
  handleAlterarStatus,
  navigate
}: RenderTitleProps) {
  const statusId = solicitacao.status.id
  const { signOut, user, perfilAtual } = useAuth()
  const condicoesParaTexto = () => {
    const statusPaciente = [
      statusOrcamento['SOLICITACAO_RECEBIDA'],
      statusOrcamento['EM_ANDAMENTO'],
      statusOrcamento['EM_REVISAO'],
      statusOrcamento['AGUARDANDO_APROVACAO_MEDICO'],
      statusOrcamento['PROPOSTA_APROVADA_MEDICO'],
      statusOrcamento.PROCESSO_CANCELADO
    ]

    let statusPossiveis = [
      statusOrcamento.EM_ANDAMENTO.Id,
      statusOrcamento.AGUARDANDO_APROVACAO_MEDICO.Id,
      statusOrcamento.AGUARDANDO_RESPOSTA_PACIENTE.Id
    ]
    if (
      (perfil === 'parceiro' && !statusPossiveis.includes(statusId)) ||
      solicitacao.enviado
    ) {
      return true
    }

    if (
      perfil === 'paciente' &&
      statusPaciente.find(item => item.Id === statusId)
    ) {
      return true
    }
  }

  function urlLink() {
    if (perfilAtual === SynPerfis.PARCEIRO_EXTERNO) return
    if (perfil === 'parceiro') {
      return handleAbrirSolicitacao(solicitacao)
    }

    if (
      perfil === 'paciente' &&
      (statusId === statusOrcamento.AGUARDANDO_RESPOSTA_PACIENTE.Id ||
        statusId === statusOrcamento.AGUARDANDO_CONFIRMACAO_PAGAMENTO.Id)
    ) {
      return navigate(
        `${SynRoutes.detalhesPropostaPaciente}?id=${solicitacao.id}`
      )
    }

    return handleVisualizarDetalhes(solicitacao)
  }

  function obterSubtitle() {
    return (
      <UBox
        mt={1}
        display="flex"
        render={['operacional'].includes(perfil)}
        alignItems="center"
      >
        <Tooltip
          title={
            solicitacao.sincronizadoComBitrix
              ? 'Sincronizado no Bitrix'
              : 'Erro ao sincronizar com o Bitrix'
          }
        >
          <Box
            mr={1}
            style={{
              width: '40px',
              height: '6px',
              background: solicitacao.sincronizadoComBitrix
                ? '#20a773'
                : '#e64a4a',
              borderRadius: '6px'
            }}
          ></Box>
        </Tooltip>
        {/* <Box
          mr={1}
          style={{
            width: '40px',
            height: '6px',
            background: '#800ead',
            borderRadius: '6px'
          }}
        ></Box> */}
        {/* <ChipStatusOrcamento
          statusId={solicitacao.status?.id}
          perfil={perfil}
          orcamentoAprovado={solicitacao.orcamentoAprovado}
          onClick={() => {
            if (perfil === 'operacional') {
              handleAlterarStatus(solicitacao)
            }
          }}
        />

        <UBox pl={1} render={solicitacao.pacienteTemCpf}>
          <Tooltip title="Solicitação possui responsável">
            <Chip
              style={{
                //  border: '1px solid #aaa',
                background: '#eee'
              }}
              label={
                <FaChild
                  style={{
                    color: '#555',
                    fontSize: '13px'
                  }}
                />
              }
              size="small"
            />
          </Tooltip>
        </UBox> */}
      </UBox>
    )
  }
  if (condicoesParaTexto()) {
    return (
      <>
        <Typography className="titulo-card" style={{ cursor: 'text' }}>
          {/* {perfilAtual === SynPerfis.PARCEIRO &&
            !!solicitacao.pacoteInteligenteId && (
              <Tooltip title="Orçamento instantâneo">
                <Chip
                  style={{
                    //  border: '1px solid #aaa',
                    background: 'none'
                  }}
                  label={
                    <AiTwotoneThunderbolt
                      className="icon"
                      style={{
                        fontSize: '26px',
                        paddingTop: '6px',
                        color: '#ebb852'
                      }}
                    />
                  }
                  size="small"
                />
              </Tooltip>
            )}
          {perfilAtual === SynPerfis.PARCEIRO &&
            !solicitacao.pacoteInteligenteId && (
              <Tooltip title="Orçamento cotação">
                <Chip
                  style={{
                    //  border: '1px solid #aaa',
                    background: 'none'
                  }}
                  label={
                    <BiNetworkChart
                      className="icon"
                      style={{
                        fontSize: '26px',
                        paddingTop: '6px',
                        color: '#1cc3a5'
                      }}
                    />
                  }
                  size="small"
                />
              </Tooltip>
            )}{' '} */}
          {solicitacao.descricaoPacoteProcedimento}
        </Typography>
        {obterSubtitle()}
      </>
    )
  }

  return (
    <>
      {perfilAtual !== SynPerfis.PARCEIRO_EXTERNO &&
      perfilAtual !== SynPerfis.PACIENTE ? (
        <UBox>
          {/* <p>teste</p> */}
          <Link
            component="span"
            variant="body1"
            color="primary"
            align="left"
            className="titulo-card"
            onClick={urlLink}
          >
            {/* {`${solicitacao.codigo} - `} */}
            {solicitacao?.descricaoPacoteProcedimento?.toUpperCase()}
          </Link>
        </UBox>
      ) : (
        <>
          <UBox
            style={{
              fontSize: '1rem',
              fontWeight: 600,
              textTransform: 'capitalize'
            }}
          >
            {/* <p>teste</p> */}
            {/* {`${solicitacao.codigo} - `} */}
            {solicitacao?.descricaoPacoteProcedimento?.toUpperCase()}
          </UBox>
        </>
      )}

      {solicitacao.codigoPlanilha && perfil === 'operacional' && (
        <>
          <br />
          <Link
            component="button"
            variant="body1"
            color="primary"
            align="left"
            onClick={urlLink}
          >
            {`Código planilha: ${solicitacao.codigoPlanilha}`}
          </Link>
        </>
      )}
      {obterSubtitle()}
    </>
  )
}
// #endregion

//#region Footer do Card
type RenderFooterProps = {
  perfil: Perfil
  solicitacao: ItemListagemSolicitacaoOrcamentoResponse
  handleMostrarOrcamento: ParceiroProps['handleMostrarOrcamento']
  handleVisualizarComprovantes?: MedicoProps['handleVisualizarComprovantes']
  handleMostrarDetalhes: ParceiroProps['handleMostrarDetalhes']
  handleAbrirSolicitacao: ParceiroProps['handleAbrirSolicitacao']
  setUltimaSolicitacao: MainProps['setUltimaSolicitacao']
  navigate: MainProps['navigate']
  handleHabilitarPagamento: OperacionalProps['handleHabilitarPagamento']
  permissoesSecretaria: ItemListagemAssistenteMedicoResponse
  copiarLinkPagamento: OperacionalProps['copiarLinkPagamento']
  handleMudarStatus: OperacionalProps['handleMudarStatus']
}

function renderFooter({
  perfil,
  solicitacao,
  // handleMostrarSolicitacao,
  handleMostrarOrcamento,
  setUltimaSolicitacao,
  handleVisualizarComprovantes,
  handleAbrirSolicitacao,
  navigate,
  handleHabilitarPagamento,
  handleMostrarDetalhes,
  copiarLinkPagamento,
  handleMudarStatus,
  permissoesSecretaria
}: RenderFooterProps) {
  const statusPaciente = [
    statusOrcamento['AGUARDANDO_RESPOSTA_PACIENTE'],
    statusOrcamento['AGUARDANDO_CONFIRMACAO_PAGAMENTO'],
    statusOrcamento['PAGAMENTO_PARCIAL'],
    statusOrcamento['EM_AGENDAMENTO'],
    statusOrcamento['REALIZADO'],
    statusOrcamento['PAGAMENTO_CONFIRMADO']
  ]
  const statusId = solicitacao?.status.id

  const { signOut, user, perfilAtual } = useAuth()

  switch (perfil) {
    case 'parceiro':
      return (
        <>
          <UBox
            render={
              !!solicitacao.pacoteInteligenteId
              // &&  user.tipoParceiro !== SynTipoParceiro.HOSPITAL_CLINICA.id
            }
            mt={2}
          >
            <UBox
              hidden={esconderFooterParceiro(
                solicitacao.status?.id,
                solicitacao.orcamentoAprovado
              )}
            >
              <Box mb={2}>
                <Divider />
              </Box>
              {user?.setorFuncionario ===
                SetoresLocalAtendimento.TESOURARIA.Id && (
                <Link
                  component="button"
                  variant="body2"
                  color="primary"
                  align="left"
                  onClick={() => {
                    handleMostrarDetalhes(
                      solicitacao.id,
                      solicitacao.parceiroId
                    )
                  }}
                >
                  Ver detalhes
                </Link>
              )}
              {perfilAtual === SynPerfis.TESOURARIA_HOSPITAL &&
                !!solicitacao?.propostaVencedoraId && (
                  <>
                    <Box mt={1}>
                      <Link
                        component="button"
                        variant="body2"
                        color="primary"
                        onClick={() => {
                          // setUltimaSolicitacao(solicitacao)
                          navigate(
                            `${SynRoutes.acompanharPagamento}?p=${solicitacao?.propostaVencedoraId}`
                          )
                        }}
                      >
                        Acompanhar pagamento
                      </Link>
                    </Box>
                  </>
                )}

              <UBox
                mt={1}
                style={{ display: 'flex', flexDirection: 'column' }}
                render={!!solicitacao.dataEnviado}
              >
                {user?.setorFuncionario !==
                  SetoresLocalAtendimento.TESOURARIA.Id && (
                  <Link
                    component="button"
                    variant="body2"
                    color="primary"
                    align="left"
                    onClick={() => {
                      handleMostrarOrcamento(solicitacao.id)
                    }}
                  >
                    {`Ver orçamento enviado ${formatarDataHoraLocal(
                      solicitacao.dataEnviado
                    )}`}
                  </Link>
                )}
                {/* COLOCAR VERIFICAÇÃO DE STATUS */}
                {user?.tipoParceiro !== SynTipoParceiro.HOSPITAL_CLINICA.id &&
                  user?.setorFuncionario !==
                    SetoresLocalAtendimento.TESOURARIA.Id &&
                  solicitacao.orcamentoAprovado && (
                    <Link
                      component="button"
                      variant="body2"
                      color="primary"
                      align="left"
                      onClick={() => {
                        handleVisualizarComprovantes(solicitacao)
                      }}
                    >
                      Ver comprovantes
                    </Link>
                  )}
              </UBox>
            </UBox>
          </UBox>
          <UBox render={!solicitacao.pacoteInteligenteId} mt={2}>
            <Box
              hidden={
                !solicitacao.enviado ||
                esconderFooterParceiro(
                  solicitacao.status?.id,
                  solicitacao.orcamentoAprovado
                )
              }
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <Box mb={2}>
                <Divider />
              </Box>
              <UBox render={!!solicitacao.dataEnviado}>
                {user?.setorFuncionario !==
                  SetoresLocalAtendimento.TESOURARIA.Id && (
                  <Link
                    component="button"
                    variant="body2"
                    color="primary"
                    align="left"
                    onClick={() => {
                      handleMostrarOrcamento(solicitacao.id)
                    }}
                  >
                    {`Ver orçamento enviado ${formatarDataHoraLocal(
                      solicitacao.dataEnviado
                    )}`}
                  </Link>
                )}
              </UBox>

              {/* COLOCAR VERIFICAÇÃO DE STATUS */}
              {user?.tipoParceiro !== SynTipoParceiro.HOSPITAL_CLINICA.id &&
                user?.setorFuncionario !==
                  SetoresLocalAtendimento.TESOURARIA.Id &&
                solicitacao.orcamentoAprovado && (
                  <Link
                    component="button"
                    variant="body2"
                    color="primary"
                    align="left"
                    onClick={() => {
                      handleVisualizarComprovantes(solicitacao)
                    }}
                  >
                    Ver comprovantes
                  </Link>
                )}
              {user?.setorFuncionario ===
                SetoresLocalAtendimento.TESOURARIA.Id && (
                <Link
                  component="button"
                  variant="body2"
                  color="primary"
                  align="left"
                  onClick={() => {
                    handleMostrarDetalhes(
                      solicitacao.id,
                      solicitacao.parceiroId
                    )
                  }}
                >
                  Ver detalhes
                </Link>
              )}
            </Box>

            {(solicitacao.status?.id === statusOrcamento.EM_ANDAMENTO.Id ||
              solicitacao.status?.id ===
                statusOrcamento.AGUARDANDO_APROVACAO_MEDICO.Id ||
              solicitacao.status?.id ===
                statusOrcamento.AGUARDANDO_RESPOSTA_PACIENTE.Id) &&
              !solicitacao.enviado && (
                <>
                  {/* <Box mb={2}>
                    <Divider />
                  </Box> */}
                  <Link
                    component="button"
                    variant="body2"
                    color="primary"
                    align="left"
                    onClick={() => {
                      handleAbrirSolicitacao(solicitacao)
                      setUltimaSolicitacao(solicitacao)
                    }}
                  >
                    Enviar orçamento
                  </Link>
                </>
              )}
              {perfilAtual === SynPerfis.TESOURARIA_HOSPITAL &&
                !!solicitacao?.propostaVencedoraId && (
                  <>
                    <Box mt={1}>
                      <Link
                        component="button"
                        variant="body2"
                        color="primary"
                        onClick={() => {
                          // setUltimaSolicitacao(solicitacao)
                          navigate(
                            `${SynRoutes.acompanharPagamento}?p=${solicitacao?.propostaVencedoraId}`
                          )
                        }}
                      >
                        Acompanhar pagamento
                      </Link>
                    </Box>
                  </>
                )}
            {/* <Link
              component="button"
              variant="body2"
              color="primary"
              align="left"
              onClick={() => {
                handleAbrirSolicitacao(solicitacao)
                setUltimaSolicitacao(solicitacao)
              }}
            >
              Enviar orçamento
            </Link> */}
          </UBox>
        </>
      )
    case 'paciente':
      if (statusPaciente.find(status => status.Id === solicitacao.status.id)) {
        return (
          <>
            <Divider />
            <Box mt={2}>
              <Link
                component="button"
                variant="body2"
                color="primary"
                onClick={() =>
                  navigate(
                    `${SynRoutes.detalhesPropostaPaciente}?id=${solicitacao.id}`
                  )
                }
              >
                {statusId === statusOrcamento.AGUARDANDO_RESPOSTA_PACIENTE.Id
                  ? 'Clique aqui para ver as propostas'
                  : 'Clique aqui para ver as propostas'}
              </Link>
            </Box>
            {(statusId ===
              statusOrcamento.AGUARDANDO_CONFIRMACAO_PAGAMENTO.Id ||
              statusId === statusOrcamento.PAGAMENTO_PARCIAL.Id) &&
              solicitacao?.propostaVencedoraId &&
              solicitacao?.pagamentoHabilitado && (
                <Box mt={1}>
                  <Link
                    component="button"
                    variant="body2"
                    color="primary"
                    onClick={() =>
                      navigate(
                        `${SynRoutes.resumoPagamento}?p=${solicitacao?.propostaVencedoraId}`
                      )
                    }
                  >
                    Pagamento
                  </Link>
                </Box>
              )}

            {
              // (statusId === statusOrcamento.EM_AGENDAMENTO.Id ||
              //   statusId === statusOrcamento.PAGAMENTO_CONFIRMADO.Id ||
              //   statusId === statusOrcamento.FINALIZADO.Id) &&
              solicitacao?.propostaVencedoraId &&
                solicitacao?.pagamentoHabilitado &&
                solicitacao?.acompanharPagamentoHabilitado && (
                  <Box mt={1}>
                    <Link
                      component="button"
                      variant="body2"
                      color="primary"
                      onClick={() => {
                        // setUltimaSolicitacao(solicitacao)
                        navigate(
                          `${SynRoutes.acompanharPagamento}?p=${solicitacao?.propostaVencedoraId}`
                        )
                      }}
                    >
                      Acompanhar pagamento
                    </Link>
                  </Box>
                )
            }
            {[
              statusOrcamento.EM_AGENDAMENTO.Id,
              statusOrcamento.PAGAMENTO_CONFIRMADO.Id,
              statusOrcamento.REALIZADO.Id
            ].includes(statusId) &&
              solicitacao?.propostaVencedoraId && (
                // solicitacao?.acompanharPagamentoHabilitado &&
                <Box mt={1}>
                  <Link
                    component="button"
                    variant="body2"
                    color="primary"
                    onClick={() =>
                      navigate(
                        `${SynRoutes.instrucoesNotaFiscal}?p=${solicitacao?.propostaVencedoraId}&nome=${solicitacao?.codigo} - ${solicitacao?.descricaoPacoteProcedimento}`
                      )
                    }
                  >
                    Instruções para nota fiscal
                  </Link>
                </Box>
              )}
          </>
        )
      }
      return <></>
    default:
      return (
        <>
          <UBox
            render={
              perfilAtual !== SynPerfis.PARCEIRO_EXTERNO &&
              perfilAtual !== SynPerfis.FINANCEIRO
            }
          >
            <Divider />
            <Box mt={2} mb={1}>
              <Typography variant="subtitle2">Propostas</Typography>
            </Box>
            {!solicitacao.qtdPropostas ? (
              <Box mb={2}>
                <Typography variant="body2">Nenhuma proposta ainda</Typography>
              </Box>
            ) : (
              <>
                <Grid container>
                  {solicitacao.qtdPropostasVencedoras > 0 && (
                    <Grid item xs={12} sm={12}>
                      <Typography
                        style={{
                          color: StatusProposta.VENCEDORA.Color
                        }}
                        variant="body2"
                      >
                        1 Proposta vencedora
                      </Typography>
                    </Grid>
                  )}
                  {solicitacao.qtdPropostasEnviadas > 0 && (
                    <Grid item xs={12} sm={12}>
                      <Typography
                        style={{
                          color:
                            StatusProposta.AGUARDANDO_APROVACAO_MEDICO.Color
                        }}
                        variant="body2"
                      >
                        {solicitacao.qtdPropostasEnviadas} Aguardando aprovação
                        médica
                      </Typography>
                    </Grid>
                  )}
                  {solicitacao.qtdPropostasAprovadas > 0 && (
                    <Grid item xs={12} sm={12}>
                      <Typography
                        style={{
                          color: StatusProposta.APROVADA_PELO_MEDICO.Color
                        }}
                        variant="body2"
                      >
                        {solicitacao.qtdPropostasAprovadas === 1
                          ? '1 Aprovada pelo médico'
                          : `${solicitacao.qtdPropostasAprovadas} Aprovadas pelo médico`}
                      </Typography>
                    </Grid>
                  )}
                  {solicitacao.qtdPropostasRecusadas > 0 && (
                    <Grid item xs={12} sm={12}>
                      <Typography
                        style={{
                          color: StatusProposta.RECUSADA_PELO_MEDICO.Color
                        }}
                        variant="body2"
                      >
                        {solicitacao.qtdPropostasRecusadas === 1
                          ? '1 Recusada'
                          : `${solicitacao.qtdPropostasRecusadas} Recusadas`}
                      </Typography>
                    </Grid>
                  )}
                  {solicitacao.qtdPropostasRecusadasHospital > 0 && (
                    <Grid item xs={12} sm={12}>
                      <Typography
                        style={{
                          color: StatusProposta.RECUSADA_PELO_MEDICO.Color
                        }}
                        variant="body2"
                      >
                        {solicitacao.qtdPropostasRecusadasHospital === 1
                          ? '1 Recusada'
                          : `${solicitacao.qtdPropostasRecusadasHospital} Recusadas`}
                      </Typography>
                    </Grid>
                  )}
                  {solicitacao.qtdPropostasAguardandoPaciente > 0 && (
                    <Grid item xs={12} sm={12}>
                      <Typography
                        style={{
                          color:
                            StatusProposta.AGUARDANDO_APROVACAO_PACIENTE.Color
                        }}
                        variant="body2"
                      >
                        {`${solicitacao.qtdPropostasAguardandoPaciente} Aguardando paciente`}
                      </Typography>
                    </Grid>
                  )}
                  {solicitacao.qtdPropostasAprovadasPaciente > 0 && (
                    <Grid item xs={12} sm={12}>
                      <Typography
                        style={{
                          color: StatusProposta.APROVADA_PELO_PACIENTE.Color
                        }}
                        variant="body2"
                      >
                        {solicitacao.qtdPropostasAprovadasPaciente === 1
                          ? '1 Aprovada pelo paciente'
                          : `${solicitacao.qtdPropostasAprovadasPaciente} Aprovadas pelo paciente`}
                      </Typography>
                    </Grid>
                  )}

                  {solicitacao.qtdPropostasRecusadasPaciente > 0 && (
                    <Grid item xs={12} sm={12}>
                      <Typography
                        style={{
                          color: StatusProposta.RECUSADA_PELO_PACIENTE.Color
                        }}
                        variant="body2"
                      >
                        {solicitacao.qtdPropostasRecusadasPaciente === 1
                          ? '1 Recusada pelo paciente'
                          : `${solicitacao.qtdPropostasRecusadasPaciente} Recusadas pelo paciente`}
                      </Typography>
                    </Grid>
                  )}

                  {solicitacao.qtdPendenteRevisaoValores > 0 && (
                    <Grid item xs={12} sm={12}>
                      <Typography
                        style={{
                          color: StatusProposta.PENDENTE_REVISAO.Color
                        }}
                        variant="body2"
                      >
                        {solicitacao.qtdPendenteRevisaoValores === 1
                          ? '1 Pendente de revisão'
                          : `${solicitacao.qtdPendenteRevisaoValores} Pendente de revisão`}
                      </Typography>
                    </Grid>
                  )}
                  <UBox
                    render={
                      perfilAtual !== SynPerfis.OPERACIONAL
                        ? statusOrcamento.PENDENTE_REVISAO.Id !==
                            solicitacao.status.id &&
                          (perfil !== 'secretaria' ||
                            (perfil === 'secretaria' &&
                              !!permissoesSecretaria?.podeAprovarRecusarProposta))
                        : perfil !== 'secretaria' ||
                          (perfil === 'secretaria' &&
                            !!permissoesSecretaria?.podeAprovarRecusarProposta)
                    }
                  >
                    <Link
                      component="button"
                      variant="body2"
                      color="primary"
                      align="left"
                      onClick={() => {
                        if (
                          perfil === 'medico' ||
                          perfilAtual === SynPerfis.PARCEIRO_EXTERNO ||
                          (perfil === 'secretaria' &&
                            !!permissoesSecretaria?.podeAprovarRecusarProposta)
                        ) {
                          navigate(
                            `${SynRoutes.detalhesPropostasMedico}?id=${solicitacao.id}`
                          )
                        }
                        if (['operacional', 'comercial'].includes(perfil)) {
                          navigate(
                            `${SynRoutes.detalhesPropostas}?id=${solicitacao.id}`
                          )
                        }
                        setUltimaSolicitacao(solicitacao)
                      }}
                      style={{ marginTop: '10px' }}
                    >
                      Ver propostas
                    </Link>
                  </UBox>
                </Grid>
              </>
            )}

            {(perfil === 'medico' || perfil === 'secretaria') &&
              !!solicitacao?.propostaVencedoraId &&
              solicitacao?.pagamentoHabilitado && (
                <Box>
                  <Link
                    component="button"
                    variant="body2"
                    color="primary"
                    onClick={() => {
                      setUltimaSolicitacao(solicitacao)
                      navigate(
                        `${SynRoutes.acompanharPagamento}?p=${solicitacao?.propostaVencedoraId}`
                      )
                    }}
                  >
                    Acompanhar pagamento
                  </Link>
                </Box>
              )}

            {(perfil === 'medico' || perfil === 'secretaria') &&
              !!solicitacao?.propostaVencedoraId &&
              solicitacao?.pagamentoHabilitado && (
                <Box>
                  <Link
                    component="button"
                    variant="body2"
                    color="primary"
                    onClick={() => {
                      handleVisualizarComprovantes(solicitacao)
                    }}
                  >
                    Ver comprovantes
                  </Link>
                </Box>
              )}

            {perfil === 'operacional' && (
              <Box>
                {!solicitacao?.pacoteInteligenteId && (
                  <Box>
                    <Link
                      component="button"
                      variant="body2"
                      color="primary"
                      align="left"
                      onClick={() => {
                        navigate(
                          `${SynRoutes.dadosSolicitacaoOperacionalOPME}?id=${solicitacao.id}`
                        )
                        setUltimaSolicitacao(solicitacao)
                      }}
                    >
                      Preparar propostas
                    </Link>
                  </Box>
                )}

                {!!solicitacao?.propostaVencedoraId &&
                  solicitacao?.pagamentoHabilitado && (
                    <Box>
                      <Link
                        component="button"
                        variant="body2"
                        color="primary"
                        onClick={() => {
                          setUltimaSolicitacao(solicitacao)
                          navigate(
                            `${SynRoutes.acompanharPagamento}?p=${solicitacao?.propostaVencedoraId}`
                          )
                        }}
                      >
                        Acompanhar pagamento
                      </Link>
                    </Box>
                  )}
                {(statusId ===
                  statusOrcamento.AGUARDANDO_CONFIRMACAO_PAGAMENTO.Id ||
                  statusId === statusOrcamento.PAGAMENTO_PARCIAL.Id) &&
                  !!solicitacao?.propostaVencedoraId &&
                  solicitacao?.pagamentoHabilitado &&
                  (perfilAtual === SynPerfis.ADMIN_HOSPITAL ||
                    perfilAtual === SynPerfis.OPERACIONAL_HOSPITAL) && (
                    <Box>
                      <Link
                        component="button"
                        variant="body2"
                        color="primary"
                        onClick={() => {
                          copiarLinkPagamento(solicitacao?.propostaVencedoraId)
                        }}
                      >
                        Gerar Link de pagamento
                      </Link>
                    </Box>
                  )}

                {perfilAtual !== SynPerfis.ADMIN_HOSPITAL &&
                  perfilAtual !== SynPerfis.OPERACIONAL_HOSPITAL && (
                    <>
                      <Box>
                        {solicitacao?.loadingPagamentoHabilitado ? (
                          <CircularProgress size={20} />
                        ) : (
                          <Link
                            component="button"
                            variant="body2"
                            color="primary"
                            onClick={() =>
                              handleHabilitarPagamento(
                                solicitacao,
                                !solicitacao?.pagamentoHabilitado
                              )
                            }
                          >
                            {!solicitacao?.pagamentoHabilitado
                              ? 'Habilitar pagamento'
                              : 'Desabilitar pagamento'}
                          </Link>
                        )}
                      </Box>
                      {solicitacao?.propostaVencedoraId && (
                        // solicitacao?.acompanharPagamentoHabilitado &&
                        <Box>
                          <Link
                            component="button"
                            variant="body2"
                            color="primary"
                            onClick={() => {
                              setUltimaSolicitacao(solicitacao)
                              navigate(
                                `${SynRoutes.instrucoesNotaFiscal}?p=${solicitacao?.propostaVencedoraId}&nome=${solicitacao?.codigo} - ${solicitacao?.descricaoPacoteProcedimento}`
                              )
                            }}
                          >
                            Instruções para nota fiscal
                          </Link>
                        </Box>
                      )}
                    </>
                  )}
              </Box>
            )}
          </UBox>
        </>
      )
  }
}
// #endregion

/* As Props foram divididas pra que, dependendo do perfil, algumas props sejam obrigatórias */

//#region Types
type Perfil =
  | 'operacional'
  | 'medico'
  | 'paciente'
  | 'parceiro'
  | 'comercial'
  | 'secretaria'

interface MainProps {
  solicitacao: ItemListagemSolicitacaoOrcamentoResponse
  setClassToHighlight: (
    item: ItemListagemSolicitacaoOrcamentoResponse
  ) => string
  setUltimaSolicitacao: (item: ItemListagemSolicitacaoOrcamentoResponse) => void
  navigate: (route: string) => void
}

type OperacionalProps = {
  handleVisualizarDetalhes: (
    item: ItemListagemSolicitacaoOrcamentoResponse
  ) => void
  carregarListagem: (options: {
    resetarPaginacao: Boolean
    status?: string
    tag?: string
    statusProposta?: string
    newDados?: any
    criadoPorHospital?: '0' | '1' | '2'
  }) => void
  handleAtividades: (item: ItemListagemSolicitacaoOrcamentoResponse) => void
  handleAlterarStatus: (item: ItemListagemSolicitacaoOrcamentoResponse) => void
  handleInstrucoesNF: (item: ItemListagemSolicitacaoOrcamentoResponse) => void
  handleExcluir: (itemId: string) => void
  handleLogStatus: (item: ItemListagemSolicitacaoOrcamentoResponse) => void
  handleAnexo: (item: ItemListagemSolicitacaoOrcamentoResponse) => void
  handleCadastrarCpf: (item: ItemListagemSolicitacaoOrcamentoResponse) => void
  handleAbrirModalTag: (item: ItemListagemSolicitacaoOrcamentoResponse) => void
  removerTag: (command: SetarTagCommand) => void
  handleVisualizarParceiros: (
    item: ItemListagemSolicitacaoOrcamentoResponse
  ) => void
  handleAlterarHonorarios: (
    item: ItemListagemSolicitacaoOrcamentoResponse
  ) => void
  handleOperacional: OperacionalResponse[]
  handleAbrirModalDono: (item: ItemListagemSolicitacaoOrcamentoResponse) => void
  handleHabilitarPagamento: (
    item: ItemListagemSolicitacaoOrcamentoResponse,
    habilitado: boolean
  ) => void
  copiarLinkPagamento: (propostaId: string) => void
  handleMudarStatus: (item: ItemListagemSolicitacaoOrcamentoResponse) => void
  handleLiberarEdicao: (item: ItemListagemSolicitacaoOrcamentoResponse) => void
}

type ComercialProps = {
  handleVisualizarDetalhes: (
    item: ItemListagemSolicitacaoOrcamentoResponse
  ) => void
  carregarListagem: (options: {
    resetarPaginacao: Boolean
    status?: string
    tag?: string
    statusProposta?: string
    newDados?: any
    criadoPorHospital?: '0' | '1' | '2'
  }) => void
  handleLogStatus: (item: ItemListagemSolicitacaoOrcamentoResponse) => void
  handleVisualizarParceiros: (
    item: ItemListagemSolicitacaoOrcamentoResponse
  ) => void
}

type MedicoProps = {
  handleVisualizarDetalhes: (
    item: ItemListagemSolicitacaoOrcamentoResponse
  ) => void
  handleImprimir: (item: ItemListagemSolicitacaoOrcamentoResponse) => void
  handleEditar?: (item: ItemListagemSolicitacaoOrcamentoResponse) => void
  handleHistoricoEditar?: (
    item: ItemListagemSolicitacaoOrcamentoResponse
  ) => void
  handleVisualizarOcorrencias: (solicitacaoId: string) => void
  handleVisualizarComprovantes: (
    item: ItemListagemSolicitacaoOrcamentoResponse
  ) => void
  handleAlterarStatusProcedimentoRealizado: (idSolicitacao: string) => void
}

type ParceiroProps = {
  handleAbrirSolicitacao: (
    item: ItemListagemSolicitacaoOrcamentoResponse
  ) => void
  handleMostrarOrcamento: (itemId: string) => void
  handleMostrarDetalhes: (itemId: string, parceiroId: string) => void
}

type PacienteProps = {
  handleVisualizarDetalhes: (
    item: ItemListagemSolicitacaoOrcamentoResponse
  ) => void
}

type Props = MainProps &
  (
    | (// Operacional
      {
        perfil: 'operacional'
      } & OperacionalProps &
        Partial<MedicoProps> &
        Partial<ParceiroProps> &
        Partial<PacienteProps>)
    | (// Operacional
      {
        perfil: 'comercial'
      } & ComercialProps &
        Partial<MedicoProps> &
        Partial<ParceiroProps> &
        Partial<PacienteProps> &
        Partial<OperacionalProps>)
    | (// Médico
      {
        perfil: 'medico'
      } & MedicoProps &
        Partial<ParceiroProps> &
        Partial<PacienteProps> &
        Partial<OperacionalProps>)
    | ({
        // Paciente
        perfil: 'paciente'
      } & PacienteProps &
        Partial<ParceiroProps> &
        Partial<MedicoProps> &
        Partial<OperacionalProps>)
    | ({
        // Parceiro
        perfil: 'parceiro'
      } & ParceiroProps &
        Partial<MedicoProps> &
        Partial<PacienteProps> &
        Partial<OperacionalProps>)
    | ({
        perfil: 'secretaria'
      } & MedicoProps &
        Partial<ParceiroProps> &
        Partial<PacienteProps> &
        Partial<OperacionalProps>)
  )

//#endregion

const useStyles = makeStyles(theme => ({
  cardListagem: {
    borderColor: '#eee',
    '& .titulo-card': {
      fontWeight: 600,
      cursor: 'pointer'
    },
    '&.MuiPaper-rounded': {
      borderRadius: '12px'
    },
    '& .MuiCardHeader-root': {
      borderBottom: '1px solid #efefef',
      marginBottom: '8px'
    }
  },

  badgeCustom: {
    '& .MuiBadge-colorSecondary': {
      // backgroundColor: theme.palette.grey[100]
      backgroundColor: '#e91e63'
    }
  },

  [theme.breakpoints.down('sm')]: {
    cardListagem: {}
  },
  '@media (max-width:344px)': {
    cardListagem: {
      '& .MuiCardHeader-root': {
        flexDirection: 'column',
        alignItems: 'start'
      },
      '& .MuiCardHeader-action': {
        marginLeft: 'auto'
      },
      '& .status-icones': {
        flexDirection: 'column',
        alignItems: 'start'
      }
    }
  }
}))

export default function CardListagemSolicitacao({
  solicitacao,
  perfil,
  setClassToHighlight,
  setUltimaSolicitacao,
  navigate,
  handleAlterarStatus,
  handleAtividades,
  handleExcluir,
  handleLogStatus,
  handleCadastrarCpf,
  handleVisualizarDetalhes,
  handleAbrirModalTag,
  removerTag,
  handleAlterarStatusProcedimentoRealizado,
  carregarListagem,
  handleVisualizarParceiros,
  handleEditar,
  handleHistoricoEditar,
  handleImprimir,
  handleAbrirSolicitacao,
  handleMostrarOrcamento,
  handleVisualizarOcorrencias,
  handleAlterarHonorarios,
  handleVisualizarComprovantes,
  handleInstrucoesNF,
  handleOperacional,
  handleAbrirModalDono,
  handleHabilitarPagamento,
  copiarLinkPagamento,
  handleMudarStatus,
  handleMostrarDetalhes,
  handleAnexo,
  handleLiberarEdicao
}: Props) {
  const classes = useStyles()
  const [loading, setLoading] = useState<boolean>(false)
  const { signOut, user, perfilAtual } = useAuth()
  const [
    permissoesSecretaria,
    setPermissoesSecretaria
  ] = useState<ItemListagemAssistenteMedicoResponse>(null)

  const [verTexto, setVerTexto] = React.useState(false)

  const handleVerTexto = () => {
    setVerTexto(!verTexto)
  }

  const truncatedText =
    solicitacao?.agendamento?.observacao?.length > 30
      ? `${solicitacao?.agendamento?.observacao?.substring(0, 30)}... `
      : solicitacao?.agendamento?.observacao

  useEffect(() => {
    if (perfil === 'secretaria') obterPermissoes()
  }, [])

  async function obterPermissoes() {
    var response = await buscarPermissoesPorId(solicitacao?.medicoId)
    if (!!response?.data) setPermissoesSecretaria(response?.data)
  }

  function obterStyleDataSolicitado(): any {
    var style = {
      // position: 'absolute',
      // top: '-36px',
      // textAlign: 'right',
      fontStyle: 'italic',
      fontSize: '12px',
      fontWeSight: 300,
      color: '#919191'
    }
    if (perfil === 'operacional') {
      style['right'] = '24px'
    } else {
      style['left'] = '16px'
    }

    return style
  }

  function obterSubtitle() {
    return (
      <>
        <Box
          my={2}
          display="flex"
          alignItems="center"
          className="status-icones"
        >
          <ChipStatusOrcamento
            statusId={solicitacao.status?.id}
            perfil={perfil}
            orcamentoAprovado={solicitacao.orcamentoAprovado}
            existePropostaAguardandoPaciente={
              solicitacao.existePropostaAguardandoPaciente
            }
            onClick={() => {
              if (perfil === 'operacional') {
                handleAlterarStatus(solicitacao)
              }
            }}
          />
          <UBox display="flex">
            <UBox
              pl={1}
              render={
                !!solicitacao.pacienteTemResponsavel &&
                ['operacional', 'medico', 'paciente'].includes(perfil)
              }
            >
              <Tooltip title="Solicitação possui responsável">
                <Chip
                  style={{
                    //  border: '1px solid #aaa',
                    background: '#eee'
                  }}
                  label={
                    <FaChild
                      style={{
                        color: '#555',
                        fontSize: '13px'
                      }}
                    />
                  }
                  size="small"
                />
              </Tooltip>
            </UBox>

            <UBox
              pl={1}
              render={
                !!solicitacao.pagamentoHabilitado &&
                ['operacional'].includes(perfil)
              }
            >
              <Tooltip title="Pagamento habilitado">
                <Chip
                  style={{
                    //  border: '1px solid #aaa',
                    background: 'none'
                  }}
                  label={
                    <FaMoneyCheckAlt
                      style={{
                        color: '#3bd1b6',
                        fontSize: '26px',
                        paddingTop: '6px'
                      }}
                    />
                  }
                  size="small"
                />
              </Tooltip>
            </UBox>

            <UBox
              // pl={1}
              render={
                !!solicitacao.pacoteInteligenteId &&
                ['operacional', 'medico', 'secretaria', 'comercial'].includes(
                  perfil
                )
              }
            >
              <Tooltip title="Orçamento instantâneo">
                <Chip
                  style={{
                    //  border: '1px solid #aaa',
                    background: 'none'
                  }}
                  label={
                    <AiTwotoneThunderbolt
                      className="icon"
                      style={{
                        fontSize: '26px',
                        paddingTop: '6px',
                        color: '#ebb852'
                      }}
                    />
                  }
                  size="small"
                />
              </Tooltip>
            </UBox>

            <UBox
              // pl={1}
              render={
                !solicitacao.pacoteInteligenteId &&
                ['operacional', 'medico', 'secretaria', 'comercial'].includes(
                  perfil
                )
              }
            >
              <Tooltip title="Orçamento cotação">
                <Chip
                  style={{
                    //  border: '1px solid #aaa',
                    background: 'none'
                  }}
                  label={
                    <BiNetworkChart
                      className="icon"
                      style={{
                        fontSize: '26px',
                        paddingTop: '6px',
                        color: '#1cc3a5'
                      }}
                    />
                  }
                  size="small"
                />
              </Tooltip>
            </UBox>
          </UBox>
        </Box>
        {(solicitacao.status?.id === statusOrcamento.SOLICITACAO_RECEBIDA.Id ||
          solicitacao.status?.id ===
            statusOrcamento.REVISAR_LIBERAR_COTACAO.Id) &&
          (perfilAtual === SynPerfis.OPERACIONAL ||
            perfilAtual === SynPerfis.ADMIN_HOSPITAL ||
            perfilAtual === SynPerfis.OPERACIONAL_HOSPITAL) && (
            <Box mt={-1} mb={1}>
              <ButtonSrc
                size="small"
                fullWidth={true}
                variant="contained"
                color="secondary"
                onClick={() => handleMudarStatus(solicitacao)}
              >
                Liberar orçamento
              </ButtonSrc>
            </Box>
          )}

        {!!mostrarLiberarEdicao(solicitacao, perfilAtual) && (
          <Box mt={-1} mb={1}>
            <ButtonSrc
              size="small"
              fullWidth={true}
              variant="outlined"
              color="secondary"
              onClick={() => handleLiberarEdicao(solicitacao)}
            >
              Liberar para edição
            </ButtonSrc>
          </Box>
        )}
      </>
    )
  }
  return (
    <Card
      variant="outlined"
      className={setClassToHighlight(solicitacao) + ' ' + classes.cardListagem}
    >
      <UBox
        style={{ borderBottom: '1px solid #eee', padding: '2px 8px 4px 12px' }}
      >
        <UBox display="flex" alignItems="center" justifyContent="space-between">
          <UBox
            style={{
              display: 'flex',
              margin:
                perfil === 'paciente' ||
                perfilAtual === SynPerfis.PARCEIRO_EXTERNO
                  ? '8px 0 0'
                  : '0px'
            }}
          >
            <Typography
              variant="body2"
              color="primary"
              style={{
                padding: '2px 4px',
                border: '1px solid #eee',
                borderRadius: '8px',
                background: '#eee'
              }}
            >
              <b>{solicitacao?.codigo}</b>
            </Typography>

            {parceiros.includes(perfilAtual) &&
              !!solicitacao.pacoteInteligenteId && (
                <Tooltip title="Orçamento instantâneo">
                  <Chip
                    style={{
                      //  border: '1px solid #aaa',
                      background: 'none'
                    }}
                    label={
                      <AiTwotoneThunderbolt
                        className="icon"
                        style={{
                          fontSize: '26px',
                          paddingTop: '6px',
                          color: '#ebb852'
                        }}
                      />
                    }
                    size="small"
                  />
                </Tooltip>
              )}

            {parceiros.includes(perfilAtual) &&
              !solicitacao.pacoteInteligenteId && (
                <Tooltip title="Orçamento cotação">
                  <Chip
                    style={{
                      //  border: '1px solid #aaa',
                      background: 'none'
                    }}
                    label={
                      <BiNetworkChart
                        className="icon"
                        style={{
                          fontSize: '26px',
                          paddingTop: '6px',
                          color: '#1cc3a5'
                        }}
                      />
                    }
                    size="small"
                  />
                </Tooltip>
              )}
          </UBox>
          <UBox>
            {perfil === 'operacional' ? (
              <MenuOperacional
                navigate={navigate}
                setUltimaSolicitacao={setUltimaSolicitacao}
                solicitacao={solicitacao}
                handleAlterarStatus={handleAlterarStatus}
                handleAtividades={handleAtividades}
                handleEditar={handleEditar}
                handleInstrucoesNF={handleInstrucoesNF}
                handleAlterarHonorarios={handleAlterarHonorarios}
                handleCadastrarCpf={handleCadastrarCpf}
                handleExcluir={handleExcluir}
                handleLogStatus={handleLogStatus}
                handleOperacional={handleOperacional}
                handleAbrirModalDono={handleAbrirModalDono}
                handleAnexo={handleAnexo}
                handleHistoricoEditar={handleHistoricoEditar}
                handleLiberarEdicao={handleLiberarEdicao}
              />
            ) : perfil === 'medico' || perfil === 'secretaria' ? (
              <MenuMedico
                solicitacao={solicitacao}
                perfil={perfil}
                permissoesSecretaria={permissoesSecretaria}
                handleEditar={handleEditar}
                handleImprimir={handleImprimir}
                handleVisualizarComprovantes={handleVisualizarComprovantes}
                handleVisualizarDetalhes={handleVisualizarDetalhes}
                handleVisualizarOcorrencias={handleVisualizarOcorrencias}
                handleAlterarStatusProcedimentoRealizado={
                  handleAlterarStatusProcedimentoRealizado
                }
                handleHistoricoEditar={handleHistoricoEditar}
                handleLiberarEdicao={handleLiberarEdicao}
              />
            ) : perfil === 'comercial' ? (
              <MenuComercial
                solicitacao={solicitacao}
                handleLogStatus={handleLogStatus}
                handleVisualizarDetalhes={handleVisualizarDetalhes}
              />
            ) : perfil === 'parceiro' ? (
              <MenuParceiro
                solicitacao={solicitacao}
                handleVisualizarOcorrencias={handleVisualizarOcorrencias}
                handleVisualizarDetalhes={handleVisualizarDetalhes}
              />
            ) : null}
          </UBox>
        </UBox>
        <UBox style={{ marginTop: perfil !== 'paciente' ? '-4px' : '8px' }}>
          {renderTitle({
            perfil,
            handleAbrirSolicitacao,
            handleVisualizarDetalhes,
            handleAlterarStatus,
            handleVisualizarOcorrencias,
            solicitacao,
            navigate
          })}
        </UBox>

        <UBox style={obterStyleDataSolicitado()}>
          Solicitado em {formatarDataHoraLocal(solicitacao.criado)}
        </UBox>
      </UBox>

      <CardContent style={{ paddingTop: 0, position: 'relative' }}>
        {obterSubtitle()}
        {/* {JSON.stringify(solicitacao)} */}

        {(perfilAtual === SynPerfis.OPERACIONAL ||
          perfilAtual === SynPerfis.COMERCIAL_B2B ||
          perfilAtual === SynPerfis.COMERCIAL_EXPANSAO ||
          perfilAtual === SynPerfis.COMERCIAL_MEDICOS) &&
        perfilAtual !== SynPerfis.PARCEIRO_EXTERNO &&
        perfilAtual !== SynPerfis.FINANCEIRO ? (
          <Chip
            style={{
              border: '2px solid #aaa',
              background: '#eee',
              fontWeight: 600,
              cursor: 'pointer'
            }}
            label={
              solicitacao?.donoSolicitacaoId ? (
                <div
                  onClick={() => {
                    handleAbrirModalDono(solicitacao)
                  }}
                >
                  Responsável Operacional:{' '}
                  {primeiroNome(
                    handleOperacional?.find(
                      w => w.id === solicitacao?.donoSolicitacaoId
                    )?.descricao
                  )}
                </div>
              ) : perfilAtual === SynPerfis.OPERACIONAL ? (
                <div
                  onClick={() => {
                    handleAbrirModalDono(solicitacao)
                  }}
                >
                  S/ Responsável Operacional
                </div>
              ) : (
                <div>S/ Responsável Operacional</div>
              )
            }
            size="small"
          />
        ) : (
          ''
        )}
        <Box mb={1}>
          {(perfil === 'secretaria' ||
            perfil === 'medico' ||
            perfil === 'operacional') &&
            solicitacao.nomeAssistente && (
              <Grid item xs={12} sm={12}>
                <Typography variant="subtitle2">
                  Essa solicitação foi feita por:
                </Typography>
                <Typography variant="body2">
                  {solicitacao.nomeAssistente}
                </Typography>
              </Grid>
            )}
        </Box>

        <Box mt={0}>
          <Typography variant="subtitle2">Paciente:</Typography>
          <Typography variant="body2">{solicitacao.nomePaciente}</Typography>
        </Box>

        {perfil !== 'medico' && (
          <Box mt={1}>
            <Typography variant="subtitle2">Médico:</Typography>
            <Typography variant="body2">{solicitacao.nomeMedico}</Typography>
          </Box>
        )}

        {(perfil === 'operacional' ||
          perfil === 'medico' ||
          perfil === 'secretaria') &&
          perfilAtual !== SynPerfis.PARCEIRO_EXTERNO &&
          !solicitacao.pacoteInteligenteId && (
            <UBox
              render={
                !!solicitacao.valorMinHonorarios &&
                perfilAtual !== SynPerfis.OPERACIONAL_HOSPITAL &&
                perfilAtual !== SynPerfis.ADMIN_HOSPITAL
              }
              mt={1}
            >
              <Typography variant="subtitle2">Honorário flexível:</Typography>
              <Typography variant="body2">
                {`${formatarDinheiro(
                  solicitacao.valorMinHonorarios
                )} até ${formatarDinheiro(solicitacao.valorMaxHonorarios)}`}
              </Typography>
            </UBox>
          )}

        {(perfil === 'operacional' ||
          perfil === 'medico' ||
          perfil === 'secretaria') &&
          perfilAtual !== SynPerfis.PARCEIRO_EXTERNO && (
            <UBox
              render={
                perfilAtual === SynPerfis.OPERACIONAL_HOSPITAL ||
                perfilAtual === SynPerfis.ADMIN_HOSPITAL
              }
              mt={1}
            >
              <Typography variant="subtitle2">Honorário flexível:</Typography>
              <Typography variant="body2">
                {!(
                  solicitacao.valorMinHonorarios ===
                  solicitacao.valorMaxHonorarios
                )
                  ? ' Possui honorário médico'
                  : ' Não possui honorário médico'}
              </Typography>
            </UBox>
          )}

        {(perfil === 'medico' || perfil === 'secretaria') &&
          (statusOrcamento.AGUARDANDO_CONFIRMACAO_PAGAMENTO.Id ===
            solicitacao.status.id ||
            statusOrcamento.PAGAMENTO_CONFIRMADO.Id === solicitacao.status.id ||
            statusOrcamento.EM_AGENDAMENTO.Id === solicitacao.status.id ||
            statusOrcamento.REALIZADO.Id === solicitacao.status.id) && (
            <UBox render={!!solicitacao.honorarioEquipeMedica} mt={1}>
              <Typography variant="subtitle2">Honorário aprovado:</Typography>
              <Typography variant="body2">
                {formatarDinheiro(solicitacao.honorarioEquipeMedica)}
              </Typography>
            </UBox>
          )}

        {(perfil === 'operacional' ||
          perfil === 'medico' ||
          perfil === 'secretaria') &&
          solicitacao.nomeParceiro && (
            <Box mt={1}>
              <Typography variant="body2">
                {'Criado por: ' + solicitacao.nomeParceiro}
              </Typography>
            </Box>
          )}

        {(perfil === 'operacional' ||
          perfil === 'medico' ||
          perfil === 'secretaria') &&
          solicitacao.uf && (
            <Box mt={1}>
              <Typography
                variant="subtitle2"
                noWrap
                style={{ display: 'inline' }}
              >
                UF:{' '}
              </Typography>
              <Typography variant="body2" noWrap style={{ display: 'inline' }}>
                {solicitacao.uf}
              </Typography>
            </Box>
          )}

        <UBox
          render={
            !!solicitacao?.agendamento &&
            (perfil === 'parceiro' ? !!solicitacao?.orcamentoAprovado : true)
          }
          mt={1}
        >
          {!!solicitacao?.agendamento?.dataPreAgendamento ? (
            <>
              <Typography variant="body2" color="error">
                <b>
                  <small>Pré agendamento</small>
                </b>
              </Typography>
              <Box mt={0}>
                <Typography
                  variant="body2"
                  style={{ fontWeight: 500 }}
                  color="error"
                >
                  {formatarDataHoraLocal(
                    solicitacao?.agendamento?.dataPreAgendamento
                  )}
                </Typography>
              </Box>
            </>
          ) : (
            <>
              <Typography variant="body2" color="primary">
                <b>
                  <small>Data de agendamento</small>
                </b>
              </Typography>
              <Box mt={0}>
                <Typography variant="body2" style={{ fontWeight: 500 }}>
                  {formatarDataHoraLocal(
                    solicitacao?.agendamento?.dataAgendamento
                  )}
                </Typography>
              </Box>
            </>
          )}

          <UBox
            mt={1}
            render={
              !!solicitacao?.agendamento?.observacao &&
              (perfil === 'parceiro' ? !!solicitacao?.orcamentoAprovado : true)
            }
          >
            <Typography variant="body2">
              <Alert severity="info">
                <b>
                  <small>Observação agendamento: </small>
                </b>
                <br />
                {verTexto
                  ? solicitacao?.agendamento?.observacao
                  : truncatedText}
                {solicitacao?.agendamento?.observacao?.length > 30 && (
                  <Link
                    component="button"
                    variant="body2"
                    onClick={handleVerTexto}
                    style={{
                      fontSize: '13px',
                      fontWeight: 600,
                      marginLeft: '4px'
                    }}
                  >
                    {verTexto ? ' Ver menos' : ' Ver mais'}
                  </Link>
                )}
              </Alert>
            </Typography>
          </UBox>
        </UBox>

        {/* Tags */}
        {['operacional', 'comercial'].includes(perfil) &&
          perfilAtual !== SynPerfis.PARCEIRO_EXTERNO && (
            <Box pt={2}>
              <Divider />
              <Box
                mt={2}
                mb={1}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <Typography variant="subtitle2">Tags</Typography>

                {perfil === 'operacional' &&
                  perfilAtual !== SynPerfis.PARCEIRO_EXTERNO && (
                    <Button onClick={() => handleAbrirModalTag(solicitacao)}>
                      <AddIcon /> Add
                    </Button>
                  )}
              </Box>

              {solicitacao?.tags?.length === 0 ? (
                <Typography variant="body2">
                  A solicitação não possui tags
                </Typography>
              ) : (
                solicitacao?.tags?.map(tag => {
                  return (
                    <Chip
                      key={tag.id}
                      label={tag.descricao}
                      size="small"
                      style={{
                        margin: '0 5px 5px 0',
                        color: tag.id
                          ?.toLowerCase()
                          .includes('a0c7a7c1-87a4-4e21-bce9-e06dec247c59')
                          ? '#fff'
                          : '',
                        background: tag.id
                          ?.toLowerCase()
                          .includes('a0c7a7c1-87a4-4e21-bce9-e06dec247c59')
                          ? '#e84141'
                          : ''
                      }}
                      onDelete={async () => {
                        if (perfilAtual !== SynPerfis.PARCEIRO_EXTERNO) {
                          await removerTag({
                            tagId: tag?.id,
                            solicitacaoOrcamentoId: solicitacao?.id
                          })
                          carregarListagem({ resetarPaginacao: true })
                        }
                      }}
                    />
                  )
                })
              )}
            </Box>
          )}
        {/* Tags */}
        {/* Orçamentos */}
        {['operacional', 'comercial'].includes(perfil) &&
          perfilAtual !== SynPerfis.PARCEIRO_EXTERNO && (
            <Box pt={2}>
              <Divider />
              <Box mt={2} mb={1}>
                <Typography variant="subtitle2">Orçamentos</Typography>
              </Box>

              {!solicitacao.qtdOrcamentos ||
              (!solicitacao.qtdOrcamentosOPME &&
                perfilAtual !== SynPerfis.ADMIN_HOSPITAL &&
                perfilAtual !== SynPerfis.OPERACIONAL_HOSPITAL) ? (
                <Typography variant="body2" style={{ marginTop: '10px' }}>
                  Nenhum orçamento ativo
                </Typography>
              ) : (
                <Box>
                  <Grid container>
                    {solicitacao.qtdOrcamentosRecusados > 0 && (
                      <Grid item xs={12} sm={12}>
                        <Typography variant="body2" style={{ color: 'red' }}>
                          {solicitacao.qtdOrcamentosRecusados === 1
                            ? '1 Recusado'
                            : `${solicitacao.qtdOrcamentosRecusados} Recusados`}
                        </Typography>
                      </Grid>
                    )}
                    {solicitacao.qtdOrcamentosOPME >= 1 && (
                      <Grid item xs={12} sm={12}>
                        <Typography variant="body2">
                          {solicitacao.qtdOrcamentosOPME} OPME
                        </Typography>
                      </Grid>
                    )}
                    {solicitacao.qtdOrcamentosHospital > 0 &&
                      perfilAtual !== SynPerfis.ADMIN_HOSPITAL &&
                      perfilAtual !== SynPerfis.OPERACIONAL_HOSPITAL && (
                        <Grid item xs={12} sm={12}>
                          <Typography variant="body2">
                            {solicitacao.qtdOrcamentosHospital} Hospital
                          </Typography>
                        </Grid>
                      )}
                  </Grid>
                  <Box mt={2} hidden={!solicitacao.qtdOrcamentos}>
                    <Link
                      component="button"
                      variant="body2"
                      color="primary"
                      align="left"
                      onClick={() => handleVisualizarParceiros(solicitacao)}
                    >
                      Ver parceiros
                    </Link>
                  </Box>
                </Box>
              )}
            </Box>
          )}
        {/* Orçamentos */}
        {/* Propostas */}
        <Box mt={2}>
          {renderFooter({
            handleAbrirSolicitacao,
            handleMostrarOrcamento,
            handleVisualizarComprovantes,
            navigate,
            perfil,
            setUltimaSolicitacao,
            solicitacao,
            handleHabilitarPagamento,
            copiarLinkPagamento,
            handleMudarStatus,
            handleMostrarDetalhes,
            permissoesSecretaria
          })}
        </Box>
        {/* Propostas */}
      </CardContent>
      <Box mt={1} p={2}>
        {(perfil === 'medico' ||
          (perfil === 'parceiro' &&
            !!solicitacao.orcamentoAprovado &&
            user.tipoParceiro === SynTipoParceiro.HOSPITAL_CLINICA.id)) &&
          statusOrcamento.AGENDADO.Id === solicitacao.status.id && (
            <ButtonAlterarStatusOrcamento
              onClick={async () => {
                var swalResult = {
                  isConfirmed: false
                }

                swalResult = await Swal.fire({
                  title: 'Atenção',
                  icon: 'warning',
                  text:
                    'Essa cirurgia foi realmente REALIZADA? Após confirmar, essa ação NÃO poderá ser revertida?',
                  confirmButtonText: 'Sim',
                  showCancelButton: true,
                  cancelButtonText: 'Não'
                })

                if (swalResult?.isConfirmed) {
                  setLoading(true)
                  handleAlterarStatusProcedimentoRealizado(solicitacao.id)
                } else {
                  setLoading(false)
                }
              }}
              loadingStatus={loading}
            />
          )}
      </Box>
    </Card>
  )
}
