import {
  Box,
  makeStyles,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Grid
} from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import SynTipoParceiro from 'src/core/consts/synTipoParceiro'
import formatarCNPJ from 'src/core/formatacoes/formatarCNPJ'
import formatarTelefone from 'src/core/formatacoes/formatarTelefone'
import { IAny, IParceiro, IParceiroFuncionario } from 'src/types'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import formatarCPF from 'src/core/formatacoes/formatarCPF'
import formatarCEP from 'src/core/formatacoes/formatarCEP'

const useStylesResumo = makeStyles(theme => ({
  boxResumo: {
    border: '1px solid #eee',
    backgroundColor: '#efefef',
    borderRadius: 4,
    '& .MuiTableCell-root.MuiTableCell-head': {
      padding: theme.spacing(1, 2),
      background: '#f9f9f9',
      color: theme.palette.grey[800]
    },
    '& h3': {
      display: 'flex',
      justifyContent: 'space-between'
    }
  },
  esconderEditar: {
    '& button': {
      display: 'none'
    }
  },
  fontMarca: {
    fontStyle: 'italic',
    fontWeight: 500,
    fontSize: '0.8rem',
    color: theme.palette.grey[700]
  },
  [theme.breakpoints.down('sm')]: {
    container: {
      margin: '0 auto',
      boxShadow: 'none'
    }
  }
}))

interface IparceiroProps extends IAny {
  parceiro: IParceiro
  contatos?: IParceiroFuncionario[]
  telaOrcamento?: boolean
  exibirAjusteEtapa?: boolean
  handleAjusteEtapa?: (etapa: number) => void
}

const ResumoParceiro = (props: IparceiroProps) => {
  const { parceiro, ...rest } = props
  const classes = useStylesResumo()

  function setTipoParceiro(tipo: string) {
    if (tipo === SynTipoParceiro.OPME.id) return SynTipoParceiro.OPME.descricao
    if (tipo === SynTipoParceiro.HOSPITAL_CLINICA.id) {
      return SynTipoParceiro.HOSPITAL_CLINICA.descricao
    }
  }

  return (
    <Box
      mt={2}
      p={2}
      className={clsx({
        [classes.boxResumo]: true,
        [classes.esconderEditar]: !props.exibirAjusteEtapa
      })}
      {...rest}
    >
      <Grid container>
        <Grid item xs={12} md={8}>
          <Typography variant="subtitle2" component="h3" color="primary">
            Nome do parceiro
          </Typography>
          <Typography variant="body2">{parceiro.nomeFantasia}</Typography>
          <br />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="subtitle2" component="h3" color="primary">
            CNPJ
          </Typography>
          <Typography variant="body2">{formatarCNPJ(parceiro.cnpj)}</Typography>
          <br />
        </Grid>
        <Grid item xs={12} md={8}>
          <Typography variant="subtitle2" component="h3" color="primary">
            Razao Social
          </Typography>
          <Typography variant="body2">{parceiro.razaoSocial}</Typography>
          <br />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="subtitle2" component="h3" color="primary">
            Tipo do parceiro
          </Typography>
          <Typography variant="body2">
            {setTipoParceiro(parceiro.tipo)}
          </Typography>
          <br />
        </Grid>

        <Grid item xs={12} md={8}>
          <Typography variant="subtitle2" component="h3" color="primary">
            E-mail para NF
          </Typography>
          <Typography variant="body2">
            {!!parceiro.emailNF ? parceiro.emailNF : 'Não informado'}
          </Typography>
          <br />
        </Grid>

        <Grid item xs={12} md={4}>
          <Typography variant="subtitle2" component="h3" color="primary">
            Telefone para NF
          </Typography>
          <Typography variant="body2">
            {!!parceiro.telefoneNF
              ? formatarTelefone(parceiro.telefoneNF)
              : 'Não informado'}
          </Typography>
          <br />
        </Grid>

        <Grid item xs={12} md={8}>
          {parceiro.localAtendimento && (
            <>
              <Typography variant="subtitle2" component="h3" color="primary">
                Nome Fantasia
              </Typography>
              <Typography variant="body2">
                {parceiro.localAtendimento.descricao}
              </Typography>
              <br />
              <Typography variant="subtitle2" component="h3" color="primary">
                UF local de atendimento
              </Typography>
              <Typography variant="body2">{parceiro.uf}</Typography>
              <br />
            </>
          )}
        </Grid>
        {parceiro.tipo === SynTipoParceiro.HOSPITAL_CLINICA.id && (
          <>
            <Grid item xs={12}>
              <Typography variant="subtitle2" component="h3" color="primary">
                Permite equipe de anestesia externa
              </Typography>
              <Typography variant="body2">
                {parceiro.permiteEquipeAnestesiaExterna ? 'Sim' : 'Não'}
              </Typography>
              <br />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" component="h3" color="primary">
                Gerenciar OPME's de parceiros
              </Typography>
              <Typography variant="body2">
                {parceiro.gerenciarOpmeExterno ? 'Sim' : 'Não'}
              </Typography>
              <br />
            </Grid>
          </>
        )}
        <Grid item xs={12} md={4}>
          {(parceiro.taxaOPME || parseFloat(parceiro.taxaOPME) >= 0) && (
            <>
              <Typography variant="subtitle2" component="h3" color="primary">
                Taxa de OPME
              </Typography>
              <Typography variant="body2">
                {parseFloat(parceiro.taxaOPME).toFixed(2)}%
              </Typography>
              <br />
            </>
          )}
        </Grid>
        <Grid item xs={12} sm={8}>
          {parceiro.cidade && (
            <>
              <Typography variant="subtitle2" component="h3" color="primary">
                Cidade - UF
              </Typography>
              <Typography variant="body2">
                {parceiro.cidade} - {parceiro.uf}
              </Typography>
              <br />
            </>
          )}
        </Grid>
        <Grid item xs={6} sm={3}>
          {parceiro.cep && (
            <>
              <Typography variant="subtitle2" component="h3" color="primary">
                CEP
              </Typography>
              <Typography variant="body2">
                {formatarCEP(parceiro.cep)}
              </Typography>
            </>
          )}
        </Grid>

        <Grid item xs={12} sm={6}>
          {parceiro.bairro && (
            <>
              <Typography variant="subtitle2" component="h3" color="primary">
                Bairro
              </Typography>
              <Typography variant="body2">{parceiro.bairro}</Typography>
              <br />
            </>
          )}
        </Grid>
        <Grid item xs={12} sm={8}>
          {parceiro.logradouro && (
            <>
              <Typography variant="subtitle2" component="h3" color="primary">
                Logradouro
              </Typography>
              <Typography variant="body2">{parceiro.logradouro}</Typography>
              <br />
            </>
          )}
        </Grid>
        <Grid item xs={12} sm={4}>
          {parceiro.numero && (
            <>
              <Typography variant="subtitle2" component="h3" color="primary">
                Número
              </Typography>
              <Typography variant="body2">{parceiro.numero}</Typography>
            </>
          )}
        </Grid>
        <Grid item xs={12} sm={4}>
          {parceiro.complemento && (
            <>
              <Typography variant="subtitle2" component="h3" color="primary">
                Complemento
              </Typography>
              <Typography variant="body2">{parceiro.complemento}</Typography>
              <br />
            </>
          )}
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography variant="subtitle2" component="h3" color="primary">
            Observação
          </Typography>
          {parceiro.observacao && (
            <Typography variant="body2">{parceiro.observacao}</Typography>
          )}
          {!parceiro.observacao && (
            <Typography variant="body2">Não há</Typography>
          )}
          <br />
        </Grid>
      </Grid>
      <Typography variant="subtitle2" component="h3" color="primary">
        Responsáveis
      </Typography>

      {parceiro.funcionarios?.length !== 0 && (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nome</TableCell>
                <TableCell>Dados</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.contatos
                ? props.contatos.map(contato => (
                    <TableRow key={contato.id}>
                      <TableCell>{contato.nome}</TableCell>
                      <TableCell>
                        {formatarTelefone(contato.celular)}
                        <br />
                        {contato.email}
                      </TableCell>
                    </TableRow>
                  ))
                : parceiro.funcionarios?.map(contato => (
                    <TableRow key={contato.id}>
                      <TableCell>{contato.nome}</TableCell>
                      <TableCell>
                        {formatarTelefone(contato.celular)}
                        <br />
                        {contato.email}
                        <br />
                        {formatarCPF(contato.cpf)}
                      </TableCell>
                      <TableCell>
                        <PopupState variant="popover" popupId="demo-popup-menu">
                          {popupState => (
                            <React.Fragment>
                              <MoreVertIcon
                                style={{ cursor: 'pointer' }}
                                {...bindTrigger(popupState)}
                              />
                              <Menu
                                anchorOrigin={{
                                  vertical: 'top',
                                  horizontal: 'right'
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'right'
                                }}
                                {...bindMenu(popupState)}
                              >
                                {contato.especialidades?.map(e => {
                                  return (
                                    <MenuItem key={`${contato.id} - ${e.id}`}>
                                      {e.descricao}
                                    </MenuItem>
                                  )
                                })}
                              </Menu>
                            </React.Fragment>
                          )}
                        </PopupState>
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <br />
    </Box>
  )
}

export default ResumoParceiro
