import { AxiosResponse } from 'axios'
import {
  AlterarSenhaCommand,
  AuthResponse,
  DefinirSenhaCommand,
  EsqueciSenhaCommand,
  LoginQuery,
  ResetarSenhaCommand
} from 'src/types'
import api from '../../services/api'

export function authLogin(
  query: LoginQuery
): Promise<AxiosResponse<AuthResponse>> {
  return api.post('/auth/login', query)
}

export function esqueciSenha(
  command: EsqueciSenhaCommand
): Promise<AxiosResponse> {
  return api.post('/auth/EsqueciSenha', command)
}

export function definirSenhaToken(
  command: DefinirSenhaCommand
): Promise<AxiosResponse> {
  return api.post('/auth/DefinirSenhaToken', command)
}

export function definirSenha(
  command: DefinirSenhaCommand
): Promise<AxiosResponse> {
  return api.post('/auth/DefinirSenha', command)
}

export const alterarSenha: (
  command: AlterarSenhaCommand
) => Promise<AxiosResponse<string | undefined>> = command => {
  const body: AlterarSenhaCommand = {
    senhaAtual: command.senhaAtual,
    novaSenha: command.novaSenha
  }
  return api.post('/auth/alterarSenha', body)
}

export function aceitarTermoDeUso(tipoTermo: number): Promise<AxiosResponse> {
  return api.post(`/auth/aceitarTermo/${tipoTermo}`)
}

export function resetarSenha(
  command: ResetarSenhaCommand
): Promise<AxiosResponse> {
  return api.post('/auth/resetarsenha', command)
}

export interface ITenantResponse {
  id: string
  parceiroId: string
  localAtendimentoId: string
  uf: string
  descricao: string
  urlLogotipo: string
  horarioFuncionamento: string
  telefoneContato: string
  textoExclusao: string
}
export function obterTenant(): Promise<AxiosResponse<ITenantResponse>> {
  return api.get('/auth/tenant')
}
