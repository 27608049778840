import { Box } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import React from 'react'
import { useQuery } from 'react-query'
import { obterTenant } from 'src/api/auth'
import useAuth from 'src/hooks/useAuth'

export default function textoExclusao() {
  const { user } = useAuth()
  const { data: infoTenant, isLoading } = useQuery(
    ['acomodacoes', user?.id],
    async () => {
      var response = await obterTenant()
      return response.data
    },
    {
      cacheTime: 1 * (1000 * 60) //1 minuto
    }
  )
  if (isLoading || !infoTenant?.textoExclusao) return null

  return (
    <Box mt={2} style={{ whiteSpace: 'pre-line' }}>
      <Alert severity="info">{infoTenant?.textoExclusao}</Alert>
    </Box>
  )
}
